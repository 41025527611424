import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { BsTrash } from "react-icons/bs"
import ActiveRules from "./activeRules"
import ActiveRuleLogs from "./activeRuleLogs"
import { Button, Dropdown, Input, Toggle, Pagination, BaseModal, Spinner } from "../../../components"
import { useFormik } from "formik"
import { getRuleTemplatesAPI, createRuleTemplatesAPI, getCampaginsAPI, getFacebookAdAccounts, getAdsetAPI, getAdCreativeAPI, updateRuleTemplateDataAPI, deleteRuleTemplatesAPI } from "../../../service"
import { FaEdit, FaTrash } from "react-icons/fa"
import DataTable from 'react-data-table-component'
import moment from 'moment'
import 'moment-timezone'
import { json } from "react-router-dom"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function RulesAndAutomation() {

  const [open, setOpen] = useState(false);
  const [currentPageTemplates, setCurrentPageTemplates] = useState(0)
  const [numberOfInputs, setNumberOfInputs] = useState(1)
  const [activeTab, setActiveTab] = useState('rules')
  const [rulesTemplateData, setRulesTemplateData] = useState([])
  const [rulesTemplateCount, setRulesTemplateCount] = useState(0)
  const [adAccounts, setAddAccounts] = useState([])
  const [campagins, setAllCampaigns] = useState([])
  const [adsets, setAllAdsets] = useState([])
  const [ads, setAllAds] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false)
  const [dataId, setDataId] = useState(null)
  const [mode, setMode] = useState('Create')
  const [editInfo, setEditInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sorted, setSorted] = useState(null)

  useEffect(() => {
    getAllCampagins()
    getAllAdsets()
    getAllAds()
    getFacebookAccount()

    moment.tz.setDefault("America/New_York")

  }, [])

  useEffect(() => {
    if (activeTab === "templates") {
      getRuleTemplates()
    }
  }, [activeTab])

  // GET RULE TEMPLATES
  const getRuleTemplates = async (param) => {
    try {
      let current = (currentPageTemplates > 0) ? currentPageTemplates : 0
      let obj = { offset: current, limit: 10 }

      if (param && param.sort_by) {
        obj.sort_by = param.sort_by
      } else if (sorted) {
        obj.sort_by = sorted
      }

      const { data } = await getRuleTemplatesAPI(obj)

      setRulesTemplateData(data.results)
      setRulesTemplateCount(data.count)
    } catch (error) {
      // console.log("error?.response?", error.response)
    }
  }

  useEffect(() => {
    getRuleTemplates()
  }, [currentPageTemplates])

  // GET ALL CAMPAIGNS
  const getAllCampagins = async () => {
    try {
      const { data } = await getCampaginsAPI({ drafts: false })
      const res = data.map(item => {
        return { label: item.name, value: item.id }
      })
      setAllCampaigns(res)
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  // GET ALL ADSETS
  const getAllAdsets = async () => {
    try {
      const { data } = await getAdsetAPI({ drafts: false })
      const res = data.map(item => {
        return { label: `(${item.id}) - ${item.name}`, value: item.id }
      })
      setAllAdsets(res)
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  // GET ALL ADS
  const getAllAds = async () => {
    try {
      const { data } = await getAdCreativeAPI({ drafts: false })
      const res = data.map(item => {
        return { label: `(${item.id}) - ${item.name}`, value: item.id }
      })
      setAllAds(res)
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  // GET FACEBOOK ACCOUNTS
  const getFacebookAccount = async () => {
    try {
      const { data } = await getFacebookAdAccounts()
      if (data.length) {
        const ad_accounts = data.map(item => {
          return { label: item.name, value: item.id }
        })
        setAddAccounts(ad_accounts)
      }
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  const submitHandler = async (values) => {
    
    try {
      const params = {
        name: values.name,
        action: values.action.value,
        time_range: values.time_range.value,
        conditions: values.conditions.map((items) => {
          const obj = {
            condition: items.condition.value,
            operator: items.operator.value,
          }
          if(obj.condition === 'current_datetime'){
            obj.datetime = moment(items.datetime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          }else{
            obj.value = items.value
          }
          if (items.id) {
            obj.id = items.id
          }
          return obj
        })
      }
      if((['Increase bid by','Decrease bid by']).includes(values.action.label)){
        params.minimum_bid = values.minimum_bid
        params.frequency = values.frequency.value
        params.bid_type = values.bid_type.value
        if(values.bid_type.value === 'bid_percentage'){
          params.bid_percentage = values.bid_value
        }else{
          params.bid_value = values.bid_value
        }
      }
      if (editInfo) {
        await updateRuleTemplateDataAPI(editInfo.id, params)
      } else {
        await createRuleTemplatesAPI(params)
      }
      formik.resetForm()
      setOpen(!open)
      getRuleTemplates()
    } catch (error) {
      const message =
        error?.response.data?.non_field_errors[0] || "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      applyto: "",
      action: "",
      time_range: "",
      conditions: [{ condition: "", operator: "", value: "", datetime: ""}],
      frequency: "",
      minimum_bid: "",
      bid_type: "",
      bid_value: ""
    },
    onSubmit: submitHandler
  })

  const handleRemove = (index) => {
    const temp = [...formik.values.conditions]
    temp.splice(index, 1)
    formik.setFieldValue("conditions", temp)
    setNumberOfInputs(old => --old)
  }

  const bid_type = [
    { label: "$", value: "bid_value" },
    { label: "%", value: "bid_percentage" }
  ]

  const frequency = [
    { label: "Once Hourly", value: "once_hourly" },
    { label: "Once Every 12 Hours", value: "once_every_12" },
    { label: "Once Daily", value: "once_daily" },
    { label: "Once Weekly", value: "once_weekly" },
    { label: "Every 2 Week", value: "every_two_week" },
  ]

  const actionData = [
    { label: "Turn on Campaign", value: "campaign__turn_on" },
    { label: "Turn off Campaign", value: "campaign__turn_off" },
    { label: "Campaign Notification", value: "campaign__notification" },
    { label: "Turn on Adset", value: "ad_set__turn_on" },
    { label: "Turn off Adset", value: "ad_set__turn_off" },
    { label: "Adset Notification", value: "ad_set__notification" },
    { label: "Increase Daily budget by Adset", value: "ad_set__inc_daily_budget" },
    { label: "Decrease Daily budget by Adset", value: "ad_set__dec_daily_budget" },
    { label: "Increase Lifetime budget by Adset", value: "ad_set__inc_lifetime_budget" },
    { label: "Decrease Lifetime budget by Adset", value: "ad_set__dec_lifetime_budget" },
    { label: "Turn on Ad", value: "ad__turn_on" },
    { label: "Turn off Ad", value: "ad__turn_off" },
    { label: "Ad Notification", value: "ad__notification" },
    { label: "Increase bid by", value: "ad__inc_bid" },
    { label: "Decrease bid by", value: "ad__dec_bid" }
  ]

  const timeRangeData = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 3 Days", value: "last_3_days" },
    { label: "Last 7 Days", value: "last_7_days" },
    { label: "Last 14 Days", value: "last_14_days" },
    { label: "Last 30 Days", value: "last_30_days" },
    { label: "Last 3 Days, Including Today", value: "last_3_days_incl_today" },
    { label: "Last 7 Days, Including Today", value: "last_7_days_incl_today" },
    { label: "Last 14 Days, Including Today", value: "last_14_days_incl_today" },
    { label: "Last 30 Days, Including Today", value: "last_30_days_incl_today" }
  ]

  const conditionData = [
    { label: "Amount Spent", value: "amount_spent" },
    { label: "Lifetime Spent", value: "lifetime_spent" },
    { label: "Conversion Rate", value: "conversions" },
    { label: "Landing Page View Cost", value: "landing_page_view_cost" },
    { label: "Current Datetime", value: "current_datetime" },
    { label: "Clicks", value: "clicks" },
    { label: "Submitted Leads", value: "submitted_leads" },
    { label: "Sold Leads", value: "sold_leads" },
    { label: "CTR", value: "CTR" },
    { label: "CPC", value: "CPC" },
    { label: "Impressions", value: "impressions" },
    { label: "ROI", value: "ROI" }
  ]

  const operatorData = [
    { label: "<", value: "<" },
    { label: ">", value: ">" },
    { label: "<=", value: "<=" },
    { label: ">=", value: ">=" },
    { label: "=", value: "=" }
  ]

  const renderConditions = (conditions) => {
    return (
      <>
        {conditions.map((a) => (
          <>
            <p><span className="capitalize">{a.condition.replace('_', ' ')}</span> {a.operator} {a.value}</p>
          </>
        ))}
      </>
    )
  }

  const handleTemplateStatus = async (id, status) => {
    try {
      await updateRuleTemplateDataAPI(id, { enabled: !status })
      getRuleTemplates()
      toast.success("Rule template updated successfully!")
    } catch (error) {
      const message = (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors) || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const handleAdd = (row) => {
    
    setOpen(!open)
    if (row) {
      setMode('Update')
      setEditInfo(row)
      setTimeout(() => {
        formik.setFieldValue("name", row.name)
        formik.setFieldValue("minimum_bid", row.minimum_bid)
        formik.setFieldValue("bid_type", bid_type.find((item) => item.value === row.bid_type) || {})
        formik.setFieldValue("frequency", frequency.find((item) => item.value === row.frequency) || {})
        formik.setFieldValue("applyto", (row.action.includes('campaign')) ? { label: "Campaign", value: "campaign" } : (row.action.includes('ad__')) ? { label: "Ads", value: "ads" } : (row.action.includes('adset')) ? { label: "Adset", value: "adset" } : {})
        formik.setFieldValue("action", actionData.find((item) => item.value === row.action) || {})
        formik.setFieldValue("time_range", timeRangeData.find((item) => item.value === row.time_range) || {})
        
        if((['Increase bid by','Decrease bid by']).includes(row.action_readable)){
          if(row.bid_type === 'bid_percentage'){
            formik.setFieldValue("bid_value", String(row.bid_percentage))
          }else{
            formik.setFieldValue("bid_value", String(row.bid_value))
          }
        }

        const optdata = row.conditions.map((items) => {
          return {
            id: items.id,
            condition: conditionData.find((item) => item.value === items.condition) || {},
            operator: operatorData.find((item) => item.value === items.operator) || {},
            value: conditionData.find((item) => item.value === items.condition) == 'current_datetime' ? '': items.value || "",
            datetime: items.datetime ? new Date(items.datetime) : ''
          }
        })
        formik.setFieldValue("conditions", optdata)
      }, 800)
    } else {
      setMode('Create')
      setEditInfo(null)
      formik.resetForm()
    }
  }

  const handleDelete = async (id) => {
    setDataId(id)
    setOpenConfirm(!openConfirm)
  }

  const deleteRow = async () => {
    try {
      if (dataId) {
        setOpenConfirm(!openConfirm)
        setDataId(null)
        if (activeTab === 'templates') {
          await deleteRuleTemplatesAPI(dataId)
          getRuleTemplates()
          toast.success("RUle Template Deleted!")
        }
      }
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      toast.error(message)
    }
  }

  const handleSorting = (col, direction) => {
    setSorted(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getRuleTemplates({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  const columns = [
    {
      name: "ON/OFF",
      id: 'enabled',
      sortable: true,
      selector: row => <>
        <Toggle
          isChecked={row.enabled}
          onChange={status =>
            handleTemplateStatus(row.id, row.enabled)
          }
        />
      </>
    },
    {
      name: "Rule Name",
      id: 'name',
      sortable: true,
      selector: row => <>
        {row.name}
      </>
    },
    {
      name: "Apply To",
      sortable: false,
      selector: row => <span>{row.action.includes('campaign__') ? 'Campaign' : row.action.includes('ad_set__') ? 'Adset' : row.action.includes('ad__') ? 'Ad' : ''}</span>
    },
    {
      name: "Rule Action",
      id: 'action',
      sortable: true,
      selector: row => <span>{actionData.filter((item) => item.value === row.action)[0].label}</span>
    },
    {
      name: "Period",
      id: 'time_range',
      sortable: true,
      selector: row => <span>{timeRangeData.filter((item) => item.value === row.time_range)[0].label}</span>
    },
    {
      name: "Condition",
      sortable: false,
      selector: row => <span>{renderConditions(row.conditions)}</span>
    },
    {
      name: "Minimum Bidding Cap",
      sortable: false,
      selector: row => <span>{row.minimum_bid}</span>
    },
    {
      name: "Minimum Bidding Cap",
      sortable: false,
      selector: row => <span>{row.minimum_bid}</span>
    },
    {
      name: "Actions",
      sortable: false,
      center: true,
      selector: row => (
        <div className="py-3">
          <button className="my-2 md:my-0 px-7 py-2 bg-[#DBD312] text-white rounded-xl" onClick={() => handleAdd(row)}>
            <FaEdit />
          </button>
          <button
            className="my-2 md:my-0 px-7 py-2 bg-[#CC4B43] text-white rounded-xl ml-3"
            onClick={() => handleDelete(row.id)}
          >
            <FaTrash />
          </button>
        </div>
      )
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  return (
    <section className="w-full mx-auto px-3 min-h-screen bg-gray-200">
      <div className="px-0 sm:px-6 lg:px-8">
        <div className="mt-12 mb-6">
          <Button
            className={`my-2 md:my-0 px-7 py-3 ${(activeTab === 'rules') ? 'bg-[#B6CC43]' : 'bg-white text-[#B6CC43]'} hover:bg-[#B6CC43] hover:text-white text-lg rounded-lg mr-3 disabled:opacity-60`}
            onClick={() => setActiveTab('rules')}
          >
            Active Rules
          </Button>
          <Button
            className={`my-2 md:my-0 px-7 py-3 ${(activeTab === 'templates') ? 'bg-[#B6CC43]' : 'bg-white text-[#B6CC43]'} hover:bg-[#B6CC43] hover:text-white text-lg rounded-lg mr-3`}
            onClick={() => setActiveTab('templates')}
          >
            Rules Templates
          </Button>
          <Button
            className={`my-2 md:my-0 px-7 py-3 ${(activeTab === 'logs') ? 'bg-[#B6CC43]' : 'bg-white text-[#B6CC43]'} hover:bg-[#B6CC43] hover:text-white text-lg rounded-lg mr-3 disabled:opacity-60`}
            onClick={() => setActiveTab('logs')}
          >
            Rules Log
          </Button>
        </div>

        {/* RULES */}
        {(activeTab === 'rules') &&
          <ActiveRules
            activeTab={activeTab}
            adAccounts={adAccounts}
            campagins={campagins}
            adsets={adsets}
            ads={ads}
          />
        }

        {/* RULES TEMPLATES */}
        {(activeTab === 'templates') &&
          <div className="bg-white rounded-xl shadow-lg p-4 mt-4">
            <div className="flex justify-end mb-4">
              <Button className="bg-[#00B2B4] p-2" onClick={() => handleAdd()}>
                Create Rules Template
              </Button>
            </div>
            <div className="overflow-y-auto">
              <div className="relative grid py-3">
                <DataTable
                  columns={columns}
                  data={rulesTemplateData}
                  responsive={true}
                  progressPending={loading}
                  progressComponent={<Spinner />}
                  customStyles={customStyles}
                  className='react-dataTable'
                  onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
                  fixedHeader
                  fixedHeaderScrollHeight="750px"
                  persistTableHead
                />
              </div>
            </div>

            <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
              <div className="flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{rulesTemplateData.length}</span>&nbsp;of <span className="font-medium">{rulesTemplateCount}</span> results
                  </p>
                </div>
              </div>
              <Pagination
                totalPages={Math.ceil(rulesTemplateCount / 10)}
                currentPage={currentPageTemplates}
                setCurrentPage={setCurrentPageTemplates}
                offset={10}
              />
            </div>

          </div>
        }

        {/* RULES LOGS */}
        {(activeTab === 'logs') &&
          <ActiveRuleLogs
            activeTab={activeTab}
            adAccounts={adAccounts}
            campagins={campagins}
            adsets={adsets}
            ads={ads}
          />
        }
      </div>

      <BaseModal open={open} setOpen={setOpen} className="sm:max-w-2xl" title={`${mode} Rule Template`}>
        <form onSubmit={formik.handleSubmit}>
          <Input
            placeholder="Rule Name"
            name="name"
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.errors.name}
            touched={formik.touched.name}
          />
          <p className="font-lg font-bold text-gray-900 mt-6">Apply Rule To:</p>
          <Dropdown
            placeholder="Apply Rule To"
            name="applyto"
            options={[
              { label: "Campaign", value: "campaign" },
              { label: "Adset", value: "adset" },
              { label: "Ads", value: "ads" }
            ]}
            onChange={(e) => formik.setFieldValue("applyto", e)}
            onBlur={formik.handleBlur}
            value={formik.values.applyto}
            error={formik.errors.applyto}
            touched={formik.touched.applyto}
          />
          <Dropdown
            placeholder="Action"
            name="action"
            options={actionData}
            onChange={(e) => formik.setFieldValue("action", e)}
            onBlur={formik.handleBlur}
            value={formik.values.action}
            error={formik.errors.action}
            touched={formik.touched.action}
          />
          <Dropdown
            placeholder="Time Range"
            name="time_range"
            options={timeRangeData}
            onChange={(e) => formik.setFieldValue("time_range", e)}
            onBlur={formik.handleBlur}
            value={formik.values.time_range}
            error={formik.errors.time_range}
            touched={formik.touched.time_range}
          />
          {(['Increase bid by','Decrease bid by']).includes(formik?.values?.action?.label) ? <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
            <div style={{width:'49%'}}>
              <Input
                    className="my-0"
                    placeholder={formik.values.bid_type.value === 'bid_percentage'?"0":"0.00"}
                    name="bid_value"
                    onChange={(e) => formik.setFieldValue("bid_value", e.target.value)}
                    type="text"
                    onBlur={formik.handleBlur}
                    value={formik.values.bid_value}
                    error={formik.errors.bid_value}
                    touched={formik.touched.bid_value}
              />
            </div>
            <div style={{width:'49%'}}>
              <Dropdown
                placeholder="Bidding Value Type"
                name="frequency"
                options={bid_type}
                onChange={(e) => {
                  formik.setFieldValue("bid_value", '')
                  formik.setFieldValue("bid_type", e)}}
                onBlur={formik.handleBlur}
                value={formik.values.bid_type}
                error={formik.errors.bid_type}
                touched={formik.touched.bid_type}
                
              />
            </div>
          </div>:null}
          {(['Increase bid by','Decrease bid by']).includes(formik?.values?.action?.label) ? <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
            <div style={{width:'49%'}}>
              <Input
                    className="my-0"
                    placeholder={(['Increase bid by']).includes(formik?.values?.action?.label)?"Maximum manual bid cap":'Minimum manual bid cap'}
                    name="minimum_bid"
                    onChange={(e) => formik.setFieldValue("minimum_bid", e.target.value)}
                    type="text"
                    onBlur={formik.handleBlur}
                    value={formik.values.minimum_bid}
                    error={formik.errors.minimum_bid}
                    touched={formik.touched.minimum_bid}
              />
            </div>
            <div style={{width:'49%'}}>
              <Dropdown
                placeholder="Action frequency"
                name="frequency"
                options={frequency}
                onChange={(e) => formik.setFieldValue("frequency", e)}
                onBlur={formik.handleBlur}
                value={formik.values.frequency}
                error={formik.errors.frequency}
                touched={formik.touched.frequency}
                
              />
            </div>
          </div>:null}
          {/* {(Object.values(formik.values.action).filter(x=> x.includes('_budget')).length > 0) ? <>
            <Input
              className="my-0"
              placeholder="Increase By"
              type="text"
              onBlur={formik.handleBlur}
            />
            <Input
              className="my-0"
              placeholder="Until"
              type="text"
              onBlur={formik.handleBlur}
            />
            <Dropdown
              placeholder="Time Range"
              name="time_range"
              options={timeRangeData}
              onChange={(e) => formik.setFieldValue("time_range", e)}
              onBlur={formik.handleBlur}
              value={formik.values.time_range}
              error={formik.errors.time_range}
              touched={formik.touched.time_range}
            />
          </> : <>
            <Dropdown
              placeholder="Time Range"
              name="time_range"
              options={timeRangeData}
              onChange={(e) => formik.setFieldValue("time_range", e)}
              onBlur={formik.handleBlur}
              value={formik.values.time_range}
              error={formik.errors.time_range}
              touched={formik.touched.time_range}
            />
          </>} */}
          <p className="font-lg font-bold text-gray-900 mt-6">Conditions</p>
          <p className="text-sm text-gray-700"><small>All of the following match</small></p>
          <div className="grid grid-cols-3 gap-x-2 ">
            {[...new Array(numberOfInputs)].map((_, index) => (
              <>
                <div className="mt-1">
                  <Dropdown
                    placeholder="Field"
                    name={`conditions[${index}].condition`}
                    onChange={e => {
                      formik.setFieldValue(`conditions[${index}].value`, '')
                      formik.setFieldValue(`conditions[${index}].condition`, e)}}
                    options={conditionData}
                    onBlur={formik.handleBlur}
                    value={formik.values.conditions[index]?.condition}
                    error={formik.errors.conditions && formik.errors.conditions[index]?.condition}
                    touched={formik.touched.conditions && formik.touched.conditions[index]?.condition}
                  />
                </div>
                <div className="mt-1">
                  <Dropdown
                    placeholder="Operator"
                    name={`conditions[${index}].operator`}
                    onChange={e =>
                      formik.setFieldValue(`conditions[${index}].operator`, e)
                    }
                    options={operatorData}
                    onBlur={formik.handleBlur}
                    value={formik.values.conditions[index]?.operator}
                    error={formik.errors.conditions && formik.errors.conditions[index]?.operator}
                    touched={formik.touched.conditions && formik.touched.conditions[index]?.operator}
                  />
                </div>
                <div className="mt-1">
                <div className="" style={{flexDirection:'row',display:'flex',alignContent:'center',alignItems:'center'}}>
                  {(['Current Datetime']).includes(formik?.values?.conditions[index]?.condition?.label)?<DatePicker
                  selected={formik.values.conditions[index]?.datetime}
                  onChange={(date) => {
                    formik.setFieldValue(`conditions[${index}].datetime`, date)
                  }}
                  timeInputLabel="Value"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  name={`conditions[${index}].datetime`}
                  style={{}}
                  className="my-3"
                />:<Input
                  className="my-0"
                  placeholder="Value"
                  name={`conditions[${index}].value`}
                  onChange={e => {
                    formik.setFieldValue(`conditions[${index}].value`, e.target.value)}}
                  type={"text"}
                  onBlur={formik.handleBlur}
                  value={formik.values.conditions[index]?.value}
                  error={formik.errors.conditions && formik.errors.conditions[index]?.value}
                  touched={formik.touched.conditions && formik.touched.conditions[index]?.value}
                  style={{width: 165}}
                />}
                  <BsTrash
                    size={30}
                    className="cursor-pointer mx-3 text-[#CC4B43] text-md"
                    onClick={() => handleRemove(index)}
                  />
                </div>
                </div>
              </>
            ))}
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
            <Button
              className={"bg-[#00B2B4] mr-2 py-2 px-6 shadow-sm"}
              onClick={() => {
                formik.setFieldValue("conditions", [
                  ...formik.values.conditions,
                  { field: "", operator: "", value: "" }
                ])
                setNumberOfInputs(old => ++old)
              }}
            >
              + Add
            </Button>
          </div>

          <div className="mt-6 sm:mt-6 sm:flex sm:flex-row justify-end">
            <Button
              type="button"
              className={"bg-[#CC4B43] mr-2 py-2 px-6 shadow-sm"}
              onClick={() => {
                setOpen(false)
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              className={"bg-[#B6CC43] mr-2 py-2 px-6 shadow-sm"}
            >
              Save
            </Button>
          </div>
        </form>
      </BaseModal>

      <BaseModal open={openConfirm} setOpen={setOpenConfirm} title={`Delete ${(activeTab === 'rules') ? 'Active Rule' : (activeTab === 'templates') ? 'Rules Template' : ''}?`}>
        <div class="md:flex items-center">
          <div class="mt-4 md:mt-0 md:text-left">
            <p class="text-sm text-gray-700 mt-1">{`Are you sure you want to delete this ${(activeTab === 'rules') ? 'active rule' : (activeTab === 'templates') ? 'rules template' : ''}?`}</p>
            <p class="text-sm text-gray-700 mt-1">This action cannot be undone.</p>
          </div>
        </div>
        <div class="text-center md:text-right mt-4 md:flex md:justify-end">
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={deleteRow}>Yes, Delete it</button>
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={() => setOpenConfirm(!openConfirm)}>Cancel</button>
        </div>
      </BaseModal>
    </section>
  )
}
