import React, { useCallback, useEffect, useState, useRef } from "react";
import DataTable from 'react-data-table-component';
import BreakdownExpanded from "./breakdownExpanded"
import { getAdsetAPI, getSelfConfigAPI } from "../../../service";
import { Spinner, Toggle, Input } from "../../../components";
import { Popover } from "@headlessui/react"
import { BiArrowToBottom, BiChevronDown, BiDownArrow, BiFilterAlt } from "react-icons/bi"
import { useFormik } from "formik"
import moment from 'moment'
import 'moment-timezone'

export default function Breakdown({ data }) {

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  const popRef = useRef()
  const [adsets, setAdsets] = useState([])
  const [togglePopover, setPopover] = useState(false)
  const [loading, setLoading] = useState(false)
  const [columnData, setColumnData] = useState([])
  const [allColumns, setColumns] = useState([
    { label: "ID", value: "id", isActive: false },
    { label: "Status", value: "status", isActive: false },
    { label: "Name", value: "name", isActive: false },
    { label: "Submitted Leads", value: "ms_submitted_leads", isActive: false },
    { label: "Sold Leads", value: "ms_sold_leads", isActive: false },
    { label: "Clicks", value: "ms_clicks", isActive: true },
    { label: "CTR", value: "ms_ctr", isActive: true },
    { label: "ROI", value: "ms_roi", isActive: true },
    { label: "Conv. Rate", value: "ms_conversion_rate", isActive: false },
    { label: "Sold Rate", value: "ms_sold_rate", isActive: false },
    { label: "EPC", value: "ms_epc", isActive: true },
    { label: "CPC", value: "ms_lpvc", isActive: true },
    { label: "Revenue", value: "ms_revenue", isActive: true },
    { label: "Profit", value: "ms_profit", isActive: false },
    { label: "Spend", value: "spend", isActive: false },
    { label: "Impressions", value: "impressions", isActive: false },
    { label: "FB Conversion", value: "results", isActive: false },
    { label: "FB Cost Per CVR", value: "cvr", isActive: false },
    { label: "FB Revenue", value: "purchase", isActive: false },
  ])

  const getAllAdsets = async (param) => {
    try {
      setLoading(true)
      const { data } = await getAdsetAPI(param)
      setLoading(false)
      setAdsets(data)
    } catch (error) {
      setLoading(false)
      console.log("error response")
    }
  }

  const handleFormData = async values => {
    try {
      let params = {}
      if (values.insights_start_date) {
        params.insights_start_date = values.insights_start_date
      }
      if (values.insights_end_date) {
        params.insights_end_date = values.insights_end_date
      }
      popRef.current?.click()
      if (data && data.id) {
        getAllAdsets({ ...params, campaign_ids: data.id })
      }
    } catch (error) {

    }
  }

  const formik = useFormik({
    initialValues: {
      insights_start_date: moment().format('YYYY-MM-DD'),
      insights_end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: handleFormData
  })

  // GET COLUMN ORDERS
  const getColumnOrderList = async () => {
    try {
      const { data } = await getSelfConfigAPI()
      if (data.ad_set_cols) {
        setColumns(data.ad_set_cols)
      }
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  useEffect(() => {
    if (data && data.id) {
      getAllAdsets({ insights_start_date: formik.values.insights_start_date, insights_end_date: formik.values.insights_end_date, campaign_ids: data.id })
    }
    getColumnOrderList()
  }, [data])

  const handleSorting = (col, direction) => {
    getAllAdsets({ campaign_ids: data.id, insights_start_date: formik.values.insights_start_date, insights_end_date: formik.values.insights_end_date, sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  useEffect(() => {
    const cols = allColumns.map((items) => {
      if (items.value === 'id') {
        return {
          name: "ID",
          id: 'id',
          sortable: true,
          maxWidth: '50px',
          selector: row => <span>{row.id}</span>
        }
      }
      else if (items.value === 'status') {
        return {
          name: "Status",
          id: 'status',
          minWidth: '150px',
          selector: row => <div className="flex justify-end">
            <Toggle
              isChecked={(row.status === 'ACTIVE')}
              disabled={true}
            />
          </div>
        }
      }
      else if (items.value === 'name') {
        return {
          name: "Name",
          id: 'name',
          sortable: true,
          minWidth: '250px',
          selector: row => <span>{row.name}</span>,
          wrap: 'no'
        }
      }
      else if (items.value === 'ms_submitted_leads') {
        return {
          name: "Submitted Leads",
          id: 'ms_submitted_leads',
          minWidth: '180px',
          sortable: true,
          center: true,
          selector: row => <span>{row.ms_submitted_leads}</span>
        }
      }
      else if (items.value === 'ms_sold_leads') {
        return {
          name: "Sold Leads",
          id: 'ms_sold_leads',
          sortable: true,
          center: true,
          selector: row => <span>{row.ms_sold_leads}</span>
        }
      }
      else if (items.value === 'ms_clicks') {
        return {
          name: "Clicks",
          id: 'ms_clicks',
          sortable: true,
          center: true,
          selector: row => <span>{row.ms_clicks}</span>
        }
      }
      else if (items.value === 'ms_ctr') {
        return {
          name: "CTR",
          id: 'ms_ctr',
          sortable: true,
          center: true,
          selector: row => <span>{row.ms_ctr.toFixed(2)}%</span>
        }
      }
      else if (items.value === 'ms_roi') {
        return {
          name: "ROI",
          id: 'ms_roi',
          sortable: true,
          center: true,
          selector: row => <span>{row.ms_roi}%</span>
        }
      }
      else if (items.value === 'ms_conversion_rate') {
        return {
          name: "Conv. Rate",
          id: 'ms_conversion_rate',
          center: true,
          sortable: true,
          minWidth: '150px',
          selector: row => (
            <div className="flex items-center">
              {row.ms_conversion_rate.toFixed(2)}%
            </div>
          )
        }
      }
      else if (items.value === 'ms_sold_rate') {
        return {
          name: "Sold Rate",
          id: 'ms_sold_rate',
          center: true,
          sortable: true,
          selector: row => (
            <div className="flex items-center">
              {row.ms_sold_rate.toFixed(2)}%
            </div>
          )
        }
      }
      else if (items.value === 'ms_epc') {
        return {
          name: "EPC",
          id: 'ms_epc',
          center: true,
          sortable: true,
          selector: row => <span>${row.ms_epc}</span>
        }
      }
      else if (items.value === 'ms_lpvc') {
        return {
          name: "CPC",
          id: 'ms_lpvc',
          center: true,
          sortable: true,
          selector: row => <span>${row.ms_lpvc}</span>
        }
      }
      else if (items.value === 'spend') {
        return {
          name: "Spend",
          id: 'spend',
          center: true,
          sortable: true,
          selector: row => <span>${row.spend}</span>
        }
      }
      else if (items.value === 'ms_revenue') {
        return {
          name: "Revenue",
          id: 'ms_revenue',
          center: true,
          sortable: true,
          selector: row => <span>${row.ms_revenue}</span>
        }
      }
      else if (items.value === 'ms_profit') {
        return {
          name: "Profit",
          id: 'ms_profit',
          sortable: true,
          center: true,
          selector: row => <span>${row.ms_profit}</span>
        }
      }
      else if (items.value === 'impressions') {
        return {
          name: "Impressions",
          id: 'impressions',
          minWidth: '150px',
          center: false,
          sortable: true,
          selector: row => <span>{row.impressions}</span>
        }
      }
      else {
        return items
      }
    })
    setColumnData(cols)
  }, [allColumns])

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 30px 5px #5E84C24F'
      }
    }
  };

  const expandableRowsComponent = ({ data }) => {
    return <BreakdownExpanded data={data} filtered={formik} />
  }

  return (
    <div className="relative py-3">
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-6 gap-3 lg:gap-5 mb-2 items-end">
          <label className="col-span-3"></label>
          <label className="block">
            <span className="font-semibold text-gray-500">Start Date</span>
            <Input
              placeholder="Start Date"
              type="date"
              className="bg-white"
              name="insights_start_date"
              onChange={(e) => formik.setFieldValue("insights_start_date", e.target.value)}
              onBlur={formik.handleBlur}
              value={formik.values.insights_start_date}
              error={formik.errors.insights_start_date}
              touched={formik.touched.insights_start_date}
            />
          </label>
          <label className="block">
            <span className="font-semibold text-gray-500">End Date</span>
            <Input
              placeholder="End Date"
              type="date"
              className="bg-white"
              name="insights_end_date"
              onChange={(e) => formik.setFieldValue("insights_end_date", e.target.value)}
              onBlur={formik.handleBlur}
              value={formik.values.insights_end_date}
              error={formik.errors.insights_end_date}
              touched={formik.touched.insights_end_date}
            />
          </label>
          <label className="block">
            <button className="px-2 py-2 my-4 button-gradient rounded-md font-bold text-white w-full" type="submit">
              Apply
            </button>
          </label>
        </div>
      </form>
      <DataTable
        columns={columnData}
        data={adsets}
        expandableRows
        className="rounded-md border border-2 border-gray-300 my-6 shadow-xl"
        progressPending={loading}
        progressComponent={<div className="py-3"><Spinner /></div>}
        expandableRowsComponent={expandableRowsComponent}
        customStyles={customStyles}
        onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="725px"
        persistTableHead
      />
    </div>
  );
};
