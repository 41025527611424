import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert"

import { displayErrors, renderErrorMessage } from "../../../helpers"
import { AudienceTargeting } from "../../../components/campagins/createCompagins/conversionForms"
import { updateCampaignDataAPI, getCampaignDetailAPI, getSavedAudienceAPI } from "../../../service"
import moment from 'moment'
import 'moment-timezone'
import ReactLoading from "react-loading";
const CreateCampaignSteps = ({}) => {
  const navigate = useNavigate()
  const {campaignId } = useParams()
  const [campaignData, setCampaignData] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [validation, setValidation] = useState(null)
  const [savedAudiences, setSavedAudiences] = useState([])
  const [loading, setLoading] = useState(false)

  const genderToValueMap = gender => {
    const gendersOptions = { all: undefined, men: 1, women: 3 }
    return gendersOptions[gender]
  }

  const getCampaignData = async (id) => {
    setLoading(true)
    try {
      const { data } = await getCampaignDetailAPI(id)
      if (data) {
        setCampaignData(data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setCampaignData(null)
      if(error.response?.status === 404) {
        navigate("/dashboard/campagins")
        const message = error?.response.data?.detail || "something went wrong"
        toast.error(message)
      }
    }
  }

  const getSavedAudiences = async (id) => {
    try {
      const { data } = await getSavedAudienceAPI(id)
      if (data) {
        setSavedAudiences(data)
      }
    } catch (error) {
      setSavedAudiences([])
    }
  }

  useEffect(() => {
    if (campaignId) {
      getCampaignData(campaignId)
      getSavedAudiences(campaignId)
    }

    moment.tz.setDefault("America/New_York")
  }, [])

  const makeDateTime = data => {
    if (!data.date || !data.time) return null

    const dateTime = moment(data.date + ' ' + data.time, 'YYYY-MM-DD HH:mm')
    return dateTime.format('YYYY-MM-DD HH:mm')

    // const [year, month, day] = data.date.split("-")
    // const [hour, minute] = data.time.split(":")

    // const validDate = new Date(year, month - 1, day, hour, minute)
    // // const formattedDate = validDate.toString(); // validDate.toISOString()
    // const yearf = validDate.getFullYear()
    // const monthf = String(validDate.getMonth() + 1).padStart(2, '0')
    // const dayf= String(validDate.getDate()).padStart(2, '0')
    // const hoursf = String(validDate.getHours()).padStart(2, '0')
    // const minutesf = String(validDate.getMinutes()).padStart(2, '0')

    // // return formattedDate
    // return `${yearf}-${monthf}-${dayf}T${hoursf}:${minutesf}Z`;
  }

  const handleFormsData = async values => {
    if (values && Array.isArray(values.audienceConfig) && values.audienceConfig.length === 0) {
      toast.error("Audience Targeting should not be empty!")
      return false
    }
    if (values && Array.isArray(values.optimizationAndDeliveryConfig) && values.optimizationAndDeliveryConfig.length === 0) {
      toast.error("Optimization & Delivery Configuration should not be empty!")
      return false
    }
    
    try {
      const meta_ad_set_audience_configs = values.audienceConfig.map(
        ({
          age_max: { value: age_max },
          age_min: { value: age_min },
          gender,
          locations = [],
          targetingDetails = [],
          customAudience = []
        }) => {
          const geo_locations = { countries: [], regions: [], cities: [], location_types: ['recent', 'home'] }
          const excluded_geo_locations = {
            countries: [],
            regions: [],
            cities: []
          }

          locations.map(location => {
            location.options.map(({ include, ...option }) => {
              if (include) {
                if (option.type === "country")
                  geo_locations.countries.push(option.country_code)
                else if (option.type === "region")
                  geo_locations.regions.push(option)
                else if (option.type === "city")
                  geo_locations.cities.push(option)
              } else {
                if (option.type === "country")
                  excluded_geo_locations.countries.push(option.country_code)
                else if (option.type === "region")
                  excluded_geo_locations.regions.push(option)
                else if (option.type === "city")
                  excluded_geo_locations.cities.push(option)
              }
            })
          })
          const flexible_spec = {
            connections: [],
            friends_of_connections: [],
            custom_audiences: [],
            interests: [],
            behaviors: [],
            college_years: [],
            education_majors: [],
            education_schools: [],
            education_statuses: [],
            family_statuses: [],
            home_value: [],
            interested_in: [],
            income: [],
            industries: [],
            life_events: [],
            user_adclusters: [],
            work_positions: [],
            work_employers: []
          }
          const exclusions = {
            connections: [],
            friends_of_connections: [],
            custom_audiences: [],
            interests: [],
            behaviors: [],
            college_years: [],
            education_majors: [],
            education_schools: [],
            education_statuses: [],
            family_statuses: [],
            home_value: [],
            interested_in: [],
            income: [],
            industries: [],
            life_events: [],
            user_adclusters: [],
            work_positions: [],
            work_employers: []
          }
          targetingDetails.map(location => {
            location.options.map(({ include, type, ...option }) => {
              if (include) {
                flexible_spec[type].push(option)
              } else {
                exclusions[type].push(option)
              }
            })
          })

          return {
            age_max : age_max ? age_max : null,
            age_min : age_min ? age_min : null,
            gender: genderToValueMap(gender),
            geo_locations,
            excluded_geo_locations,
            flexible_spec: [flexible_spec],
            exclusions: exclusions,
            ad_set_audience_custom_audiences: customAudience && customAudience.length > 0 ? customAudience.flatMap(({type, options}) => options.map(opt => { return { id: opt.value, name: opt.label, is_exclude: !opt.include, uid: 0 }})) : []
          }
        }
      )

      const optimization_delivery_configs =
        values.optimizationAndDeliveryConfig.map((items) => {
            return {
              optimization_goal: items.adOptimization.value,
              attribution_spec: (items.adOptimization.value && items.adOptimization.value === 'OFFSITE_CONVERSIONS') ? items.settings.value : null,
              pacing_type: items.deliveryType.value,
              bid_strategy: (campaignData && campaignData.budget_type === "ABO") ? items.bidStrategy?.value : null,
              bid_amount: ((campaignData && campaignData.bid_strategy === "COST_CAP") || (campaignData && campaignData.bid_strategy === "LOWEST_COST_WITH_BID_CAP") || (items.bidStrategy && items.bidStrategy?.value === "COST_CAP") || (items.bidStrategy && items.bidStrategy?.value === "LOWEST_COST_WITH_BID_CAP")) ? items.bidAmount : null,
              daily_budget: (items.budgetType?.value === "DAILY") ? items.budgetPrice : null,
              lifetime_budget: (items.budgetType?.value === "LIFETIME") ? items.budgetPrice : null,
              start_time: makeDateTime({ date: items.start_date, time: items.start_time }),
              stop_time: makeDateTime({ date: items.end_date, time: items.end_time })
            }
          }
        )
      
      const placement_configs = values.adPlacementConfiguration.map(
        ({ adPlacement, ...item }) =>
          adPlacement
            ? { advantage_plus: false, ...item }
            : { advantage_plus: true }
      )

      const ad_set_naming = values.adSetNaming

      const locale = values.locale
      
      setSubmitting(true)
      await updateCampaignDataAPI(parseInt(campaignId), {
        meta_ad_set_audience_configs,
        optimization_delivery_configs,
        placement_configs,
        ad_set_naming,
        locale
      })
      setSubmitting(false)
      toast.success("Information added to campagin")
      navigate(`/dashboard/ad-creation/${campaignId}`)
    } catch (error) {
      setSubmitting(false)
      const message =  (Object.values(error?.response?.data)[0][0].non_field_errors) ? displayErrors(Object.values(error?.response?.data)[0][0].non_field_errors) : (error?.response?.data) ? displayErrors(error?.response?.data) : "Something Went Wrong"
      setValidation(message)
      // confirmAlert({
      //   title: "Error",
      //   message: message,
      //   buttons: [
      //     {
      //       label: "Close"
      //     }
      //   ]
      // })
    }
  }

  return (
    <section className="mx-auto px-6 py-6 bg-gray-200 w-full">
      <div className="rounded-md">

        {(validation) && <>
          {renderErrorMessage(validation, setValidation)}
          {window.scrollTo({ top: 0, behavior: 'smooth' })}
        </> }

        <AudienceTargeting submitData={handleFormsData} campaignData={campaignData} submitting={submitting} savedAudiences={savedAudiences} />
      </div>
      {loading ? <div style={{width:'100%',height:'100vh',position:'fixed',zIndex:9,backgroundColor:'rgba(255, 255, 255, 0.6)',top:0,left:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
          <ReactLoading
              type={"spin"}
              color="var(--secondary)"
              height={"25px"}
              width={"25px"}
          />
      </div>:null}
    </section>
  )
}

export default CreateCampaignSteps
