import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { FaChevronRight, FaChevronLeft, FaTrash } from "react-icons/fa"
import { confirmAlert } from "react-confirm-alert"
import { useNavigate, useParams } from "react-router-dom"

import { AdPreview, Button, Spinner, BaseModal, Toggle, Input, Checkbox } from "../../../components"
import { getCampaignDetailAPI, pushAdstoFacebook } from "../../../service"
import { displayErrors } from "../../../helpers"
import { BiCheckCircle } from "react-icons/bi"
import { MdClose } from "react-icons/md"
import { AiFillCloseCircle } from "react-icons/ai"

const AdReview = ({ }) => {

  const navigate = useNavigate()
  const { campaignId } = useParams()
  const [campaignData, setCampaignData] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [currentSliderIndex, setSliderIndex] = useState(0)
  const [adsets, setAllAdsets] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [oneAdCombinationForEachAdset, setOneAdsetforEachCombination] = useState(false)
  const [isDynamicCreative, setIsDynamicCreative] = useState(false)

  const getCampaignDetails = async (id) => {
    try {

      const { data } = await getCampaignDetailAPI(id)
      setCampaignData(data)
      const adsCombinations = getAllAdsCombinations(
        data.meta_description_configs,
        data.meta_media_configs,
        data.meta_text_configs,
        data.meta_headline_configs,
        data.ad_naming
      )
      const adsetCombinations = getAllAdsetCombinations(
        data.optimization_delivery_configs,
        data.placement_configs,
        data.meta_ad_set_audience_configs,
        [...adsCombinations],
        oneAdCombinationForEachAdset,
        data.ad_set_naming,
      )
      setAllAdsets(adsetCombinations)
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  useEffect(() => {
    if (campaignId) {
      getCampaignDetails(campaignId)
    }
  }, [oneAdCombinationForEachAdset])

  const getAllAdsCombinations = (
    meta_description_configs = [],
    meta_media_configs = [],
    meta_text_configs = [],
    meta_headline_configs = [],
    ad_naming = ""
  ) => {
    const allAdsCombinations = []
    if (meta_description_configs && meta_description_configs.length > 0) {
      for (const meta_description_config in meta_description_configs) {
        for (const meta_media_config in meta_media_configs) {
          for (const meta_text_config in meta_text_configs) {
            for (const meta_headline_config in meta_headline_configs) {
              allAdsCombinations.push({
                id: `${meta_description_config}-${meta_media_config}-${meta_text_config}-${meta_headline_config}`,
                meta_description_config: meta_description_configs[meta_description_config],
                meta_media_config: meta_media_configs[meta_media_config],
                meta_text_config: meta_text_configs[meta_text_config],
                meta_headline_config: meta_headline_configs[meta_headline_config],
                naming: ad_naming
              })
            }
          }
        }
      }
    } else {
      for (const meta_media_config in meta_media_configs) {
        for (const meta_text_config in meta_text_configs) {
          for (const meta_headline_config in meta_headline_configs) {
            allAdsCombinations.push({
              id: `${meta_media_config}-${meta_text_config}-${meta_headline_config}`,
              meta_description_config: '',
              meta_media_config: meta_media_configs[meta_media_config],
              meta_text_config: meta_text_configs[meta_text_config],
              meta_headline_config: meta_headline_configs[meta_headline_config],
              naming: ad_naming
            })
          }
        }
      }
    }

    return allAdsCombinations
  }

  const getAllAdsetCombinations = (
    optimization_delivery_configs,
    placement_configs,
    meta_ad_set_audience_configs,
    adsCombinations,
    oneAdCombinationForEachAdset = false,
    ad_set_naming = ""
  ) => {
    if (oneAdCombinationForEachAdset) {
      const allAdsetCombinations = []
      for (const adsCombination of adsCombinations) {
        for (const optimization_delivery_config of optimization_delivery_configs) {
          for (const placement_config of placement_configs) {
            for (const meta_ad_set_audience_config of meta_ad_set_audience_configs) {
              allAdsetCombinations.push({
                optimization_delivery_config,
                placement_config,
                meta_ad_set_audience_config,
                adsCombinations: [adsCombination],
                naming: ad_set_naming
              })
            }
          }
        }
      }
      return allAdsetCombinations
    }
    const allAdsetCombinations = []
    for (const optimization_delivery_config of optimization_delivery_configs) {
      for (const placement_config of placement_configs) {
        for (const meta_ad_set_audience_config of meta_ad_set_audience_configs) {
          allAdsetCombinations.push({
            optimization_delivery_config,
            placement_config,
            meta_ad_set_audience_config,
            adsCombinations,
            naming: ad_set_naming
          })
        }
      }
    }
    return allAdsetCombinations
  }

  const removeAdCombinationFromAdset = (adsetIndex, adIndex) => {
    const newAdsets = [...adsets]
    const newCombination = [...newAdsets[adsetIndex].adsCombinations]
    newCombination.splice(adIndex, 1)

    newAdsets[adsetIndex].adsCombinations = newCombination

    setAllAdsets(newAdsets)
  }

  const removeAdCombinationFromAllAdsetsById = adId => {
    const newAdsets = [...adsets]
    newAdsets.forEach(adset => {
      adset.adsCombinations = adset.adsCombinations.filter(ad => ad.id !== adId)
    })
    setAllAdsets(newAdsets)
  }

  const removeAdset = adsetIndex => {
    const newAdsets = [...adsets]
    newAdsets.splice(adsetIndex, 1)
    setAllAdsets(newAdsets)
  }

  const submitCampaign = async adsets => {

    if (isDynamicCreative) {
      adsets = adsets.map(ad => {
        return {
          ...ad, is_dynamic_creative: true, ads: [{
            naming: campaignData.ad_naming,
            meta_media_image_ids: campaignData.meta_media_configs.map((i) => i.meta_media_images && i.meta_media_images[0] && i.meta_media_images[0].id),
            meta_text_config_ids: campaignData.meta_text_configs.map((i) => i.id),
            meta_headline_config_ids: campaignData.meta_headline_configs.map((i) => i.id),
            meta_description_config_ids: campaignData.meta_description_configs.map((i) => i.id)
          }]
        }
      })
    }
    
    try {
      setSubmitting(true)

      await pushAdstoFacebook(campaignId, { adsets })
      setSubmitting(false)
      setOpenSuccess(!openSuccess)
      // toast.success("Campaign Created Successfully!")
    } catch (error) {
      setSubmitting(false)
      const message =
        displayErrors(error?.response?.data) || "Something Went Wrong"
      confirmAlert({
        title: "Error",
        message: message,
        buttons: [
          {
            label: "Close"
          }
        ]
      })
    }
  }

  const handleSubmitForm = e => {
    const configs = []
    e.preventDefault()
    setOpenConfirm(!openConfirm)

    for (const adset of adsets) {
      const ads = []
      for (const adsCombination of adset.adsCombinations) {
        const ad = {
          naming: adsCombination.naming,
          meta_text_config_id: adsCombination.meta_text_config.id,
          meta_headline_config_id: adsCombination.meta_headline_config.id,
          meta_description_config_id: adsCombination.meta_description_config.id,
          meta_media_config_id: adsCombination.meta_media_config.id
        }
        ads.push(ad)
      }

      configs.push({
        naming: adset.naming,
        meta_ad_set_audience_config_id: adset.meta_ad_set_audience_config.id,
        placement_config_id: adset.placement_config.id,
        optimization_delivery_config_id: adset.optimization_delivery_config.id,
        ads
      })
    }

    submitCampaign(configs)
  }

  const handleSubmit = e => {
    setOpenConfirm(!openConfirm)
    e.preventDefault()
  }

  const formatTargetDetail = (x) => {
    var output = []
    for (let i in x) {
      for (let key in x[i]) {
        if (Array.isArray(x[i][key]) && x[i][key].length > 0) {
          x[i][key].map((items) => { output.push(items) })
        }
      }
    }
    return output.map(({ name }) => name).join(", ")
  }

  const handleAdsetNaming = (e, index) => {
    const temp = [...adsets]
    temp[index]['naming'] = e.target.value
    setAllAdsets(temp)
  }

  const handleAdNaming = (e, cIndex, index) => {
    const temp = [...adsets]
    temp[cIndex]['adsCombinations'][index]['naming'] = e.target.value
    setAllAdsets(temp)
  }

  const handleCheckboxChange = (e, cIndex, index) => {
    const temp = [...adsets]
    if (e.target.checked) {
      temp[cIndex]['adsCombinations'][index]['selected'] = true
    } else {
      temp[cIndex]['adsCombinations'][index]['selected'] = false
    }
    setAllAdsets(temp)
  }

  const handleSelectAll = (e, cIndex) => {
    const temp = [...adsets]

    if (e.target.checked) {
      temp[cIndex]['adsCombinations'] = temp[cIndex]['adsCombinations'].map((items) => { return { ...items, selected: true } })
    } else {
      temp[cIndex]['adsCombinations'] = temp[cIndex]['adsCombinations'].map((items) => { return { ...items, selected: false } })
    }
    setAllAdsets(temp)
  }

  const removeSelectedAds = (adsetIndex) => {
    const newAdsets = [...adsets]
    const newCombination = [...newAdsets[adsetIndex].adsCombinations].filter(ad => typeof ad.selected === undefined || !ad.selected)

    newAdsets[adsetIndex].adsCombinations = newCombination

    setAllAdsets(newAdsets)
  }

  const changeCurrentStatus = (status) => {
    setIsDynamicCreative(status)
  }

  const handleRemoveImages = (i) => {
    if (campaignData) {
      const updatedArray = campaignData.meta_media_configs.filter((_, index) => index !== i)
      setCampaignData({ ...campaignData, meta_media_configs: updatedArray })
    }
  }
  const handleRemoveText = (i) => {
    if (campaignData) {
      const updatedArray = campaignData.meta_text_configs.filter((_, index) => index !== i)
      setCampaignData({ ...campaignData, meta_text_configs: updatedArray })
    }
  }
  const handleRemoveHeadline = (i) => {
    if (campaignData) {
      const updatedArray = campaignData.meta_headline_configs.filter((_, index) => index !== i)
      setCampaignData({ ...campaignData, meta_headline_configs: updatedArray })
    }
  }
  const handleRemoveDescription = (i) => {
    if (campaignData) {
      const updatedArray = campaignData.meta_description_configs.filter((_, index) => index !== i)
      setCampaignData({ ...campaignData, meta_description_configs: updatedArray })
    }
  }

  return (
    <section className="mx-auto px-6 py-6 bg-gray-200 w-full">
      <form
        onSubmit={handleSubmit}
        className="border border-gray-300 bg-white rounded-md p-10 min-h-screen"
      >
        <div className="flex justify-between items-center">
          <div className="flex justify-start">
            <Button
              className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
              onClick={() => setOneAdsetforEachCombination(false)}
            >
              Adsets with Multiple Ads
            </Button>
            <Button
              type="button"
              className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
              onClick={() => setOneAdsetforEachCombination(true)}
            >
              Ad per Adset
            </Button>

            <div className="flex items-center ml-8">
              <Toggle
                isChecked={isDynamicCreative}
                onChange={status =>
                  changeCurrentStatus(status)
                }
              />
              <span className="ml-2 cursor-pointer">Dynamic Creative</span>
            </div>
          </div>

          <div className="flex justify-start">
            <div className="border border-gray-300 rounded-md py-3 px-5"><p><span className="font-semibold">Total Adsets:</span> {adsets && adsets.length}</p></div>
          </div>
          <div className="flex justify-end gap-2">
            <div
              className="rounded-full p-2 border bg-[#B6CC43] w-10 h-10 flex justify-center items-center cursor-pointer"
              onClick={() =>
                setSliderIndex(
                  currentSliderIndex > 0
                    ? currentSliderIndex - 1
                    : currentSliderIndex
                )
              }
            >
              <FaChevronLeft color="#fff" />
            </div>
            <div
              className="rounded-full p-2 border bg-[#B6CC43] w-10 h-10 flex justify-center items-center cursor-pointer"
              onClick={() =>
                setSliderIndex(
                  currentSliderIndex < adsets.length - 1
                    ? currentSliderIndex + 1
                    : currentSliderIndex
                )
              }
            >
              <FaChevronRight color="#fff" />
            </div>
          </div>
        </div>
        {adsets.length > currentSliderIndex && (
          <div className="border px-3 py-3 mt-5 shadow-sm rounded-md">
            <div className="flex justify-between my-2">
              <div className="flex items-center">
                <p className="font-bold mr-8">Ad set {currentSliderIndex + 1}</p>
                <Input
                  placeholder="Enter Ad Set Name"
                  name={`ad_set_naming`}
                  id={`ad_set_naming`}
                  onChange={(e) => handleAdsetNaming(e, currentSliderIndex)}
                  value={adsets && adsets[currentSliderIndex]?.naming}
                  style={{ width: "600px" }}
                />
                <div className="border border-gray-300 rounded-md py-2 px-5 ml-8"><p><span className="font-semibold">Total Ads:</span> {adsets && adsets[currentSliderIndex]?.adsCombinations.length}</p></div>
              </div>
              <button
                className="text-red-600 flex items-center gap-1"
                onClick={() => removeAdset(currentSliderIndex)}
                type="button"
              >
                <FaTrash size={20} />
                Remove Adset
              </button>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="col-span-1 bg-[#F3F3F3] border rounded-md border-black px-2 py-3">
                {/* <p className="font-bold">Facebook Page</p>
                <ul className="list-disc list-inside flex gap-3 ml-3">
                  <li>{(campaignData && campaignData.meta_facebook_page_config && campaignData.meta_facebook_page_config.name) ? campaignData.meta_facebook_page_config.name : ''}</li>
                </ul> */}
                <div className="grid grid-cols-2 gap-y-5 my-4">
                  <div>
                    <p className="font-bold">Optimization for ad Delivery</p>
                    <p className="mt-0">
                      {(campaignData && campaignData.optimization_delivery_configs && campaignData.optimization_delivery_configs.length > 0) && <>
                        {campaignData.optimization_delivery_configs.map((items) => { return items.optimization_goal && items.optimization_goal.replace('_', ' ') }).join(', ')}
                      </>}
                    </p>
                  </div>
                  <div>
                    <p className="font-bold">Budget Type</p>
                    <p className="mt-0">{(campaignData && campaignData.budget_type) ? campaignData.budget_type : ''}</p>
                  </div>

                  <div>
                    <p className="font-bold">Budget</p>
                    <p className="mt-0">{(campaignData.optimization_delivery_configs[0].daily_budget) ? 'DAILY' : (campaignData.optimization_delivery_configs[0].lifetime_budget) ? 'LIFE TIME' : (campaignData && campaignData.daily_budget) ? 'DAILY' : (campaignData && campaignData.lifetime_budget) ? 'LIFE TIME' : ''}</p>
                  </div>

                  {(campaignData && campaignData.budget_type && campaignData.budget_type === 'CBO') && <>
                    <div>
                      <p className="font-bold">Budget Amount</p>
                      <p className="mt-0">{(campaignData && campaignData.daily_budget) ? `$${campaignData.daily_budget/100}` : (campaignData && campaignData.lifetime_budget) ? campaignData.lifetime_budget/100 : ''}</p>
                    </div>
                    <div>
                      <p className="font-bold">Bid Strategy</p>
                      <p className="mt-0">{(campaignData && campaignData.bid_strategy) ? campaignData.bid_strategy.replaceAll('_', ' ') : ''}</p>
                    </div>
                  </>}

                  {(campaignData && campaignData.budget_type && campaignData.budget_type === 'ABO' && campaignData.optimization_delivery_configs && campaignData.optimization_delivery_configs.length > 0) && <>
                    <div>
                      <p className="font-bold">Budget Amount</p>
                      <p className="mt-0">{(campaignData.optimization_delivery_configs[0].daily_budget) ? `$${campaignData.optimization_delivery_configs[0].daily_budget}` : (campaignData.optimization_delivery_configs[0].lifetime_budget) ? campaignData.optimization_delivery_configs[0].lifetime_budget : ''}</p>
                    </div>
                    <div>
                      <p className="font-bold">Bid Strategy</p>
                      <p className="mt-0">{(campaignData.optimization_delivery_configs[0].bid_strategy) ? campaignData.optimization_delivery_configs[0].bid_strategy.replaceAll('_', ' ') : ''}</p>
                    </div>
                  </>}

                  {((campaignData.optimization_delivery_configs[0].bid_strategy && ['COST_CAP', 'LOWEST_COST_WITH_BID_CAP'].includes(campaignData.optimization_delivery_configs[0].bid_strategy)) || (campaignData && campaignData.bid_strategy && ['COST_CAP', 'LOWEST_COST_WITH_BID_CAP'].includes(campaignData.bid_strategy))) &&
                    <div>
                      <p className="font-bold">Bid Amount</p>
                      <p className="mt-0">{(campaignData.optimization_delivery_configs[0].bid_amount) ? `$${campaignData.optimization_delivery_configs[0].bid_amount}` : ''}</p>
                    </div>
                  }
                </div>
              </div>
              <div className="col-span-1 bg-[#F3F3F3] border rounded-md border-black px-2 py-3">
                <p className="font-bold">Audience Configuration</p>
                <p className="mt-0">Audience 1</p>

                <div className="grid gap-y-5 my-4">
                  <span>
                    <p className="font-bold">Location</p>
                    <p className="mt-0">
                      {(adsets[currentSliderIndex]?.meta_ad_set_audience_config?.geo_locations && adsets[currentSliderIndex]?.meta_ad_set_audience_config.geo_locations.countries && adsets[currentSliderIndex]?.meta_ad_set_audience_config.geo_locations.countries.length > 0) &&
                        adsets[currentSliderIndex]
                          ?.meta_ad_set_audience_config
                          ?.geo_locations.countries.join(", ")
                      }
                    </p>
                    <p className="mt-0">
                      {(adsets[currentSliderIndex]?.meta_ad_set_audience_config?.geo_locations && adsets[currentSliderIndex]?.meta_ad_set_audience_config.geo_locations.cities && adsets[currentSliderIndex]?.meta_ad_set_audience_config.geo_locations.cities.length > 0) &&
                        [
                          ...adsets[currentSliderIndex]
                            ?.meta_ad_set_audience_config
                            ?.geo_locations.cities
                        ]
                          .map(
                            ({ name, country_name = "" }) =>
                              name + " - " + country_name
                          )
                          .join(", ")}
                    </p>
                    <p className="mt-0">
                      {(adsets[currentSliderIndex]?.meta_ad_set_audience_config?.geo_locations && adsets[currentSliderIndex]?.meta_ad_set_audience_config.geo_locations.regions && adsets[currentSliderIndex]?.meta_ad_set_audience_config.geo_locations.regions.length > 0) &&
                        [
                          ...adsets[currentSliderIndex]
                            ?.meta_ad_set_audience_config
                            ?.geo_locations.regions
                        ]
                          .map(
                            ({ name, country_name = "" }) =>
                              name + " - " + country_name
                          )
                          .join(", ")}
                    </p>
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-y-5 my-4">
                  <span>
                    <p className="font-bold">Gender</p>
                    <p className="mt-0">
                      {!adsets[currentSliderIndex]?.meta_ad_set_audience_config
                        ?.gender
                        ? "All"
                        : adsets[currentSliderIndex]
                          ?.meta_ad_set_audience_config?.gender === 1
                          ? "Male"
                          : "Female"}
                    </p>
                  </span>
                  <span>
                    <p className="font-bold">Age</p>
                    <p className="mt-0">
                      {
                        adsets[currentSliderIndex]?.meta_ad_set_audience_config
                          ?.age_min || "18"
                      }
                      -
                      {
                        adsets[currentSliderIndex]?.meta_ad_set_audience_config
                          ?.age_max || "65+"
                      }
                    </p>
                  </span>
                  <span>
                    <p className="font-bold">Target Audience</p>
                    <p className="mt-0">
                      {adsets[currentSliderIndex]?.meta_ad_set_audience_config
                        ?.ad_set_audience_custom_audiences &&
                        [
                          ...adsets[currentSliderIndex]
                            ?.meta_ad_set_audience_config
                            ?.ad_set_audience_custom_audiences
                        ]
                          .map(({ name }) => name)
                          .join(", ")}
                    </p>
                  </span>
                  <span>
                    <p className="font-bold">Detailed Targeting</p>
                    <p className="mt-0">
                      {adsets[currentSliderIndex]?.meta_ad_set_audience_config?.flexible_spec &&
                        formatTargetDetail(adsets[currentSliderIndex]?.meta_ad_set_audience_config?.flexible_spec)}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="py-3 mt-5 shadow-sm rounded-md">
          {(!isDynamicCreative) && <>
            <div className="flex items-center justify-end">
              <div className="flex items-center mr-5">
                <Checkbox
                  name="budget_type"
                  className="w-5 h-5"
                  onChange={(e) => handleSelectAll(e, currentSliderIndex)}
                /> <span className="text-lg">Select All Ads</span>
              </div>

              <button
                className="border text-red-600 border-red-300 rounded-md flex items-center gap-1 px-5 py-3"
                onClick={() => removeSelectedAds(currentSliderIndex)}
                type="button"
              >
                <FaTrash size={16} />
                Remove Ads
              </button>
            </div>
          </>}

          {(isDynamicCreative) ? <>
            <div class="grid grid-cols-2 grid-flow-col gap-4">
              <div className="">
                <div>
                  <h4 className="text-lg font-semibold pb-3 border-b pt-5">
                    Images
                  </h4>
                  <div className="my-5 grid grid-cols-3 gap-4">
                    {campaignData && campaignData.meta_media_configs.map((item, index) => (<>
                      {(item.meta_media_images && item.meta_media_images[0] && item.meta_media_images[0].is_video) ? <>
                        <video className="w-[250px] object-fill" controls>
                          <source src={item.meta_media_images[0].file} type="video/mp4" />
                        </video>
                      </> : (item.meta_media_images[0] && item.meta_media_images[0].file) ? (<div className="relative">
                        <AiFillCloseCircle className="absolute right-0 top-0 cursor-pointer" size={20} onClick={() => handleRemoveImages(index)} />
                        <img src={item.meta_media_images[0] && item.meta_media_images[0].file} className="w-[250px] object-fill" />
                      </div>) : <></>}
                    </>))}
                  </div>
                </div>

                <div>
                  <h4 className="text-lg font-semibold pb-3 border-b pt-5">
                    Primary Text
                  </h4>
                  <div className="my-5">
                    {campaignData && campaignData.meta_text_configs.map((item, index) => (<>
                      <div className="flex items-center justify-between border rounded-md shadow-md px-5 mb-2">
                        <p className="my-4 whitespace-pre-line">{item.text}</p>
                        <MdClose className="cursor-pointer" onClick={() => handleRemoveText(index)} />
                      </div>
                    </>))}
                  </div>
                </div>

                <div>
                  <h4 className="text-lg font-semibold pb-3 border-b pt-5">
                    Headline
                  </h4>
                  <div className="my-5">
                    {campaignData && campaignData.meta_headline_configs.map((item, index) => (<>
                      <div className="flex items-center justify-between border rounded-md shadow-md px-5 mb-2">
                        <p className="my-4 whitespace-pre-line">{item.text}</p>
                        <MdClose className="cursor-pointer" onClick={() => handleRemoveHeadline(index)} />
                      </div>
                    </>))}
                  </div>
                </div>

                <div>
                  <h4 className="text-lg font-semibold pb-3 border-b pt-5">
                    Description <span className="text-xs font-medium">(Optional)</span>
                  </h4>
                  <div className="my-5">
                    {campaignData && campaignData.meta_description_configs.map((item, index) => (<>
                      <div className="flex items-center justify-between border rounded-md shadow-md px-5 mb-2">
                        <p className="my-4 whitespace-pre-line">{item.text}</p>
                        <MdClose className="cursor-pointer" onClick={() => handleRemoveDescription(index)} />
                      </div>
                    </>))}
                  </div>
                </div>

              </div>
              <div></div>
            </div>
          </>

            : <>
              {(adsets && adsets[currentSliderIndex]?.adsCombinations && adsets[currentSliderIndex]?.adsCombinations.length > 0) ? <>
                <div className="grid grid-cols-3 3xl:grid-cols-5 gap-x-3 gap-y-4 mt-5">
                  {adsets[currentSliderIndex]?.adsCombinations.map((item, index) => (
                    <AdPreview
                      calltoaction={(campaignData && campaignData.meta_facebook_page_config && campaignData.meta_facebook_page_config.call_to_action) ? campaignData.meta_facebook_page_config.call_to_action : 'LEARN MORE'}
                      title={item.meta_text_config.text}
                      headline={item.meta_headline_config.text}
                      description={item.meta_description_config.text}
                      image={item.meta_media_config.meta_media_images[0] && item.meta_media_config.meta_media_images[0].file}
                      handleRemove={() =>
                        removeAdCombinationFromAdset(currentSliderIndex, index)
                      }
                      handleRemoveAll={() =>
                        removeAdCombinationFromAllAdsetsById(item.id)
                      }
                      isVideo={item.meta_media_config.meta_media_images[0] && item.meta_media_config.meta_media_images[0].is_video}
                      naming={item.naming}
                      handleInputChange={(e) => handleAdNaming(e, currentSliderIndex, index)}
                      handleCheckboxChange={(e) => handleCheckboxChange(e, currentSliderIndex, index)}
                      preview={true}
                      selected={item.selected || false}
                    />
                  ))}
                </div>
              </> : <>
                <div className="text-center border my-8 py-8 rounded-md">
                  <p className="text-md text-gray-500">No Ads Found!</p>
                </div>
              </>}
            </>}
        </div>

        <div className="flex justify-end mt-5">
          <Button
            className="my-2 md:my-0 px-7 py-2 bg-[#00B2B4] rounded-xl mr-3"
            onClick={() => navigate(`/dashboard/ad-creation/${campaignId}`)}
          >
            Previous
          </Button>
          <Button
            type="submit"
            className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
          >
            {(submitting) ? <Spinner className="w-5 h-5" /> : 'Publish'}
          </Button>
        </div>
      </form>

      <BaseModal open={openConfirm} setOpen={setOpenConfirm} title="Confirm Campaign">
        <div className="md:flex items-center">
          <div className="mt-4 md:mt-0 md:text-left">
            <p className="text-sm text-gray-700 mt-1">This message is to ensure that the provided information is correct. Do you wish to continue?</p>
          </div>
        </div>
        <div className="text-center md:text-right mt-4 md:flex md:justify-end">
          <button className="block w-full md:inline-block md:w-auto px-5 py-3 md:py-2 bg-[#B6CC43] text-white rounded-lg font-semibold text-sm" onClick={handleSubmitForm}>Yes</button>
          <button className="block w-full md:inline-block md:w-auto px-5 py-3 md:py-2 bg-[#F51C1C] text-white rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:ml-2" onClick={() => { setOpenConfirm(!openConfirm) }}>No</button>
        </div>
      </BaseModal>

      <BaseModal open={openSuccess} setOpen={setOpenSuccess}>
        <div className="md:flex items-center my-4">
          <div className="flex items-center flex-col w-full mt-4 md:mt-0 text-center">
            <BiCheckCircle size="80" color="#B6CC43" />
            <h3>Success</h3>
            <p className="text-md text-gray-700 mt-1">We're pushing the ads to Facebook, you'll receive a confirmation email in a few minutes!</p>
          </div>
        </div>
        <div className="text-center md:text-right mt-4 md:flex md:justify-center">
          <button className="block w-full md:inline-block md:w-auto px-5 py-3 md:py-2 bg-[#B6CC43] text-white rounded-lg font-semibold text-sm" onClick={() => { setOpenSuccess(!openSuccess); navigate(`/dashboard/campagins`) }}>OK</button>
        </div>
      </BaseModal>
    </section>
  )
}

export default AdReview
