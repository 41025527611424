import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"
import { confirmAlert } from "react-confirm-alert"

import { displayErrors } from "../../../helpers"
import { CampaignStructure } from "../../../components/campagins/createCompagins/conversionForms"
import {
  createCampaignAPI,
  getFacebookAdAccounts,
  getVerticalsAPI,
  getLandingPagesAPI,
  getCampaignDetailAPI,
  updateCampaignDataAPI
} from "../../../service"
import { useNavigate, useParams } from "react-router-dom"
import ReactLoading from "react-loading";

const CreateCampaignSteps = ({ }) => {
  const navigate = useNavigate()
  const { campaignId } = useParams()
  const [currentStep, setCurrentStep] = useState(1)
  const [adAccounts, setAddAccounts] = useState([])
  const [campaignData, setCampaignData] = useState(null)
  // const [offers, setOffers] = useState([])
  const [landingPages, setLandingPages] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState(null)

  const [loading, setLoading] = useState(false)

  const handleGetFacebookAccount = async () => {
    try {
      const { data } = await getFacebookAdAccounts()
      if (data.length) {
        const ad_accounts = data.map((item) => {
          return { label: item.name, value: item.id }
        })
        setAddAccounts(ad_accounts)
      }
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      // toast.error(message)
    }
  }

  const getCampaignData = async (id) => {
    setLoading(true)
    try {
      const { status, data } = await getCampaignDetailAPI(id)
      if (data) {
        setCampaignData(data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setCampaignData(null)
      if(error.response?.status === 404) {
        navigate("/dashboard/campagins")
        const message = error?.response.data?.detail || "something went wrong"
        toast.error(message)
      }
    }
  }

  // const getOffers = async () => {
  //   try {
  //     const { data } = await getVerticalsAPI()
  //     console.log("newOffers", data)
  //     if (data.length) {
  //       const newOffers = data?.map(item => {
  //         return { label: item.name, value: item.id }
  //       })
  //       setOffers(newOffers)
  //     }
  //   } catch (error) {
  //     const message = error?.response.data?.detail || "something went wrong"
  //     console.log("ERROR", error)
  //     toast.error(message)
  //   }
  // }

  const getLandingPages = async () => {
    try {
      const { data } = await getLandingPagesAPI()
      setLandingPages(data.map(({ id, name }) => ({ label: name, value: id })))

      if (data.length) {
        const newOffers = data?.map(item => {
          return { label: item.name, value: item.id }
        })
        setLandingPages(newOffers)
      }
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  useEffect(() => {
    handleGetFacebookAccount()
    // getOffers()
    getLandingPages()
    if (campaignId) {
      getCampaignData(campaignId)
    }
  }, [])

  const makeDateTime = data => {
    if (!data.date || !data.time) return null
    const [year, month, day] = data.date.split("-")
    const [hour, minute] = data.time.split(":")

    const validDate = new Date(year, month - 1, day, hour, minute)
    const formattedDate = validDate.toISOString()

    console.log(formattedDate)
    return formattedDate
  }

  const handleFormsData = async values => {
    console.log(values);
    try {
      const finalData = {
        ad_account: values.ad_account.value,
        landing_page: values.landing_page.value,
        bid_strategy: values.budget_type === "ABO" ? null : values.bid_strategy.value,
        daily_budget:
          values.budget_type === "ABO"
            ? null
            : values.budget_spend_critaria.value === "DAILY"
              ? values.budget * 100
              : null,
        lifetime_budget:
          values.budget_type === "ABO"
            ? null
            : values.budget_spend_critaria.value === "LIFETIME"
              ? values.budget * 100
              : null,
        name: values.name,
        objective: values.objective.value,
        special_ad_categories: [values.special_ad_categories.value],
        budget_type: values.budget_type,
        // spend_cap: values.spend_cap === "" || values.budget_type === "ABO" ? null  : values.spend_cap
        // start_time:
        //   values.budget_type === "ABO"
        //     ? null
        //     : makeDateTime({
        //         date: values.start_date,
        //         time: values.start_time
        //       }),
        // stop_time:
        //   values.budget_type === "ABO"
        //     ? null
        //     : makeDateTime({
        //         date: values.start_date,
        //         time: values.start_time
        //       })
      }

      const urlParams = new URLSearchParams(window.location.search)
      const phaseparam = urlParams.get('testPhaseId')

      if (phaseparam) {
        finalData.test_phase = parseInt(phaseparam)
      }

      if (campaignId) {
        setSubmitting(true)
        const { data } = await updateCampaignDataAPI(campaignId, finalData)
        toast.success("Campagin Updated")
        setSubmitting(false)
        navigate(`/dashboard/audience-targeting/${data?.id}`)
      } else {
        setSubmitting(true)
        const { data } = await createCampaignAPI(finalData)
        toast.success("Campagin Created")
        navigate(`/dashboard/audience-targeting/${data?.id}`)
        setSubmitting(false)
      }
    } catch (error) {

      setSubmitting(false)
      const message = displayErrors(error?.response?.data) || "Something Went Wrong"
      setErrors(error?.response?.data)
      // confirmAlert({
      //   title: "Validation Error",
      //   message: renderMessage(message),
      //   buttons: [
      //     {
      //       label: "Close"
      //     }
      //   ]
      // })
    }
  }

  const handleNext = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <section className="mx-auto px-6 py-6 bg-gray-200 w-full">
      <div className="rounded-md p-0">

        {/* {(validation) && <>
          {renderErrorMessage(validation, setValidation)}
          {window.scrollTo({ top: 0, behavior: 'smooth' })}
        </> } */}
        <CampaignStructure
          submitData={handleFormsData}
          onNext={handleNext}
          ad_accounts={adAccounts || []}
          // offers={offers || []}
          landingPages={landingPages}
          campaignData={campaignData}
          submitting={submitting}
          errors={errors}
        />
      </div>
      {loading ? <div style={{width:'100%',height:'100vh',position:'fixed',zIndex:9,backgroundColor:'rgba(255, 255, 255, 0.6)',top:0,left:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
          <ReactLoading
              type={"spin"}
              color="var(--secondary)"
              height={"25px"}
              width={"25px"}
          />
      </div>:null}
    </section>
  )
}

export default CreateCampaignSteps
