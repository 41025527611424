import React, { useState } from "react"
import { FaChevronRight, FaChevronLeft, FaTrash } from "react-icons/fa"
import { AiOutlineLike } from "react-icons/ai"
import { BiComment } from "react-icons/bi"
import { IoIosShareAlt } from "react-icons/io"
import { Button, Input, Checkbox,Textarea } from "../common"

const AdPreview = ({
  handleNext,
  handleBack,
  title,
  headline,
  description,
  image,
  handleRemove,
  handleRemoveAll,
  isVideo = false,
  preview = false,
  naming = "",
  handleInputChange,
  calltoaction,
  selected = false,
  handleCheckboxChange
}) => {
  return (
    <div className="border border-gray-300 rounded-md bg-white py-5 px-5">
      <div className="flex justify-end">
        {handleNext && (
          <div className="rounded-full p-2 border" onClick={handleNext}>
            <FaChevronLeft />
          </div>
        )}
        {handleBack && (
          <div className="rounded-full p-2 border" onClick={handleBack}>
            <FaChevronRight />
          </div>
        )}
      </div>
      {(preview) &&
        <div>
          <Checkbox
            name="ad_checkbox"
            className="w-5 h-5"
            onChange={handleCheckboxChange}
            checked={selected}
          />
        </div>
      }
      {(preview) &&
        <div>
          <Textarea
                        placeholder="Enter Ad Name"
                        rows={3}
                        name={`naming-structure`}
                        onChange={handleInputChange}
                        value={naming && naming}
                      />
          
        </div>
      }
      <p className="my-4 whitespace-pre-line">{title}</p>
      {(isVideo) ? <>
        <video className="w-[600px] object-fill" controls>
          <source src={image} type="video/mp4" />
        </video>
      </> : image ? (
        <img src={image} className="w-[600px] object-fill" />
      ) : (
        <div className="w-[600px] h-[600px]" />
      )}
      <h1 className="text-xl mt-4">{headline}</h1>
      <div className="grid grid-cols-2 gap-4 my-5">
        <p>{description}</p>
        <div className="flex items-center justify-center ml-2">
          <Button className={`px-3 py-3 bg-[#adadad] rounded-md text-xs text-black`}>
            {(calltoaction) ? calltoaction.replace('_', " ") : 'LEARN MORE'}
          </Button>
        </div>
      </div>
      <div className="border-t w-full pt-4 flex justify-between">
        <div className="flex items-center gap-1">
          <AiOutlineLike size={30} />
          <span>Like</span>
        </div>
        <div className="flex items-center gap-1">
          <BiComment size={30} />
          <span>Comment</span>
        </div>
        <div className="flex items-center gap-1">
          <IoIosShareAlt size={30} />
          <span>Share</span>
        </div>
      </div>
      {(handleRemove || handleRemoveAll) && (
        <div className="border-t w-full pt-4 flex justify-between text-red-600">
          {handleRemove && (
            <button 
            type="button"
            className="flex items-center gap-1" 
            onClick={handleRemove}>
              <FaTrash size={20} />
              <span>Remove</span>
            </button>
          )}
          {handleRemoveAll && (
            <button
              type="button"
              className="flex items-center gap-1 "
              onClick={handleRemoveAll}
            >
              <FaTrash size={20} />
              <span>Remove Everywhere</span>
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default AdPreview
