import { useEffect, useState, createRef, useCallback, useRef } from "react"
import { FaMapMarkerAlt, FaTimes } from "react-icons/fa"

import { IconContext } from "react-icons"
import { useFormik } from "formik"
import AsyncSelect from "react-select/async"
import { findIndex, debounce } from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import {
  Checkbox,
  Input,
  Dropdown,
  Tabs,
  Button,
  Toggle,
  Select,
  BaseModal,
  AdPreview,
  Spinner,
  Textarea,
  Pagination
} from "../../common"
import { ManageColumnsCompaginsModals, AddTokenModal } from "../modals"

import {
  BID_STRATEGY_ENUM,
  OBJECTIVE_ENUM,
  SPECIAL_AD_CATEGORIES_ENUM,
  BUDGET_TYPE_ENUM,
  OPTIMIZATION_GOALS,
  OPTIMIZATION_GOALS_LEADS,
  OPTIMIZATION_GOALS_SALES,
  OPTIMIZATION_GOALS_TRAFFIC,
  ATTRIBUTATION_SETTINGS,
  DELIVERY_TYPE,
  LANGUAGES,
  PIXEL_DROPDOWN,
  CALL_TO_ACTIONS,
  CALL_TO_ACTION_INSTANT,
  CUSTOM_EVENT_TYPE_LEAD,
  CUSTOM_EVENT_TYPE_SALES
} from "../../../utils/constants"

import {
  facebookSearchDemographics,
  facebookSearchLocale,
  facebookSearchLocation,
  getCustomAudience,
  getFacebookPages,
  getMetaDescriptionConfigs,
  getMetaHeadlineConfigs,
  getMetaMediaImagesConfigs,
  getMetaTextConfigs,
  getInstantForm,
  getFacebookPixels,
  getCreativeSelector
} from "../../../service"
import { validURL, getCountryName } from "../../../helpers"
import { getOfferLists, getCampaignLists } from '../../../redux/actions/common'
import moment from 'moment'
import 'moment-timezone'

export const CampaignStructure = ({
  submitData,
  ad_accounts,
  // offers,
  landingPages,
  campaignData,
  submitting,
  errors
}) => {

  const [showEndData, setShowEndDate] = useState(false)
  const [isTestPhase, setIsTestPhase] = useState(false)

  const formik = useFormik({
    initialValues: {
      ad_account: {},
      // offers: {},
      landing_page: {},
      budget_type: "CBO",
      bid_strategy: {},
      budget: "",
      budget_spend_critaria: {},
      name: "",
      objective: {},
      special_ad_categories: {},
      spend_cap: "",
      
      // start_date: "",
      // start_time: ""
    },
    // validationSchema: createCampaign,
    onSubmit: submitData
  })

  useEffect(() => {
    if (campaignData) {
      if (campaignData.test_phase && campaignData.test_phase.phase_number && campaignData.test_phase.phase_number >= 2) {
        setIsTestPhase(true)
      }
      setTimeout(() => {
        formik.setValues({ ...campaignData })
        formik.setFieldValue("ad_account", { label: campaignData.ad_account?.name, value: campaignData.ad_account?.id })
        formik.setFieldValue("landing_page", { label: campaignData.landing_page?.name, value: campaignData.landing_page?.id })
        formik.setFieldValue("special_ad_categories", { label: campaignData.special_ad_categories[0], value: campaignData.special_ad_categories[0] })
        formik.setFieldValue("objective", { label: (campaignData.objective === 'OUTCOME_LEADS') ? 'Lead' : (campaignData.objective === 'OUTCOME_SALES') ? 'Purchase' : (campaignData.objective === 'OUTCOME_TRAFFIC') ? 'Traffic' : campaignData.objective, value: campaignData.objective })
        formik.setFieldValue("budget_spend_critaria", (campaignData.daily_budget) ? { label: 'DAILY', value: 'DAILY' } : (campaignData.lifetime_budget) ? { label: 'LIFETIME', value: 'LIFETIME' } : null)
        formik.setFieldValue("budget", (campaignData.daily_budget) ? campaignData.daily_budget/100 : (campaignData.lifetime_budget) ? campaignData.lifetime_budget/100 : null)
        formik.setFieldValue("bid_strategy", BID_STRATEGY_ENUM.filter((item) => item.value === campaignData.bid_strategy)[0])
      }, 500)
    } else {
      formik.setFieldValue("budget_spend_critaria", { label: 'DAILY', value: 'DAILY' })
      formik.setFieldValue("special_ad_categories", { value: "NONE", label: "None" })
      formik.setFieldValue("bid_strategy", { value: "LOWEST_COST_WITHOUT_CAP", label: "Lowest cost highest value" })
    }

  }, [campaignData])

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        formik.setFieldError("ad_account", errors.ad_account && errors.ad_account[0])
        formik.setFieldError("name", errors.name && errors.name[0])
        formik.setFieldError("objective", errors.objective && errors.objective[0])
        formik.setFieldError("landing_page", errors.landing_page && errors.landing_page[0])
        formik.setFieldError("special_ad_categories", errors.special_ad_categories && errors.special_ad_categories['0'][0])
      }, 500)
    }
  }, [errors])

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5 ">
          <p className="mb-2">Campaign Structure</p>
        </div>
        <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
          <p className="text-sm mb-1">Ad Accounts</p>
          <Dropdown
            placeholder="Select"
            options={ad_accounts}
            name="ad_account"
            onChange={value => formik.setFieldValue("ad_account", value)}
            onBlur={formik.handleBlur}
            value={formik.values.ad_account}
            error={formik.errors.ad_account}
            touched={formik.touched.ad_account}
            disabled={isTestPhase}
          />
          <p className="text-sm mb-1">Landing Pages
            {(campaignData) && <>
              {(isTestPhase && (campaignData && campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Landing Page')) &&
                <span className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</span>
              }
            </>}
          </p>
          <Dropdown
            placeholder="Select"
            options={landingPages}
            name="landing" 
            onChange={value => formik.setFieldValue("landing_page", value)}
            onBlur={formik.handleBlur}
            value={formik.values.landing_page}
            error={formik.errors.landing_page}
            touched={formik.touched.landing_page}
            disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Landing Page')}
          />
          {/* <p className="text-sm mb-1">Offers</p>
          <Dropdown
            placeholder="Select"
            options={offers}
            name="offers"
            onChange={value => formik.setFieldValue("offers", value)}
            onBlur={formik.handleBlur}
            value={formik.values.offers}
            error={formik.errors.offers}
            touched={formik.touched.offers}
          /> */}
        </div>
      </div>
      <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5 ">
          <p className="mb-2">Campaign</p>
        </div>
        <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
          <Input
            placeholder="Campaign Name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.errors.name}
            touched={formik.touched.name}
            disabled={isTestPhase}
          />
          <p className="font-bold my-2">Special Ad Categories</p>
          <Dropdown
            placeholder="Categories"
            options={SPECIAL_AD_CATEGORIES_ENUM}
            name="special_ad_categories"
            onChange={value =>
              formik.setFieldValue("special_ad_categories", value)
            }
            onBlur={formik.handleBlur}
            value={formik.values.special_ad_categories}
            error={formik.errors.special_ad_categories}
            touched={formik.touched.special_ad_categories}
            disabled={isTestPhase}
          />

          <p className="font-bold mb-1">Campaign Objective</p>
          <Dropdown
            placeholder="Campaign Objective"
            options={OBJECTIVE_ENUM}
            name="objective"
            onChange={value => formik.setFieldValue("objective", value)}
            onBlur={formik.handleBlur}
            value={formik.values.objective}
            error={formik.errors.objective}
            touched={formik.touched.objective}
            disabled={isTestPhase}
          />
          <p className="font-bold my-3">Budget and Scheduling</p>
          <p className="text-sm mb-2">Budget Type
            {(campaignData) && <>
              {(isTestPhase && (campaignData && campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Budget Type')) &&
                <span className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</span>
              }
            </>}
          </p>
          <div className="flex items-start">
            <Checkbox
              title="CBO"
              name="budget_type"
              onChange={() => formik.setFieldValue("budget_type", "CBO")}
              onBlur={formik.handleBlur}
              value={formik.values.budget_type}
              error={formik.errors.budget_type}
              touched={formik.touched.budget_type}
              checked={formik.values.budget_type === "CBO"}
              disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Budget Type')}
            />
            <div className="mx-2" />
            <Checkbox
              title="ABO"
              name="budget_type"
              onChange={() => formik.setFieldValue("budget_type", "ABO")}
              onBlur={formik.handleBlur}
              value={formik.values.budget_type}
              error={formik.errors.budget_type}
              touched={formik.touched.budget_type}
              checked={formik.values.budget_type === "ABO"}
              disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Budget Type')}
            />
          </div>
          {formik.errors.budget_type && formik.touched.budget_type && (
            <p className="text text-red-500">{formik.errors.budget_type}</p>
          )}
          {formik.values.budget_type !== "ABO" && (
            <>
              <p className="text-sm mt-4 mb-1">Campaign Budget</p>

              <div className="grid grid-cols-2 gap-3">
                <Dropdown
                  placeholder="Bid Strategy"
                  classRoot="mt-1"
                  options={BUDGET_TYPE_ENUM}
                  name="budget"
                  onChange={value =>
                    formik.setFieldValue("budget_spend_critaria", value)
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.budget_spend_critaria}
                  error={formik.errors.budget_spend_critaria}
                  touched={formik.touched.budget_spend_critaria}
                  disabled={isTestPhase}
                />

                <Input
                  placeholder="$ 24.00"
                  className="mt-0"
                  iconClasses={
                    "absolute inset-y-0 right-0 flex pr-3 cursor-pointer"
                  }
                  Icon={() => (
                    <div className="pointer-events-none absolute border-l pl-2 border-l-black inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        USD
                      </span>
                    </div>
                  )}
                  name="budget"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.budget}
                  error={formik.errors.budget}
                  touched={formik.touched.budget}
                  disabled={isTestPhase}
                />
              </div>

              <p className="text-sm mt-3 mb-1">Campaign Bid Strategies</p>
              <div className="grid grid-cols-2 gap-3">
                <Dropdown
                  placeholder="Bid Strategy"
                  classRoot="mt-1"
                  options={BID_STRATEGY_ENUM}
                  name="bid_strategy"
                  onChange={value =>
                    formik.setFieldValue("bid_strategy", value)
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.bid_strategy}
                  error={formik.errors.bid_strategy}
                  touched={formik.touched.bid_strategy}
                  disabled={isTestPhase}
                />
                {/* {(formik.values.bid_strategy?.value === "COST_CAP" ||
                  formik.values.bid_strategy?.value ===
                  "LOWEST_COST_WITH_BID_CAP") && (
                    <Input
                      placeholder="Bid Amount"
                      className="mt-0"
                      name="spend_cap"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.spend_cap}
                      error={formik.errors.spend_cap}
                      touched={formik.touched.spend_cap}
                    />
                  )} */}
              </div>

            </>
          )}
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
          >
            {(submitting) ? <Spinner className="w-5 h-5" /> : 'Next'}
          </Button>
        </div>
      </div>
    </form>
  )
}

export const AudienceTargeting = ({ submitData, campaignData, submitting, savedAudiences }) => {
  const navigate = useNavigate()
  const { campaignId } = useParams()
  const [isInclude, setIsInclude] = useState(true)
  const [customAudienceIncluded, setCustomAudienceIncluded] = useState(true)
  const [customAudience, setCustomAudience] = useState([])
  const [budgetType, setBudgetType] = useState(undefined)
  const [openInsertToken, setOpenInsertToken] = useState(false)
  const [currentElementId, setCurrentElementId] = useState(null)
  const [isTestPhase, setIsTestPhase] = useState(false)

  const setTabsList = (activeId, index) => {
    const tabsList = [...formik.values.audienceConfig[index]?.tabsList]
    let tempTabs = tabsList.map(item => ({
      ...item,
      isActive: item.value === activeId
    }))

    const audienceConfig = [...formik.values.audienceConfig]
    audienceConfig[index].tabsList = tempTabs
    formik.setFieldValue(`audienceConfig`, audienceConfig)
  }

  useEffect(() => {
    if (campaignId) {
      getPageData(campaignId)
    } else {
      navigate("/dashboard/campagins")
    }

    moment.tz.setDefault("America/New_York")
  }, [])

  const getPageData = async (id = campaignId) => {
    try {
      const { data } = await getCustomAudience(id)
      const filteredData = data.map(({ id, name }) => ({
        label: name,
        value: id
      }))
      setCustomAudience(filteredData)
    } catch (e) { }
  }

  const formik = useFormik({
    initialValues: {
      // NEW
      adPlacementConfiguration: [
        {
          adPlacement: false,
          devices: {},
          publisher_platforms: [
            "facebook",
            "instagram",
            "messenger",
            "audience_network"
          ],
          facebook_positions: [
            "feed",
            "right_hand_column",
            "marketplace",
            "video_feeds",
            "story",
            "search",
            "instream_video",
            "facebook_reels"
          ],
          instagram_positions: [
            "stream",
            "story",
            "explore",
            "explore_home",
            "reels",
            "profile_feed",
            "ig_search"
          ],
          audience_network_positions: ["classic", "rewarded_video"],
          messenger_positions: ["messenger_home", "sponsored_messages", "story"]
        }
      ],
      audienceConfig: [
        {
          tabsList: [
            { title: "Create new audience", value: 1, isActive: true },
            { title: "Use saved audience", value: 2, isActive: false }
          ],
          locations: [],
          targetingDetails: [],
          age_min: "",
          age_max: "",
          gender: "all",
          customAudience: []
        }
      ],

      optimizationAndDeliveryConfig: [
        { 
          adOptimization: {}, 
          settings: { value: '1d_click', label: '1d Click' }, 
          deliveryType: { value: 'standard', label: 'Standard' }, 
          budgetType: (campaignData && campaignData.budget_type === "ABO") ? { label: 'DAILY', value: 'DAILY' } : null, 
          bidStrategy: (campaignData && campaignData.budget_type === "ABO") ? { value: "LOWEST_COST_WITHOUT_CAP", label: "Lowest cost highest value" } : null, 
          bidAmount: "", budgetPrice: "",
          start_date: "", start_time: "", showEndDate: false, end_date: "", end_time: ""
        }
      ],
      adSetNaming: "",
      locale: [{label: "English (US)", value: "6"}],
    },
    // validationSchema: audienceTargetingValidationSchema,
    onSubmit: submitData
  })

  const handleBidStrategy = (value) => {
    const res = BID_STRATEGY_ENUM.filter((item) => item.value === value)
    return (res && res.length > 0) ? res[0] : { label: '', value: '' }
  }

  const formatTargetDetail = (x) => {
    var output = []
    for (let i in x) {
      for (let key in x[i]) {
        if (Array.isArray(x[i][key]) && x[i][key].length > 0) {
          output.push({ type: key, options: x[i][key].map((items) => { return { ...items, type: key, include: true }}) })
        }
      }
    }
    return output
  }

  const getCombinedLocations = (items) => {

    const combinedObj = {
      cities: [...items.geo_locations.cities.map((x) => { return {...x, include: true}}), ...items.excluded_geo_locations.cities.map((x) => { return {...x, include: false}})],
      regions: [...items.geo_locations.regions.map((x) => { return {...x, include: true}}), ...items.excluded_geo_locations.regions.map((x) => { return {...x, include: false}})],
      countries: [...items.geo_locations.countries, ...items.excluded_geo_locations.countries],
      location_types:	[ "recent", "home" ]
    }

    const loc = Object.values(Object.values(combinedObj).reduce((a, b) => a.concat(b), []).reduce((a, item) => {
      if (item.country_name)
        a[item.country_name] ??= { country_name: item.country_name, options: [] };
      else if (item && item !== null && item !== 'recent' && item !== 'home') 
        a[getCountryName(item)] ??= { country_name: getCountryName(item), options: [] };
      if (item.country_name) 
        a[item.country_name].options.push({...item, include: (typeof item?.include === 'undefined') ? true : item?.include });
      else if (item && item !== null && item !== 'recent' && item !== 'home')
        a[getCountryName(item)].options.push({name: getCountryName(item), key: item, type: "country", country_code: item, country_name: getCountryName(item), include: (typeof item?.include === 'undefined') ? true : item?.include });
      return a;
    }, {}))

    return loc
  }

  useEffect(() => {
    if (campaignData) {

      if (campaignData.test_phase && campaignData.test_phase.phase_number && campaignData.test_phase.phase_number >= 2) {
        setIsTestPhase(true)
      }

      setTimeout(() => {
        // formik.setFieldValue("adPlacementConfiguration", campaignData.placement_configs)
        formik.setFieldValue("adSetNaming", campaignData.ad_set_naming)

        formik.setFieldValue("locale", campaignData.locale != null ? campaignData.locale : [])

        // Audience Configuration
        const audiencedata = campaignData.meta_ad_set_audience_configs.map((items) => {
          return {
            tabsList: [
              { title: "Create new audience", value: 1, isActive: true },
              { title: "Use saved audience", value: 2, isActive: false }
            ],
            locations: getCombinedLocations(items),
            targetingDetails: formatTargetDetail(items.flexible_spec) || [],
            age_min: { value: items.age_min, label: items.age_min  },
            age_max: { value: items.age_max, label: items.age_max  },
            gender: "all",
            customAudience: [{ type: undefined, options: items.ad_set_audience_custom_audiences.map((obj) => { return { label: obj.name, value: obj.id, include: !obj.is_exclude } }) }] || []
          }
        })
        formik.setFieldValue("audienceConfig", audiencedata)

        // Optimization & Delivery Configuration
        const optdata = campaignData.optimization_delivery_configs.map((items) => {
          return {
            adOptimization: { value: items.optimization_goal, label: items.optimization_goal.toLowerCase().split('_').map((str) => { return str[0].toUpperCase() + str.substring(1) }).join(" ") },
            settings: { value: items.attribution_spec?items.attribution_spec:'1d_click', label: items.attribution_spec?items.attribution_spec.split('_').map((str) => { return str[0].toUpperCase() + str.substring(1) }).join(" "):'1d Click' },
            deliveryType: { value: items.pacing_type, label: items.pacing_type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())},
            bidStrategy: handleBidStrategy(items.bid_strategy),
            budgetType: (items.daily_budget) ? { label: 'DAILY', value: 'DAILY' } : (items.lifetime_budget) ? { label: 'LIFETIME', value: 'LIFETIME' } : null,
            budgetPrice: (items.daily_budget) ? items.daily_budget : (items.lifetime_budget) ? items.lifetime_budget  : null,
            bidAmount: (campaignData.bid_strategy === 'COST_CAP' || campaignData.bid_strategy === 'LOWEST_COST_WITH_BID_CAP' || items.bid_strategy === 'COST_CAP' || items.bid_strategy === 'LOWEST_COST_WITH_BID_CAP') ? items.bid_amount : null,
            start_date: items.start_time ? moment(items.start_time).format('YYYY-MM-DD') : "", 
            start_time: items.start_time ? moment(items.start_time).format('HH:mm') : "",
            showEndDate: (items.stop_time) ? true : false, 
            end_date: items.stop_time ? moment(items.stop_time).format('YYYY-MM-DD') : "", 
            end_time: items.stop_time ? moment(items.stop_time).format('HH:mm') : ""
          }
        })
        console.log(optdata);
        console.log('optdata');
        formik.setFieldValue("optimizationAndDeliveryConfig", optdata)
        setBudgetType(campaignData.budget_type)

      }, 800)
    }
  }, [campaignData])

  const handlePublisherPlatforms = ({ target: { name } }) => {
    const currentName = name.split(".")[1]
    const currentIndex = name.split(".")[0]
    if (
      formik.values.adPlacementConfiguration[
        currentIndex
      ].publisher_platforms.includes(currentName)
    ) {
      const itemIndex =
        formik.values.adPlacementConfiguration[
          currentIndex
        ].publisher_platforms.indexOf(currentName)
      const tempValues = [
        ...formik.values.adPlacementConfiguration[currentIndex]
          .publisher_platforms
      ]

      const newArray = [
        ...tempValues.slice(0, itemIndex),
        ...tempValues.slice(itemIndex + 1)
      ]
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].publisher_platforms`,
        newArray
      )
    } else
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].publisher_platforms`,
        [
          ...formik.values.adPlacementConfiguration[currentIndex]
            .publisher_platforms,
          currentName
        ]
      )
  }
  const handleFacebookPositions = ({ target: { name } }) => {
    const currentName = name.split(".")[1]
    const currentIndex = name.split(".")[0]
    if (
      formik.values.adPlacementConfiguration[
        currentIndex
      ].facebook_positions.includes(currentName)
    ) {
      const itemIndex =
        formik.values.adPlacementConfiguration[
          currentIndex
        ].facebook_positions.indexOf(currentName)
      const tempValues = [
        ...formik.values.adPlacementConfiguration[currentIndex]
          .facebook_positions
      ]

      const newArray = [
        ...tempValues.slice(0, itemIndex),
        ...tempValues.slice(itemIndex + 1)
      ]
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].facebook_positions`,
        newArray
      )
    } else
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].facebook_positions`,
        [
          ...formik.values.adPlacementConfiguration[currentIndex]
            .facebook_positions,
          currentName
        ]
      )
  }
  const handleInstagramPositions = ({ target: { name } }) => {
    const currentName = name.split(".")[1]
    const currentIndex = name.split(".")[0]
    if (
      formik.values.adPlacementConfiguration[
        currentIndex
      ].instagram_positions.includes(currentName)
    ) {
      const itemIndex =
        formik.values.adPlacementConfiguration[
          currentIndex
        ].instagram_positions.indexOf(currentName)
      const tempValues = [
        ...formik.values.adPlacementConfiguration[currentIndex]
          .instagram_positions
      ]

      const newArray = [
        ...tempValues.slice(0, itemIndex),
        ...tempValues.slice(itemIndex + 1)
      ]
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].instagram_positions`,
        newArray
      )
    } else
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].instagram_positions`,
        [
          ...formik.values.adPlacementConfiguration[currentIndex]
            .instagram_positions,
          currentName
        ]
      )
  }
  const handleAudienceNetworkPosition = ({ target: { name } }) => {
    const currentName = name.split(".")[1]
    const currentIndex = name.split(".")[0]
    if (
      formik.values.adPlacementConfiguration[
        currentIndex
      ].audience_network_positions.includes(currentName)
    ) {
      const itemIndex =
        formik.values.adPlacementConfiguration[
          currentIndex
        ].audience_network_positions.indexOf(currentName)
      const tempValues = [
        ...formik.values.adPlacementConfiguration[currentIndex]
          .audience_network_positions
      ]

      const newArray = [
        ...tempValues.slice(0, itemIndex),
        ...tempValues.slice(itemIndex + 1)
      ]
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].audience_network_positions`,
        newArray
      )
    } else
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].audience_network_positions`,
        [
          ...formik.values.adPlacementConfiguration[currentIndex]
            .audience_network_positions,
          currentName
        ]
      )
  }
  const handleMessengerPositions = ({ target: { name } }) => {
    const currentName = name.split(".")[1]
    const currentIndex = name.split(".")[0]
    if (
      formik.values.adPlacementConfiguration[
        currentIndex
      ].messenger_positions.includes(currentName)
    ) {
      const itemIndex =
        formik.values.adPlacementConfiguration[
          currentIndex
        ].messenger_positions.indexOf(currentName)
      const tempValues = [
        ...formik.values.adPlacementConfiguration[currentIndex]
          .messenger_positions
      ]

      const newArray = [
        ...tempValues.slice(0, itemIndex),
        ...tempValues.slice(itemIndex + 1)
      ]
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].messenger_positions`,
        newArray
      )
    } else
      formik.setFieldValue(
        `adPlacementConfiguration[${currentIndex}].messenger_positions`,
        [
          ...formik.values.adPlacementConfiguration[currentIndex]
            .messenger_positions,
          currentName
        ]
      )
  }

  const getSuggestions = async (searchText, callback) => {
    try {
      const { data } = await facebookSearchLocation(campaignId, searchText)

      const inputData = data.map(({ name, country_name, ...rest }) => ({
        label: `${name}, ${country_name}`,
        value: `${name}, ${country_name}`,
        country_name,
        name,
        ...rest
      }))
      callback(inputData)
    } catch (e) { }
  }

  const getLocaleSuggestions = async (searchText='english', callback) => {
    try {
      let search = 'English'
      if (searchText.length != 0){
        search = searchText
      }
      const { data } = await facebookSearchLocale(campaignId, search)
      const inputData = data.map(({ name, key }) => ({
        label: `${name}`,
        value: `${key}`,
      }))
      callback(inputData)
    } catch (e) { }
  }
  const getDemographicsSuggestions = async (searchText, callback) => {
    try {
      const { data } = await facebookSearchDemographics(campaignId, searchText)
      const inputData = data.map(({ name, type, ...rest }) => ({
        label: `${name} | ${type.replace("_", " ")}`,
        value: `${name} | ${type[0]}`,
        type,
        name,
        ...rest
      }))
      callback(inputData)
    } catch (e) { }
  }

  const handleLocationSelect = (
    { label, value, country_name, ...location },
    locationIndex
  ) => {
    const selectedOptions = [
      ...formik.values.audienceConfig[locationIndex].locations
    ]
    const index = findIndex(selectedOptions, { country_name })
    if (index > -1) {
      let tempObj = [...selectedOptions]
      tempObj[index].options = [
        ...tempObj[index]?.options,
        { ...location, country_name, include: isInclude }
      ]
      let audienceConfig = [...formik.values.audienceConfig]
      audienceConfig[locationIndex].locations = tempObj
      formik.setFieldValue("audienceConfig", audienceConfig)
      console.log(audienceConfig);
    } else {
      let audienceConfig = [...formik.values.audienceConfig]
      audienceConfig[locationIndex].locations = [
        ...selectedOptions,
        {
          country_name,
          options: [{ ...location, country_name, include: isInclude }]
        }
      ]
      console.log(audienceConfig);
      formik.setFieldValue("audienceConfig", audienceConfig)
    }
  }
  const handleSelectDemographic = (
    { label, value, type, ...rest },
    currentIndex
  ) => {
    const selectedOptions = [
      ...formik.values.audienceConfig[currentIndex].targetingDetails
    ]
    const index = findIndex(selectedOptions, { type })
    if (index > -1) {
      let tempObj = [...selectedOptions]
      tempObj[index].options = [...tempObj[index]?.options, { type, ...rest, include: isInclude }]

      let audienceConfig = [...formik.values.audienceConfig]

      audienceConfig[currentIndex].targetingDetails = tempObj
      
      formik.setFieldValue("audienceConfig", audienceConfig)
    } else {
      let audienceConfig = [...formik.values.audienceConfig]
      audienceConfig[currentIndex].targetingDetails = [
        ...selectedOptions,
        { type, options: [{ ...rest, type, include: isInclude }] }
      ]
      
      formik.setFieldValue("audienceConfig", audienceConfig)
    }
  }

  const handleSelectCustomAudience = (
    { label, value, type },
    currentIndex
  ) => {
   
    const selectedOptions = [
      ...formik.values.audienceConfig[currentIndex]?.customAudience
    ]
    const index = findIndex(selectedOptions, { type })
    if (index > -1) {
      let tempObj = [...selectedOptions]
      tempObj[index].options = [...tempObj[index]?.options, { type, label, value, include: true }]

      let audienceConfig = [...formik.values.audienceConfig]

      audienceConfig[currentIndex].customAudience = tempObj

      formik.setFieldValue("audienceConfig", audienceConfig)
    } else {
      let audienceConfig = [...formik.values.audienceConfig]
   
      audienceConfig[currentIndex].customAudience = [
        ...selectedOptions,
        { type, options: [{ label, value, type, include: true }] }
      ]
      formik.setFieldValue("audienceConfig", audienceConfig)
    }
  }

  const handleLocationRemove = (index, countryIndex, option) => {
    let selectedOptions = [
      ...formik.values.audienceConfig[index].locations[countryIndex].options
    ]
    let selectedIndex = findIndex(selectedOptions, option)

    selectedOptions.splice(selectedIndex, 1)

    if (selectedOptions.length === 0) {
      let locations = [...formik.values.audienceConfig[index].locations]

      locations.splice(countryIndex, 1)

      formik.setFieldValue(`audienceConfig[${index}].locations`, locations)
    } else {
      formik.setFieldValue(
        `audienceConfig[${index}].locations[${countryIndex}].options`,
        selectedOptions
      )
    }
  }

  const handleInterestRemove = (index, mainIndex, option) => {
    let selectedOptions = [
      ...formik.values.audienceConfig[index].targetingDetails[mainIndex].options
    ]
    let selectedIndex = findIndex(selectedOptions, option)

    selectedOptions.splice(selectedIndex, 1)

    if (selectedOptions.length === 0) {
      let targetingDetails = [
        ...formik.values.audienceConfig[index].targetingDetails
      ]

      targetingDetails.splice(mainIndex, 1)

      formik.setFieldValue(
        `audienceConfig[${index}].targetingDetails`,
        targetingDetails
      )
    } else {
      formik.setFieldValue(
        `audienceConfig[${index}].targetingDetails[${mainIndex}].options`,
        selectedOptions
      )
    }
  }

  const handleCustomAudienceRemove = (index, mainIndex, option) => {
    let selectedOptions = [
      ...formik.values.audienceConfig[index].customAudience[mainIndex].options
    ]
    let selectedIndex = findIndex(selectedOptions, option)

    selectedOptions.splice(selectedIndex, 1)

    if (selectedOptions.length === 0) {
      let customAudience = [
        ...formik.values.audienceConfig[index].customAudience
      ]

      customAudience.splice(mainIndex, 1)

      formik.setFieldValue(
        `audienceConfig[${index}].customAudience`,
        customAudience
      )
    } else {
      formik.setFieldValue(
        `audienceConfig[${index}].customAudience[${mainIndex}].options`,
        selectedOptions
      )
    }
  }

  const handleGenderSelect = ({ target: { name } }) => {
    const currentIndex = name.split(".")[0]
    const currentName = name.split(".")[1]
    if (formik.values.audienceConfig[currentIndex].gender === currentName) {
      formik.setFieldValue(`audienceConfig[${currentIndex}].gender`, "")
    } else
      formik.setFieldValue(
        `audienceConfig[${currentIndex}].gender`,
        currentName
      )
  }

  // Apply Audience Configuration
  const applySavedAudience = (index, items) => {
    
    const audiencedata = [...formik.values.audienceConfig]
    if (items) {
      audiencedata[index]['tabsList'] = [
        { title: "Create new audience", value: 1, isActive: true },
        { title: "Use saved audience", value: 2, isActive: false }
      ]
      audiencedata[index]['locations'] = Object.values(Object.values(items.geo_locations).reduce((a, b) => a.concat(b), []).reduce((a, item) => {
          if (item.country_name)
            a[item.country_name] ??= { country_name: item.country_name, options: [] };
          else if (item && item !== null && item !== 'recent' && item !== 'home') 
            a[getCountryName(item?.country)] ??= { country_name: getCountryName(item?.country), options: [] };
          if (item.country_name) 
            a[item.country_name].options.push({...item, include: true});
          else if (item && item !== null && item !== 'recent' && item !== 'home')
            a[getCountryName(item?.country)].options.push({name: item?.name, key: item?.key, type: 'neighborhood', country_code: item?.country, country_name: getCountryName(item?.country), include: true});
          return a;
        }, {})
      )
      audiencedata[index]['targetingDetails'] = formatTargetDetail(items.flexible_spec) || []
      audiencedata[index]['age_min'] = { value: items.age_min, label: items.age_min  }
      audiencedata[index]['age_max'] = { value: items.age_max, label: items.age_max  }
      audiencedata[index]['gender'] = "all"
      audiencedata[index]['customAudience'] = [{ type: "", options: items.ad_set_audience_custom_audiences.map((obj) => { return { label: obj.name, value: obj.id, include: obj.is_exclude } }) }] || []
    }
    console.log(audiencedata);
    formik.setFieldValue("audienceConfig", audiencedata)
  }

  const handleLocationExclude = (e, i, k) => {
    const audiencedata = [...formik.values.audienceConfig]
    const locations = audiencedata[i]['locations'].map((items) => {
        let options = items.options.map((item, key) => {
          if (key === k) {
            return {...item, include: (e.value === 'include') ? true : false }
          } 
          return item
        })
        return { ...items, options}
    })
    
    formik.setFieldValue(
      `audienceConfig[${i}].locations`, locations
    )
  }

  const handleCustomAudienceExclude = (e, i, k) => {
    const audiencedata = [...formik.values.audienceConfig]
    const customAudience = audiencedata[i]['customAudience'].map((items) => {
        let options = items.options.map((item, key) => {
          if (key === k) {
            return {...item, include: (e.value === 'include') ? true : false }
          } 
          return item
        })
        return { ...items, options}
    })
    
    formik.setFieldValue(
      `audienceConfig[${i}].customAudience`, customAudience
    )
  }

  const handleNamingChange = (e) => {
    
    if (e && currentElementId) {
      const input = document.getElementById(currentElementId)
      
      if (input) {
        const val = formik.values[currentElementId]
        const cursorPosition = input.selectionStart
        const textBefore = val.substring(0, cursorPosition)
        const textAfter = val.substring(cursorPosition, val.length)
       
        formik.setFieldValue(currentElementId, `${textBefore} ${e.value} ${textAfter}`)
      }
    }
  }

  const handleDuplicate = (index) => {
    formik.setFieldValue("audienceConfig", [
      ...formik.values.audienceConfig,
      formik.values.audienceConfig[index]
    ])
  }

  return (<>
    <AddTokenModal
      open={openInsertToken}
      setOpen={setOpenInsertToken}
      handleChange={handleNamingChange}
      currentElement={currentElementId}
    />
    
    <form onSubmit={formik.handleSubmit} className="rounded-md">
      {/* <div className="border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5 ">
          <p className="mb-2 font-bold">Conversions</p>
        </div>
        <div className="mx-5">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <Checkbox
              title="Website"
              name="website"
              onChange={({ target: { name } }) =>
                formik.setFieldValue("leads_place", name)
              }
              onBlur={formik.handleBlur}
              value={formik.values.leads_place}
              error={formik.errors.leads_place}
              touched={formik.touched.leads_place}
            />
            <Checkbox
              title="Instant Form Ads"
              name="instant_forms_ads"
              onChange={({ target: { name } }) =>
                formik.setFieldValue("leads_place", name)
              }
              onBlur={formik.handleBlur}
              value={formik.values.leads_place}
              error={formik.errors.leads_place}
              touched={formik.touched.leads_place}
            />
          </div>
          {formik.errors.leads_place && formik.touched.leads_place && (
            <p className="text text-red-500">{formik.errors.leads_place}</p>
          )}
          <p className="text-sm mt-3 mb-1">Pixel</p>
          <Dropdown
            placeholder="Pixels from facebook"
            classRoot="mt-1"
            options={facebookPixels}
            name="performance_goals"
            onChange={value => formik.setFieldValue("performance_goals", value)}
            onBlur={formik.handleBlur}
            value={formik.values.performance_goals}
            error={formik.errors.performance_goals}
            touched={formik.touched.performance_goals}
          />
          <p className="text-sm mt-3 mb-1">Instant Form Ads</p>
          <Dropdown
            placeholder="Select"
            classRoot="mt-1"
            options={[
              {
                label: "1",
                value: "1"
              },
              {
                label: "2",
                value: "2"
              }
            ]}
            name="facebook"
            onChange={value => formik.setFieldValue("facebook", value)}
            onBlur={formik.handleBlur}
            value={formik.values.facebook}
            error={formik.errors.facebook}
            touched={formik.touched.facebook}
          />
        </div>
      </div> */}
      <div className="border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5">
          <p className="mb-2 font-bold">Ad Set Name</p>
        </div>
        <div className="w-full grid grid-cols-2 gap-4 px-5">
          <Input
            placeholder="Enter Ad Set Name"
            id="adSetNaming"
            name="adSetNaming"
            onChange={formik.handleChange}
            onFocus={(e) => setCurrentElementId("adSetNaming")}
            onBlur={formik.handleBlur}
            value={formik.values.adSetNaming}
            error={formik.errors.adSetNaming}
            touched={formik.touched.adSetNaming}
          />
        </div>
        <div className="flex flex-wrap w-100 mt-3 px-5">
          <p className="font-semibold text-sm text-[#B6CC43] cursor-pointer underline" onClick={() => setOpenInsertToken(true)}>Add Tokens</p>
        </div>
      </div>
      <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5 ">
          <p className="mb-2 font-bold">Audience Targeting</p>
        </div>
        {formik.values.audienceConfig.map(({ }, index) => (
          <div className="mt-10 border border-gray-300 rounded-md bg-white mx-5 py-5">
            <div className="px-5 relative flex justify-between">
              <p className="mb-2 font-bold">
                Audience Configuration {index + 1}
              </p>

              <div className="flex items-center">
                <button
                  type="button"
                  className={`px-7 py-2 bg-white border border-1 border-gray-600 font-semibold text-gray-400 hover:text-gray-500 rounded-md mr-3`}
                  disabled={isTestPhase}
                  onClick={() => handleDuplicate(index)}
                >
                  Duplicate
                </button>
                <button
                  type="button"
                  className="rounded-md bg-white border border-1 border-gray-600 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    const temp = [...formik.values.audienceConfig]
                    temp.splice(index, 1)
                    formik.setFieldValue("audienceConfig", temp)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <FaTimes className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <Tabs
              tabs={formik.values.audienceConfig[index]?.tabsList}
              handleChange={activeId => setTabsList(activeId, index)}
            />
            {formik.values.audienceConfig[index]?.tabsList[0].isActive && (
              <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
                {formik.values.audienceConfig[index]?.customAudience.map(
                  (countryData, mainIndex) => (
                    <div className="px-3 py-1 bg-[#F4F4F4] rounded-md grid grid-cols-2 gap-4">
                      {countryData && countryData.options && countryData.options.length > 0 && countryData.options.map((option, key) => (
                        <Input
                          className="mt-0 pl-7"
                          type="text"
                          value={`${option.label}`}
                          iconClasses={
                            "absolute inset-y-0 flex pr-3 cursor-pointer"
                          }
                          showDropDown={true}
                          handleDropDown={(e) => handleCustomAudienceExclude(e, index, key)}
                          onRemove={() =>
                            handleCustomAudienceRemove(index, mainIndex, option)
                          }
                          Icon={() =>
                            option.include ? (
                              <div className="pointer-events-none pl-2 inset-y-0 flex items-center pr-3">
                                <span
                                  className="text-gray-500 sm:text-sm"
                                  id="price-currency"
                                >
                                  <IconContext.Provider
                                    value={{ color: "#B6CC43" }}
                                  >
                                    <div>
                                      <FaMapMarkerAlt />
                                    </div>
                                  </IconContext.Provider>
                                </span>
                              </div>
                            ) : (
                              <div className="pointer-events-none pl-2 inset-y-0 flex items-center pr-3">
                                <span
                                  className="text-gray-500 sm:text-sm"
                                  id="price-currency"
                                >
                                  <IconContext.Provider
                                    value={{ color: "#FF7373" }}
                                  >
                                    <div>
                                      <FaMapMarkerAlt />
                                    </div>
                                  </IconContext.Provider>
                                </span>
                              </div>
                            )
                          }
                        />
                      ))}
                    </div>
                  )
                )}
                                
                <p className="text-sm mt-3 mb-1">Custom Audience
                  {(campaignData) && <>
                    {(isTestPhase && (campaignData && campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Audience')) &&
                      <span className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</span>
                    }
                  </>}
                </p>
                <Dropdown
                  onChange={props => handleSelectCustomAudience(props, index)}
                  placeholder="Search Existing Audience"
                  classRoot="mt-1"
                  options={customAudience}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.audienceConfig &&
                    formik.errors.audienceConfig[index]?.customAudience
                  }
                  touched={
                    formik.touched.audienceConfig &&
                    formik.touched.audienceConfig[index]?.customAudience
                  }
                  disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Audience')}
                />
                {/* <Button
                  onClick={() =>
                    setCustomAudienceIncluded(!customAudienceIncluded)
                  }
                  className="px-7 py-2 bg-[#B6CC43] rounded-xl mr-3 mb-2"
                >
                  {customAudienceIncluded ? "Included" : "Excluded"}
                </Button> */}
                
                <p className="text-sm mt-3 mb-1">Location *
                  {(campaignData) && <>
                    {(isTestPhase && (campaignData && campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Location')) &&
                      <span className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</span>
                    }
                  </>}
                </p>
                {formik.values.audienceConfig[index]?.locations.map(
                  (countryData, countryIndex) => (
                    <div className="px-3 py-1 bg-[#F4F4F4] rounded-md">
                      <p className="text-sm mt-3 mb-1">
                        {countryData.country_name}
                      </p>
                      <div className="grid grid-cols-4 gap-4">
                        {countryData && countryData.options && countryData.options.length > 0 && countryData.options.map((option, key) => (
                          <div className="ml-2">
                            <Input
                              className="mt-0 pl-7"
                              type="text"
                              value={`${option.name}, ${countryData.country_name}`}
                              iconClasses={
                                "absolute inset-y-0 flex pr-3 cursor-pointer"
                              }
                              showDropDown={true}
                              handleDropDown={(e) => handleLocationExclude(e, index, key)}
                              onRemove={() =>
                                handleLocationRemove(index, countryIndex, option)
                              }
                              disabled
                              Icon={() =>
                                option.include ? (
                                  <div className="pointer-events-none pl-2 inset-y-0 flex items-center pr-3">
                                    <span
                                      className="text-gray-500 sm:text-sm"
                                      id="price-currency"
                                    >
                                      <IconContext.Provider
                                        value={{ color: "#B6CC43" }}
                                      >
                                        <div>
                                          <FaMapMarkerAlt />
                                        </div>
                                      </IconContext.Provider>
                                    </span>
                                  </div>
                                ) : (
                                  <div className="pointer-events-none pl-2 inset-y-0 flex items-center pr-3">
                                    <span
                                      className="text-gray-500 sm:text-sm"
                                      id="price-currency"
                                    >
                                      <IconContext.Provider
                                        value={{ color: "#FF7373" }}
                                      >
                                        <div>
                                          <FaMapMarkerAlt />
                                        </div>
                                      </IconContext.Provider>
                                    </span>
                                  </div>
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
                <div className="flex mt-2">
                  <div className="w-30 flex items-center">
                    <IconContext.Provider
                      value={{ color: isInclude ? "#B6CC43" : "#FF7373" }}
                    >
                      <div>
                        <FaMapMarkerAlt />
                      </div>
                    </IconContext.Provider>
                    <span className="pl-2">
                      {isInclude ? "include" : "exclude"}
                    </span>
                    <Select
                      className="flex"
                      options={[
                        { value: "include", label: "include" },
                        { value: "exclude", label: "exclude" }
                      ]}
                      islocationField={true}
                      setSelected={item =>
                        item.label === "include"
                          ? setIsInclude(true)
                          : setIsInclude(false)
                      }
                      disabled={isTestPhase}
                    />
                  </div>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={getSuggestions}
                    onChange={props => handleLocationSelect(props, index)}
                    placeholder="Search Locations"
                    className={"w-full border-l-0"}
                    isDisabled={isTestPhase && (campaignData && campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Location')}
                  />
                </div>
                {/* <GoogleMap /> */}

                {(campaignData && campaignData.special_ad_categories && campaignData.special_ad_categories[0] && campaignData.special_ad_categories[0] == 'NONE') && <>
                  <p className="text-sm mt-3 mb-1">Age
                    {(campaignData) && <>
                      {(isTestPhase && (campaignData && campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Age')) &&
                        <span className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</span>
                      }
                    </>}
                  </p>
                  <div className="flex">
                    <Dropdown
                      placeholder="18"
                      classRoot="mt-1 mr-2 w-1/6"
                      options={Array.from({ length: 46 }, (_, i) => ({
                        value: i + 18,
                        label: i + 18
                      }))}
                      name="age_from"
                      onChange={value =>
                        formik.setFieldValue(
                          `audienceConfig[${index}].age_min`,
                          value
                        )
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.audienceConfig[index]?.age_min}
                      error={
                        formik.errors.audienceConfig &&
                        formik.errors.audienceConfig[index]?.age_min
                      }
                      touched={
                        formik.touched.audienceConfig &&
                        formik.touched.audienceConfig[index]?.age_min
                      }
                    />
                    <p className="text-sm mt-3 mb-1 mr-2">to</p>
                    <Dropdown
                      placeholder="65"
                      classRoot="mt-1 w-1/6"
                      options={
                        formik.values.audienceConfig[index]?.age_min
                          ? Array.from(
                            {
                              length:
                                66 -
                                formik.values.audienceConfig[index]?.age_min
                                  .value
                            },
                            (_, i) => ({
                              value:
                                i +
                                formik.values.audienceConfig[index]?.age_min
                                  .value,
                              label:
                                i +
                                  formik.values.audienceConfig[index]?.age_min
                                    .value ===
                                  65
                                  ? "65+"
                                  : i +
                                  formik.values.audienceConfig[index]?.age_min
                                    .value
                            })
                          )
                          : []
                      }
                      name="age_to"
                      onChange={value =>
                        formik.setFieldValue(
                          `audienceConfig[${index}].age_max`,
                          value
                        )
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.audienceConfig[index]?.age_max}
                      error={
                        formik.errors.audienceConfig &&
                        formik.errors.audienceConfig[index]?.age_max
                      }
                      touched={
                        formik.touched.audienceConfig &&
                        formik.touched.audienceConfig[index]?.age_max
                      }
                    />
                  </div>
                  <p className="text-sm mt-3 mb-1">Gender</p>
                  <div className="flex items-start mb-1">
                    <Checkbox
                      title="All"
                      name={`${index}.all`}
                      onChange={handleGenderSelect}
                      onBlur={formik.handleBlur}
                      value={formik.values.gender}
                      error={
                        formik.errors.audienceConfig &&
                        formik.errors.audienceConfig[index]?.gender
                      }
                      touched={
                        formik.touched.audienceConfig &&
                        formik.touched.audienceConfig[index]?.gender
                      }
                      checked={
                        formik.values.audienceConfig[index].gender === "all"
                      }
                      disabled={isTestPhase}
                    />
                    <div className="mx-4" />
                    <Checkbox
                      title="Men"
                      name={`${index}.men`}
                      onChange={handleGenderSelect}
                      onBlur={formik.handleBlur}
                      value={formik.values.gender}
                      error={
                        formik.errors.audienceConfig &&
                        formik.errors.audienceConfig[index]?.gender
                      }
                      touched={
                        formik.touched.audienceConfig &&
                        formik.touched.audienceConfig[index]?.gender
                      }
                      checked={
                        formik.values.audienceConfig[index].gender === "men"
                      }
                      disabled={isTestPhase}
                    />
                    <div className="mx-4" />
                    <Checkbox
                      title="Women"
                      name={`${index}.women`}
                      onChange={handleGenderSelect}
                      onBlur={formik.handleBlur}
                      value={formik.values.gender}
                      error={
                        formik.errors.audienceConfig &&
                        formik.errors.audienceConfig[index]?.gender
                      }
                      touched={
                        formik.touched.audienceConfig &&
                        formik.touched.audienceConfig[index]?.gender
                      }
                      checked={
                        formik.values.audienceConfig[index].gender === "women"
                      }
                      disabled={isTestPhase}
                    />
                  </div>
                  {formik.errors.gender && formik.touched.gender && (
                    <p className="text text-red-500">{formik.errors.gender}</p>
                  )}
                </>}
                
                {formik.values.audienceConfig[index]?.targetingDetails.map(
                  (countryData, mainIndex) => (
                    <div className="px-3 mt-2 py-1 bg-[#F4F4F4] rounded-md">
                      <p className="text-sm mt-3 mb-1 capitalize">
                        {countryData.type && countryData.type.replace("_", " ")}
                      </p>
                      <div className="grid grid-cols-4 gap-4">
                        {countryData && countryData.options && countryData.options.length > 0 && countryData.options.map(option => (
                          <Input
                            className="mt-0 pl-7"
                            type="text"
                            value={`${option.name}`}
                            iconClasses={
                              "absolute inset-y-0 flex pr-3 cursor-pointer"
                            }
                            onRemove={() =>
                              handleInterestRemove(index, mainIndex, option)
                            }
                            disabled
                          />
                        ))}
                      </div>
                    </div>
                  )
                )}
                <p className="text-sm mt-3 mb-1">Detailed Targeting</p>

                <AsyncSelect
                  name="detailed_targeting"
                  cacheOptions
                  loadOptions={getDemographicsSuggestions}
                  onChange={props => handleSelectDemographic(props, index)}
                  placeholder="Search Locations"
                  className={"w-full border-l-0 bg-[#F4F4F4]"}
                  isDisabled={isTestPhase}
                />
                {/* <p className="text-sm mt-3 mb-1">Languages</p>
                <Dropdown
                  placeholder="All Languages"
                  classRoot="mt-1"
                  options={LANGUAGES}
                  name={`audienceConfig[${index}].language`}
                  onChange={value =>
                    formik.setFieldValue(
                      `audienceConfig[${index}].language`,
                      value
                    )
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.audienceConfig[index]?.language}
                  error={
                    formik.errors.audienceConfig &&
                    formik.errors.audienceConfig[index]?.language
                  }
                  touched={
                    formik.touched.audienceConfig &&
                    formik.touched.audienceConfig[index]?.language
                  }
                /> */}
              </div>
            )}
            {formik.values.audienceConfig[index]?.tabsList[1].isActive && ( <>
              {(savedAudiences && savedAudiences.length > 0) &&
                <div className="mx-5 my-5 grid grid-cols-2 gap-5">
                {savedAudiences.map((items) => (
                  <div className="px-5 py-5 bg-[#F4F4F4] rounded-lg border border-1 border-gray-300">
                    <p className="font-semibold text-sm mb-3">Location:</p>
                    <p className="text-sm text-gray-600">
                      {(items?.geo_locations && items.geo_locations.countries && items.geo_locations.countries.length > 0) &&
                        items?.geo_locations.countries.join(", ")
                      } 
                    </p>
                    <p className="text-sm text-gray-600">
                      {(items?.geo_locations && items.geo_locations.cities && items.geo_locations.cities.length > 0) &&
                        [
                          ...items?.geo_locations.cities
                        ]
                          .map(
                            ({ name, country = "" }) =>
                              name + " - " + country
                          )
                          .join(", ")}
                    </p>
                    <p className="text-sm text-gray-600">
                      {(items?.geo_locations && items.geo_locations.regions && items.geo_locations.regions.length > 0) &&
                        [
                          ...items?.geo_locations.regions
                        ]
                          .map(
                            ({ name, country = "" }) =>
                              name + " - " + country
                          )
                          .join(", ")}
                    </p>
                    
                    <div className="grid grid-cols-2">
                      <div>
                        <p className="font-semibold text-sm mb-3 mt-5">Age</p>
                        <p className="text-sm text-gray-600">
                          {items?.age_min || "18"}
                          -
                          {items?.age_max || "65+"}
                        </p>
                      </div>
                      <div>
                        <p className="font-semibold text-sm mb-3 mt-5">Gender</p>
                        <p className="text-sm text-gray-600">
                          {!items?.gender ? "All" : items?.gender === 1 ? "Male" : "Female"}
                        </p>
                      </div>
                    </div>
                    <p className="font-semibold text-sm mb-3 mt-5">Custom Audience:</p>
                    <p className="text-sm text-gray-600">
                      {items?.ad_set_audience_custom_audiences &&
                        [
                          ...items.ad_set_audience_custom_audiences
                        ]
                          .map(({ name }) => name)
                          .join(", ")}
                    </p>

                    <p className="font-semibold text-sm mb-3 mt-5">
                      People who match:
                    </p>
                    <p className="text-sm text-gray-600">Interests:</p>

                    <Button className="px-7 py-2 bg-[#B6CC43] rounded-xl text-black mr-3 mt-7 border border-gray-500" onClick={() => applySavedAudience(index, items)}>
                      Apply
                    </Button>
                  </div>
                ))}
              </div>}
            </>)}
          </div>
        ))}

        <div className="flex justify-end mt-5">
          <Button
            type="button"
            className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
            onClick={() =>
              formik.setFieldValue("audienceConfig", [
                ...formik.values.audienceConfig,
                {
                  tabsList: [
                    { title: "Create new audience", value: 1, isActive: true },
                    { title: "Use saved audience", value: 2, isActive: false }
                  ],
                  locations: [],
                  targetingDetails: [],
                  age_min: "",
                  age_max: "",
                  gender: "all",
                  customAudience: []
                }
              ])
            }
            disabled={isTestPhase}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5">
          <p className="mb-2 font-bold">Languages Configuration</p>
        </div>
        <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
            <p className="text-sm mt-0 mb-1">Select Target Languages</p>
            <AsyncSelect
                  isMulti
                  name="detailed_targeting"
                  cacheOptions
                  defaultOptions
                  loadOptions={getLocaleSuggestions}
                  onChange={props => {
                    console.log(props);
                    formik.setFieldValue(
                      `locale`,
                      props
                    )
                  }}
                  value={formik.values.locale}
                  placeholder="Search Languages"
                  className={"w-full border-l-0 bg-[#F4F4F4]"}
                  isDisabled={isTestPhase}
            />
        </div>
       

                
      </div>      
      <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5">
          <p className="mb-2 font-bold">Placement Configuration</p>
        </div>
        {formik.values.adPlacementConfiguration.map(({ }, index) => (
          <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
            <div className="relative flex justify-between">
              <p className="mb-2">Placement Configuration {index + 1}</p>
              <button
                type="button"
                className="rounded-md bg-white border border-1 border-gray-600 p-1 absolute right-5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  const temp = [...formik.values.adPlacementConfiguration]
                  temp.splice(index, 1)
                  formik.setFieldValue("adPlacementConfiguration", temp)
                }}
              >
                <span className="sr-only">Close</span>
                <FaTimes className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex mt-5 mb-1">
              <p className="text-sm mr-10">Advantage + Placements</p>
              <Toggle
                isChecked={
                  formik.values.adPlacementConfiguration[index]?.adPlacement
                }
                onChange={() =>
                  formik.setFieldValue(
                    `adPlacementConfiguration[${index}].adPlacement`,
                    !formik.values.adPlacementConfiguration[index]?.adPlacement
                  )
                }
              />{" "}
              <span className="ml-5">Manual Placements</span>
            </div>
            {formik.values.adPlacementConfiguration[index].adPlacement && (
              <>
                <p className="text-sm mt-5 mb-1">Devices</p>
                <Dropdown
                  placeholder="All Devices"
                  classRoot="mt-1"
                  options={[
                    {
                      label: "All Devices",
                      value: "all"
                    },
                    {
                      label: "Desktop",
                      value: "desktop"
                    },
                    {
                      label: "Mobile",
                      value: "mobile"
                    }
                  ]}
                  name={`adPlacementConfiguration[${index}].devices`}
                  onChange={value =>
                    formik.setFieldValue(
                      `adPlacementConfiguration[${index}].devices`,
                      value
                    )
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.adPlacementConfiguration[index]?.devices}
                  error={
                    formik.errors?.adPlacementConfiguration &&
                    formik.errors.adPlacementConfiguration[index]?.devices
                  }
                  touched={
                    formik.touched.adPlacementConfiguration &&
                    formik.touched.adPlacementConfiguration[index]?.devices
                  }
                />
                <p className="text-sm mt-3 mb-1">Placements</p>
                <p className="text-sm mt-5 ml-3">Publisher Platforms :</p>
                <div className="grid grid-cols-1 md:grid-cols-3 m-3">
                  <Checkbox
                    title="Facebook"
                    name={`${index}.facebook`}
                    onChange={handlePublisherPlatforms}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].publisher_platforms.includes("facebook")}
                  />
                  <Checkbox
                    title="Instagram"
                    name={`${index}.instagram`}
                    onChange={handlePublisherPlatforms}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].publisher_platforms.includes("instagram")}
                  />
                  <Checkbox
                    title="Messenger"
                    name={`${index}.messenger`}
                    onChange={handlePublisherPlatforms}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].publisher_platforms.includes("messenger")}
                  />
                  <Checkbox
                    title="Audience Network"
                    name={`${index}.audience_network`}
                    onChange={handlePublisherPlatforms}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.publisher_platforms
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].publisher_platforms.includes("audience_network")}
                  />
                </div>
                <p className="text-sm mt-5 ml-3">Facebook Positions :</p>
                <div className="grid grid-cols-1 md:grid-cols-4 m-3 gap-4">
                  <Checkbox
                    title="Facebook Feed"
                    name={`${index}.feed`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("feed")}
                  />
                  <Checkbox
                    title="Right Hand Column"
                    name={`${index}.right_hand_column`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("right_hand_column")}
                  />
                  <Checkbox
                    title="Marketplace"
                    name={`${index}.marketplace`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("marketplace")}
                  />
                  <Checkbox
                    title="Video Feeds"
                    name={`${index}.video_feeds`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("video_feeds")}
                  />
                  <Checkbox
                    title="Story"
                    name={`${index}.story`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("story")}
                  />
                  <Checkbox
                    title="Search"
                    name={`${index}.search`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("search")}
                  />
                  <Checkbox
                    title="Instream Video"
                    name={`${index}.instream_video`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("instream_video")}
                  />
                  <Checkbox
                    title="Facebook Reels"
                    name={`${index}.facebook_reels`}
                    onChange={handleFacebookPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.facebook_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].facebook_positions.includes("facebook_reels")}
                  />
                </div>
                <p className="text-sm mt-5 ml-3">Instagram Positions :</p>
                <div className="grid grid-cols-1 md:grid-cols-3 m-3">
                  <Checkbox
                    title="Stream"
                    name={`${index}.stream`}
                    onChange={handleInstagramPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].instagram_positions.includes("stream")}
                  />
                  <Checkbox
                    title="Story"
                    name={`${index}.story`}
                    onChange={handleInstagramPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].instagram_positions.includes("story")}
                  />
                  <Checkbox
                    title="Explore"
                    name={`${index}.explore`}
                    onChange={handleInstagramPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].instagram_positions.includes("explore")}
                  />
                  <Checkbox
                    title="Explore Home"
                    name={`${index}.explore_home`}
                    onChange={handleInstagramPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].instagram_positions.includes("explore_home")}
                  />
                  <Checkbox
                    title="Reels"
                    name={`${index}.reels`}
                    onChange={handleInstagramPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].instagram_positions.includes("reels")}
                  />
                  <Checkbox
                    title="Profile Feed"
                    name={`${index}.profile_feed`}
                    onChange={handleInstagramPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].instagram_positions.includes("profile_feed")}
                  />
                  <Checkbox
                    title="IG Search"
                    name={`${index}.ig_search`}
                    onChange={handleInstagramPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.instagram_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].instagram_positions.includes("ig_search")}
                  />
                </div>
                <p className="text-sm mt-5 ml-3">
                  Audience Network Positions :
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 m-3">
                  <Checkbox
                    title="Classic"
                    name={`${index}.classic`}
                    onChange={handleAudienceNetworkPosition}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.audience_network_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.audience_network_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.audience_network_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].audience_network_positions.includes("classic")}
                  />
                  <Checkbox
                    title="Rewarded Video"
                    name={`${index}.rewarded_video`}
                    onChange={handleAudienceNetworkPosition}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.audience_network_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.audience_network_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.audience_network_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].audience_network_positions.includes("rewarded_video")}
                  />
                </div>
                <p className="text-sm mt-5 ml-3">Messenger Positions :</p>
                <div className="grid grid-cols-1 md:grid-cols-3 m-3">
                  <Checkbox
                    title="Messenger Home"
                    name={`${index}.messenger_home`}
                    onChange={handleMessengerPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].messenger_positions.includes("messenger_home")}
                  />
                  <Checkbox
                    title="Sponsored Messages"
                    name={`${index}.sponsored_messages`}
                    onChange={handleMessengerPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].messenger_positions.includes("sponsored_messages")}
                  />
                  <Checkbox
                    title="Story"
                    name={`${index}.story`}
                    onChange={handleMessengerPositions}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    error={
                      formik.errors.adPlacementConfiguration &&
                      formik.errors.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    touched={
                      formik.touched.adPlacementConfiguration &&
                      formik.touched.adPlacementConfiguration[index]
                        ?.messenger_positions
                    }
                    checked={formik.values.adPlacementConfiguration[
                      index
                    ].messenger_positions.includes("story")}
                  />
                </div>
              </>
            )}

            {formik.errors.leads_place && formik.touched.leads_place && (
              <p className="text text-red-500">{formik.errors.leads_place}</p>
            )}
          </div>
        ))}
        <div className="flex justify-end">
          <Button
            type="button"
            onClick={() =>
              formik.setFieldValue("adPlacementConfiguration", [
                ...formik.values.adPlacementConfiguration,
                {
                  adPlacement: false,
                  devices: {},
                  publisher_platforms: [
                    "facebook",
                    "instagram",
                    "messenger",
                    "audience_network"
                  ],
                  facebook_positions: [
                    "feed",
                    "right_hand_column",
                    "marketplace",
                    "video_feeds",
                    "story",
                    "search",
                    "instream_video",
                    "facebook_reels"
                  ],
                  instagram_positions: [
                    "stream",
                    "story",
                    "explore",
                    "explore_home",
                    "reels",
                    "profile_feed",
                    "ig_search"
                  ],
                  audience_network_positions: ["classic", "rewarded_video"],
                  messenger_positions: [
                    "messenger_home",
                    "sponsored_messages",
                    "story"
                  ]
                }
              ])
            }
            className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
            disabled={isTestPhase}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
        <div className="px-5">
          <p className="mb-2 font-bold">Optimization &amp; Delivery Configuration</p>
        </div>
        {formik.values.optimizationAndDeliveryConfig.map(({ }, index) => (
          <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
            <div className="relative flex justify-between">
              <p className="mb-2">Optimization &amp; Delivery Configuration {index + 1}</p>
              <button
                type="button"
                className="rounded-md bg-white border border-1 border-gray-600 p-1 absolute right-5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  const temp = [...formik.values.optimizationAndDeliveryConfig]
                  temp.splice(index, 1)
                  formik.setFieldValue("optimizationAndDeliveryConfig", temp)
                }}
                disabled={isTestPhase}
              >
                <span className="sr-only">Close</span>
                <FaTimes className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <p className="font-medium my-2">Optimization for ad Delivery</p>
            <Dropdown
              placeholder="Select"
              options={(campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_LEADS') ? OPTIMIZATION_GOALS_LEADS : (campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_SALES') ? OPTIMIZATION_GOALS_SALES : (campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_TRAFFIC') ? OPTIMIZATION_GOALS_TRAFFIC : OPTIMIZATION_GOALS}
              name={`optimizationAndDeliveryConfig[${index}].adOptimization`}
              onChange={value =>
                formik.setFieldValue(
                  `optimizationAndDeliveryConfig[${index}].adOptimization`,
                  value
                )
              }
              onBlur={formik.handleBlur}
              value={
                formik.values?.optimizationAndDeliveryConfig &&
                formik.values?.optimizationAndDeliveryConfig[index]
                  ?.adOptimization
              }
              error={
                formik.errors?.optimizationAndDeliveryConfig &&
                formik.errors?.optimizationAndDeliveryConfig[index]
                  ?.adOptimization
              }
              touched={
                formik.touched?.optimizationAndDeliveryConfig &&
                formik.touched?.optimizationAndDeliveryConfig[index]
                  ?.adOptimization
              }
              disabled={isTestPhase}
            />

            {(formik.values?.optimizationAndDeliveryConfig && formik.values?.optimizationAndDeliveryConfig[index]?.adOptimization && formik.values?.optimizationAndDeliveryConfig[index]?.adOptimization.value === 'OFFSITE_CONVERSIONS') && <>
              <p className="font-medium my-2">Attributions Settings</p>
              <Dropdown
                placeholder="Select"
                options={ATTRIBUTATION_SETTINGS}
                name={`optimizationAndDeliveryConfig[${index}].settings`}
                onChange={value =>
                  formik.setFieldValue(
                    `optimizationAndDeliveryConfig[${index}].settings`,
                    value
                  )
                }
                onBlur={formik.handleBlur}
                value={
                  formik.values?.optimizationAndDeliveryConfig &&
                  formik.values?.optimizationAndDeliveryConfig[index]?.settings
                }
                error={
                  formik.errors?.optimizationAndDeliveryConfig &&
                  formik.errors?.optimizationAndDeliveryConfig[index]?.settings
                }
                touched={
                  formik.touched?.optimizationAndDeliveryConfig &&
                  formik.touched?.optimizationAndDeliveryConfig[index]?.settings
                }
                disabled={isTestPhase}
              />
            </>}
            <p className="font-medium my-2">Delivery Type</p>
            <Dropdown
              placeholder="Select"
              options={DELIVERY_TYPE}
              name={`optimizationAndDeliveryConfig[${index}].deliveryType`}
              onChange={value =>
                formik.setFieldValue(
                  `optimizationAndDeliveryConfig[${index}].deliveryType`,
                  value
                )
              }
              onBlur={formik.handleBlur}
              value={
                formik.values?.optimizationAndDeliveryConfig &&
                formik.values?.optimizationAndDeliveryConfig[index]
                  ?.deliveryType
              }
              error={
                formik.errors?.optimizationAndDeliveryConfig &&
                formik.errors?.optimizationAndDeliveryConfig[index]
                  ?.deliveryType
              }
              touched={
                formik.touched?.optimizationAndDeliveryConfig &&
                formik.touched?.optimizationAndDeliveryConfig[index]
                  ?.deliveryType
              }
              disabled={isTestPhase}
            />
            {budgetType === "ABO" && (
              <>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <p className="font-medium my-2">Budget</p>
                    <Dropdown
                      placeholder="Budget Type"
                      classRoot="mt-1"
                      options={BUDGET_TYPE_ENUM}
                      name={`optimizationAndDeliveryConfig[${index}].budgetType`}
                      onChange={value =>
                        formik.setFieldValue(
                          `optimizationAndDeliveryConfig[${index}].budgetType`,
                          value
                        )
                      }
                      onBlur={formik.handleBlur}
                      value={
                        formik.values?.optimizationAndDeliveryConfig &&
                        formik.values?.optimizationAndDeliveryConfig[index]
                          .budgetType
                      }
                      error={
                        formik.errors?.optimizationAndDeliveryConfig &&
                        formik.errors?.optimizationAndDeliveryConfig[index]
                          ?.budgetType
                      }
                      touched={
                        formik.touched?.optimizationAndDeliveryConfig &&
                        formik.touched?.optimizationAndDeliveryConfig[index]
                          ?.budgetType
                      }
                      disabled={isTestPhase}
                    />
                  </div>
                  <div>
                    <p className="font-medium my-2">Budget Price</p>
                    <Input
                      placeholder="$ 24.00"
                      className="mt-0"
                      iconClasses={
                        "absolute inset-y-0 right-0 flex pr-3 cursor-pointer"
                      }
                      Icon={() => (
                        <div className="pointer-events-none absolute border-l pl-2 border-l-black inset-y-0 right-0 flex items-center pr-3">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            USD
                          </span>
                        </div>
                      )}
                      name={`optimizationAndDeliveryConfig[${index}].budgetPrice`}
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values?.optimizationAndDeliveryConfig &&
                        formik.values?.optimizationAndDeliveryConfig[index]
                          .budgetPrice
                      }
                      error={
                        formik.errors?.optimizationAndDeliveryConfig &&
                        formik.errors?.optimizationAndDeliveryConfig[index] &&
                        formik.errors?.optimizationAndDeliveryConfig[index]
                          .budgetPrice
                      }
                      touched={
                        formik.touched?.optimizationAndDeliveryConfig &&
                        formik.touched?.optimizationAndDeliveryConfig[index] &&
                        formik.touched?.optimizationAndDeliveryConfig[index]
                          .budgetPrice
                      }
                      disabled={isTestPhase}
                    />
                  </div>
                </div>
              </>)}

              <div className="grid grid-cols-2 gap-3">
                {budgetType === "ABO" && (
                <>
                  <div>
                    <p className="text-sm mt-3 mb-1">Bid Strategies</p>
                    <Dropdown
                      placeholder="Bid Strategy"
                      classRoot="mt-1"
                      options={BID_STRATEGY_ENUM}
                      name={`optimizationAndDeliveryConfig[${index}].bidStrategy`}
                      onChange={value =>
                        formik.setFieldValue(
                          `optimizationAndDeliveryConfig[${index}].bidStrategy`,
                          value
                        )
                      }
                      onBlur={formik.handleBlur}
                      value={
                        formik.values?.optimizationAndDeliveryConfig &&
                        formik.values?.optimizationAndDeliveryConfig[index]?.bidStrategy
                      }
                      error={
                        formik.errors?.optimizationAndDeliveryConfig &&
                        formik.errors?.optimizationAndDeliveryConfig[index]?.bidStrategy
                      }
                      touched={
                        formik.touched?.optimizationAndDeliveryConfig &&
                        formik.touched?.optimizationAndDeliveryConfig[index]?.bidStrategy
                      }
                      disabled={isTestPhase}
                    />
                  </div>
                </>)}

                {((campaignData && campaignData.bid_strategy === "COST_CAP") || (campaignData && campaignData.bid_strategy === "LOWEST_COST_WITH_BID_CAP") || (formik.values?.optimizationAndDeliveryConfig && formik.values?.optimizationAndDeliveryConfig[index]?.bidStrategy?.value === "COST_CAP") ||
                  (formik.values?.optimizationAndDeliveryConfig && formik.values?.optimizationAndDeliveryConfig[index]?.bidStrategy?.value === "LOWEST_COST_WITH_BID_CAP")) && (
                    <div>
                      <p className="text-sm mt-3 mb-1">Bid Amount</p>
                      <Input
                        placeholder="Bid Amount"
                        className="mt-0"
                        name={`optimizationAndDeliveryConfig[${index}].bidAmount`}
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values?.optimizationAndDeliveryConfig &&
                          formik.values?.optimizationAndDeliveryConfig[index]
                            .bidAmount
                        }
                        error={
                          formik.errors?.optimizationAndDeliveryConfig &&
                          formik.errors?.optimizationAndDeliveryConfig[index] &&
                          formik.errors?.optimizationAndDeliveryConfig[index]
                            .bidAmount
                        }
                        touched={
                          formik.touched?.optimizationAndDeliveryConfig &&
                          formik.touched?.optimizationAndDeliveryConfig[index] &&
                          formik.touched?.optimizationAndDeliveryConfig[index]
                            .bidAmount
                        }
                        disabled={isTestPhase}
                      />
                    </div>
                  )}
              </div>
            
            <div className="grid grid-cols-2 gap-3">
              <div><p className="text-sm mt-3 mb-1">Schedule <span className="text-gray-500 text-xs">(Start Date)</span></p>
              <div className="grid grid-cols-2 gap-3">
                <Input
                  placeholder="Start Date"
                  type="date"
                  className="mt-0 pr-0"
                  name={`optimizationAndDeliveryConfig[${index}].start_date`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values?.optimizationAndDeliveryConfig &&
                    formik.values?.optimizationAndDeliveryConfig[index] &&
                    formik.values?.optimizationAndDeliveryConfig[index].start_date
                  }
                  error={
                    formik.errors?.optimizationAndDeliveryConfig &&
                    formik.errors?.optimizationAndDeliveryConfig[index] &&
                    formik.errors?.optimizationAndDeliveryConfig[index].start_date
                  }
                  touched={
                    formik.touched?.optimizationAndDeliveryConfig &&
                    formik.touched?.optimizationAndDeliveryConfig[index] &&
                    formik.touched?.optimizationAndDeliveryConfig[index].start_date
                  }
                  disabled={isTestPhase}
                />
                
                <Input
                  placeholder="Start Time"
                  type="time"
                  className="mt-0 pr-0"
                  name={`optimizationAndDeliveryConfig[${index}].start_time`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values?.optimizationAndDeliveryConfig &&
                    formik.values?.optimizationAndDeliveryConfig[index] &&
                    formik.values?.optimizationAndDeliveryConfig[index].start_time
                  }
                  error={
                    formik.errors?.optimizationAndDeliveryConfig &&
                    formik.errors?.optimizationAndDeliveryConfig[index] &&
                    formik.errors?.optimizationAndDeliveryConfig[index].start_time
                  }
                  touched={
                    formik.touched?.optimizationAndDeliveryConfig &&
                    formik.touched?.optimizationAndDeliveryConfig[index] &&
                    formik.touched?.optimizationAndDeliveryConfig[index].start_time
                  }
                  disabled={isTestPhase}
                />
              </div>
            </div>

            {formik.values?.optimizationAndDeliveryConfig &&
              formik.values?.optimizationAndDeliveryConfig[index] &&
              formik.values?.optimizationAndDeliveryConfig[index].showEndDate && ( <div>
                <p className="text-sm mt-3 mb-1">Schedule <span className="text-gray-500 text-xs">(End Date)</span></p>
                <div className="grid grid-cols-2 gap-3">
                  <Input
                    placeholder="End Date"
                    type="date"
                    className="mt-0 pr-0"
                    name={`optimizationAndDeliveryConfig[${index}].end_date`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values?.optimizationAndDeliveryConfig &&
                      formik.values?.optimizationAndDeliveryConfig[index] &&
                      formik.values?.optimizationAndDeliveryConfig[index].end_date
                    }
                    error={
                      formik.errors?.optimizationAndDeliveryConfig &&
                      formik.errors?.optimizationAndDeliveryConfig[index] &&
                      formik.errors?.optimizationAndDeliveryConfig[index].end_date
                    }
                    touched={
                      formik.touched?.optimizationAndDeliveryConfig &&
                      formik.touched?.optimizationAndDeliveryConfig[index] &&
                      formik.touched?.optimizationAndDeliveryConfig[index].end_date
                    }
                  />
                  <Input
                    placeholder="End Time"
                    type="time"
                    className="mt-0 pr-0"
                    name={`optimizationAndDeliveryConfig[${index}].end_time`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values?.optimizationAndDeliveryConfig &&
                      formik.values?.optimizationAndDeliveryConfig[index] &&
                      formik.values?.optimizationAndDeliveryConfig[index].end_time
                    }
                    error={
                      formik.errors?.optimizationAndDeliveryConfig &&
                      formik.errors?.optimizationAndDeliveryConfig[index] &&
                      formik.errors?.optimizationAndDeliveryConfig[index].end_time
                    }
                    touched={
                      formik.touched?.optimizationAndDeliveryConfig &&
                      formik.touched?.optimizationAndDeliveryConfig[index] &&
                      formik.touched?.optimizationAndDeliveryConfig[index].end_time
                    }
                    disabled={isTestPhase}
                  />
                </div></div>
              )}
            </div>
            <Checkbox
              title="Add End Date"
              onClick={() => {
                const temp = [...formik.values.optimizationAndDeliveryConfig]
                temp[index]["showEndDate"] = !temp[index]["showEndDate"]
                formik.setFieldValue("optimizationAndDeliveryConfig", temp)
              }}
              disabled={isTestPhase}
            />
          </div>
        ))}
        <div className="flex justify-end">
          <Button
            type="button"
            onClick={() =>
              formik.setFieldValue("optimizationAndDeliveryConfig", [
                ...formik.values.optimizationAndDeliveryConfig,
                {
                  adOptimization: {},
                  settings: { value: '1d_click', label: '1d Click' },
                  deliveryType: { value: 'standard', label: 'Standard' },
                  budgetType: (campaignData && campaignData.budget_type === "ABO") ? { label: 'DAILY', value: 'DAILY' } : null,
                  bidStrategy: (campaignData && campaignData.budget_type === "ABO") ? { value: "LOWEST_COST_WITHOUT_CAP", label: "Lowest cost highest value" } : null,
                  budgetPrice: null, 
                  start_date: "", start_time: "", showEndDate: false, end_date: "", end_time: ""
                }
              ])
            }
            className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
            disabled={isTestPhase}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="flex justify-end mt-5">
        <Button
          className="my-2 md:my-0 px-7 py-2 bg-[#00B2B4] rounded-xl mr-3"
          onClick={() => navigate(`/dashboard/campagins-structure/${campaignId}`)}
        >
          Previous
        </Button>
        <Button
          type="submit"
          className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
        >
          {(submitting) ? <Spinner className="w-5 h-5" /> : 'Next'}
        </Button>
      </div>
    </form>
  </>
  )
}

export const AdCreation = ({ submitData, campaignData, submitting, errors }) => {

  const dropzoneRef = useRef([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { campaignId } = useParams()
  
  const { offerList, campaigns } = useSelector(({ common }) => common)

  const [facebookPages, setFacebookPages] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [selectedInput, setSelectedInput] = useState({})
  const [maxLength, setMaxlength] = useState(0)
  const [currentSliderIndex, setSliderIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const [presetOptions, setPresetOptions] = useState([])
  const [insantFormOtions, setInsantFormOtions] = useState([])
  const [pixelOtions, setPixelOtions] = useState([])
  const [selectedCreatives, setSelectedCreatives] = useState([])
  const [loading, setLoading] = useState(false)
  const [creativeType, setCreativeType] = useState("")
  const [showManageColumnsModal, setShowManageColums] = useState(false)
  const [offers, setOffers] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [allColumns, setColumns] = useState([
    { label: "ID", value: "id", isActive: false },
    { label: "Creative", value: "creative", isActive: false },
    { label: "Impressions", value: "impressions", isActive: false },
    { label: "Submitted Leads", value: "ms_submitted_leads", isActive: false },
    { label: "Sold Leads", value: "ms_sold_leads", isActive: false },
    { label: "Clicks", value: "ms_clicks", isActive: true },
    { label: "CTR", value: "ms_ctr", isActive: true },
    { label: "ROI", value: "ms_roi", isActive: true },
    { label: "Conversion Rate", value: "ms_conversion_rate", isActive: false },
    { label: "Sold Rate", value: "ms_sold_rate", isActive: true },
    { label: "EPC", value: "ms_epc", isActive: true },
    { label: "CPC", value: "ms_lpvc", isActive: true },
    { label: "Revenue", value: "ms_revenue", isActive: true },
    { label: "Profit", value: "ms_profit", isActive: false },
    { label: "Spend", value: "spend", isActive: false }
  ])
  const [openInsertToken, setOpenInsertToken] = useState(false)
  const [currentElementId, setCurrentElementId] = useState(null)
  const [isTestPhase, setIsTestPhase] = useState(false)

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  const formik = useFormik({
    initialValues: {
      meta_facebook_page_config: { is_website: false },
      meta_media_configs: [{ meta_media_images: [], is_carousel: false }],
      meta_text_configs: [{ text: "" }],
      meta_headline_configs: [{ text: "" }],
      meta_description_configs: [{ text: "" }],
      extra_parameters: "",
      ad_naming: ""
    },
    // validationSchema: createCampaignStepTwo,
    onSubmit: submitData
  })

  useEffect(() => {
    if (campaignId) {
      handleGetFacebookPages(campaignId)
      handleGetFacebookPixels(campaignId)
      dispatch(getOfferLists())
      dispatch(getCampaignLists())
    }
  }, [])

  useEffect(() => {
    if (offerList) {
      const res = offerList.map(item => {
        return { label: item.name, value: item.id }
      })
      setOffers(res)
    }
  }, [offerList])

  useEffect(() => {
    if (campaigns) {
      const res = campaigns.map(item => {
        return { label: item.name, value: item.id }
      })
      setCampaignList(res)
    }
  }, [campaigns])

  const getUrlExtension = (url) => {
    return url
      .split(/[#?]/)[0]
      .split(".")
      .pop()
      .trim();
  }

  useEffect(() => {
    if (campaignData) {

      if (campaignData.test_phase && campaignData.test_phase.phase_number && campaignData.test_phase.phase_number >= 2) {
        setIsTestPhase(true)
      }

      setTimeout(() => {
        formik.setFieldValue("ad_naming", campaignData.ad_naming)
        formik.setFieldValue("meta_media_configs", campaignData.meta_media_configs)
        formik.setFieldValue("meta_text_configs", campaignData.meta_text_configs)
        formik.setFieldValue("meta_headline_configs", campaignData.meta_headline_configs)
        formik.setFieldValue("meta_description_configs", campaignData.meta_description_configs)
        formik.setFieldValue("extra_parameters", campaignData.extra_parameters)

        const pageconfig = {
          page: campaignData.meta_facebook_page_config?.id ? { label: campaignData.meta_facebook_page_config?.name, value: campaignData.meta_facebook_page_config?.id	} : { label: '', value: ''},
          call_to_action: campaignData.meta_facebook_page_config?.call_to_action ? { label: campaignData.meta_facebook_page_config?.call_to_action.replace('_', ' '), value: campaignData.meta_facebook_page_config?.call_to_action	} : { label: '', value: ''},
          instant: campaignData.meta_facebook_page_config?.instant_form_id ? { label: campaignData.meta_facebook_page_config?.instant_form_name, value: campaignData.meta_facebook_page_config?.instant_form_id	} : { label: '', value: ''},
          pixel: campaignData?.pixel_id ? { label: campaignData?.pixel_name, value: campaignData?.pixel_id	} : { label: '', value: ''},
          custom_event_type: campaignData?.custom_event_type ? { label: campaignData?.custom_event_type.replace('_', ' '), value: campaignData?.custom_event_type	} : { label: '', value: ''},
        }
        formik.setFieldValue("meta_facebook_page_config", pageconfig)
        if (campaignData.meta_facebook_page_config?.instant_form_id) {
          formik.setFieldValue(`meta_facebook_page_config.is_website`,  true)
        }

      }, 800)
    }
  }, [campaignData])

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        formik.setFieldError("meta_media_configs", errors.meta_media_configs)
        formik.setFieldError("meta_text_configs", errors.meta_text_configs)
        formik.setFieldError("meta_headline_configs", errors.meta_headline_configs)
        formik.setFieldError("meta_description_configs", errors.meta_description_configs)
      }, 500)
    }
  }, [errors])

  useEffect(() => {
    Math.max([])
    const value = Math.max(
      formik.values.meta_media_configs.length,
      formik.values.meta_text_configs.length,
      formik.values.meta_headline_configs.length,
      formik.values.meta_description_configs.length
    )

    setMaxlength(value)
  }, [
    formik.values.meta_media_configs.length,
    formik.values.meta_text_configs.length,
    formik.values.meta_headline_configs.length,
    formik.values.meta_description_configs.length
  ])

  const handleGetFacebookPages = async (campaignId) => {
    try {
      const { data } = await getFacebookPages(campaignId)
      setFacebookPages(
        data.map(({ id: value, name: label }) => ({ value, label }))
      )
    } catch (e) {
      console.log("error", e)
    }
  }

  const handleGetFacebookPixels = async (campaignId) => {
    try {
      const { data } = await getFacebookPixels(campaignId)
      setPixelOtions(
        data.map(({ id: value, name: label }) => ({ value, label }))
      )
    } catch (e) {
      console.log("error", e)
    }
  }

  const handleFacebookPage = async (fbId) => {
    try {
      const { data } = await getInstantForm(campaignId, fbId)
      setInsantFormOtions(
        data.map(({ id: value, name: label }) => ({ value, label }))
      )
    } catch (e) {
      console.log("error", e)
    }
  }

  const handleFilterData = async (values) => {
    try {
      let current = (currentPage > 0) ? currentPage : 0
      let params = { offset: current, limit: 10 }

      if (creativeType) {
        params.fetch = (creativeType === 'Description') ? 'description' : (creativeType === 'Headline') ? 'headline' : (creativeType === 'Primary Text') ? 'text' : ''
      }
      
      if (values.offer) {
        params.offer_ids = values.offer.value
      }
      if (values.campaign) {
        params.campaign_ids = values.campaign.value
      }
      if (values.start_date) {
        params.insights_start_date = values.start_date
      }
      if (values.end_date) {
        params.insights_end_date = values.end_date
      }
    
      setLoading(true)
      const { data } = await getCreativeSelector(params)
      setPresetOptions(data.results)
      setTotalItems(data.count)
      setLoading(false)
    } catch (error) {

    }
  }

  const formikselector = useFormik({
    initialValues: {
      offer: {},
      campaign: {},
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: handleFilterData
  })

  // write test cases for this
  const getExistingValues = async (type, text = searchText) => {
    try {

      let current = (currentPage > 0) ? currentPage : 0
      let params = { offset: current, limit: 10 }

      switch (type) {
        case "meta_text_configs":
          setCreativeType("Primary Text")
          return await getCreativeSelector({...params, fetch: 'text' })
        case "meta_description_configs":
          setCreativeType("Description")
          return await getCreativeSelector({...params, fetch: 'description' })
        case "meta_headline_configs":
          setCreativeType("Headline")
          return await getCreativeSelector({...params, fetch: 'headline' })
        case "getMetaMediaImagesConfigs":
          setCreativeType("Media")
          return await getMetaMediaImagesConfigs({...params})
      }
    } catch (e) {
      console.log("error", e)
    }
  }

  useEffect(() => {
    handleFilterData({})
  }, [currentPage])

  const handleSelectExistingValues = async (
    index,
    type,
    search = searchText
  ) => {
    try {
      formikselector.resetForm()
      formikselector.setFieldValue(`start_date`, moment().format('YYYY-MM-DD'))
      formikselector.setFieldValue(`end_date`, moment().format('YYYY-MM-DD'))
      setSelectedCreatives([])
      setLoading(true)
      setSelectedInput({ index, type })
      setOpen(true)
      const { data } = await getExistingValues(type, search)
      setPresetOptions(data.results)
      setTotalItems(data.count)
      setLoading(false)
    } catch (e) {
      console.log("error", e)
      setLoading(false)
    }
  }

  const handleFileDrop = (index, acceptedFiles) => {
    if (!formik.values.meta_media_configs[index].is_carousel) {
      if (acceptedFiles.length > 1) {
        acceptedFiles.forEach((item, key) => {
          formik.setFieldValue(
            `meta_media_configs[${index + key}].meta_media_images`,
            [item]
          )
        })
      } else {
        formik.setFieldValue(
          `meta_media_configs[${index}].meta_media_images`,
          acceptedFiles
        )
      }
    } else {
      formik.setFieldValue(
        `meta_media_configs[${index}].meta_media_images`,
        acceptedFiles
      )
    }
    // Handle dropped files here
  }

  const handleOpenBrowseWindow = (i) => {
    // dropzoneRef.current.open()
    dropzoneRef.current[i].open()
  }

  const debouncedChangeHandler = useCallback(
    debounce(handleSelectExistingValues, 1000),
    []
  )

  const handleTextInput = ({ target: { value } }) => {
    setSearchText(value)
    debouncedChangeHandler(selectedInput.index, selectedInput.type, value)
  }

  const creativeColumns = [
    {
      name: "Creative",
      id: 'creative',
      sortable: false,
      minWidth: '350px',
      omit: allColumns[1].isActive,
      selector: row => <span>{row.creative}</span>,
      wrap: 'no'
    },
    {
      name: "Impressions",
      sortable: false,
      minWidth: '180px',
      center: true,
      omit: allColumns[2].isActive,
      selector: row => <span>{row.impressions}</span>
    },
    {
      name: "Submitted Leads",
      sortable: false,
      minWidth: '180px',
      center: true,
      omit: allColumns[3].isActive,
      selector: row => <span>{row.ms_submitted_leads}</span>
    },
    {
      name: "Sold Leads",
      sortable: false,
      center: true,
      omit: allColumns[4].isActive,
      selector: row => <span>{row.ms_sold_leads}</span>
    },
    {
      name: "Clicks",
      sortable: false,
      center: true,
      omit: allColumns[5].isActive,
      selector: row => <span>{row.ms_clicks}</span>
    },
    {
      name: "CTR",
      sortable: false,
      center: true,
      omit: allColumns[6].isActive,
      selector: row => <span>${row.ms_ctr}</span>
    },
    {
      name: "ROI",
      sortable: false,
      center: true,
      omit: allColumns[7].isActive,
      selector: row => <span>{row.ms_roi}%</span>
    },
    {
      name: "Conversion Rate",
      sortable: false,
      center: true,
      minWidth: '150px',
      omit: allColumns[8].isActive,
      selector: row => (
        <div className="flex items-center">
          {row.ms_conversion_rate.toFixed(2)}%
        </div>
      )
    },
    {
      name: "Sold Rate",
      sortable: false,
      center: true,
      omit: allColumns[9].isActive,
      selector: row => (
        <div className="flex items-center">
          {row.ms_sold_rate.toFixed(2)}%
        </div>
      )
    },
    {
      name: "EPC",
      sortable: false,
      center: true,
      omit: allColumns[10].isActive,
      selector: row => <span>{row.ms_epc}</span>
    },
    {
      name: "CPC",
      sortable: false,
      center: true,
      omit: allColumns[11].isActive,
      selector: row => <span>{row.ms_lpvc}</span>
    },
    {
      name: "Spend",
      sortable: false,
      center: true,
      omit: allColumns[14].isActive,
      selector: row => <span>${row.spend}</span>
    },
    {
      name: "Revenue",
      sortable: false,
      center: true,
      omit: allColumns[12].isActive,
      selector: row => <span>${row.ms_revenue}</span>
    },
    {
      name: "Profit",
      sortable: false,
      center: true,
      omit: allColumns[13].isActive,
      selector: row => <span>${row.ms_profit}</span>
    }
  ]

  const handleRowSelection = ({ selectedRows }) => {
    setSelectedCreatives(selectedRows)
  }

  const handleAddSelected = () => {
    selectedCreatives.forEach((item, key) => {
      formik.setFieldValue(
        `${selectedInput.type}[${key}]`,
        { "text": item.creative }
      )
    })
    setOpen(false)
  }

  const handleNamingChange = (e) => {
    console.log(currentElementId);
    if (e && currentElementId) {
      const input = document.getElementById(currentElementId)
      
      if (input) {
        const val = formik.values[currentElementId]
        const cursorPosition = input.selectionStart
        const textBefore = val.substring(0, cursorPosition)
        const textAfter = val.substring(cursorPosition, val.length)
       
        formik.setFieldValue(currentElementId, `${textBefore} ${e.value} ${textAfter}`)
      }
    }
  }

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        boxShadow: '5px 1px 5px 1px #5E84C24F'
      }
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  return (
    <>
      <ManageColumnsCompaginsModals
        open={showManageColumnsModal}
        setOpen={setShowManageColums}
        allColumns={allColumns}
        setColumns={setColumns}
      />
      <AddTokenModal 
        open={openInsertToken} 
        setOpen={setOpenInsertToken} 
        handleChange={handleNamingChange}
        currentElement={currentElementId}
      />

      <BaseModal
        open={open}
        setOpen={setOpen}
        title={`Creative Selector - ${creativeType}`}
        className="xl:max-w-screen-xl 3xl:max-w-screen-3xl"
      >
        <div className="mt-4">
          {creativeType != 'Media'?<form onSubmit={formikselector.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-6 lg:gap-5 mb-2 items-end mt-4">
              <div className="block">
                <span className="font-semibold text-gray-500">Offers</span>
                <Dropdown
                  placeholder="Select"
                  classBox="bg-white"
                  options={offers}
                  onChange={value =>
                    formikselector.setFieldValue("offer", value)
                  }
                  onBlur={formikselector.handleBlur}
                  value={formikselector.values.offer}
                  error={formikselector.errors.offer}
                  touched={formikselector.touched.offer}
                />
              </div>
              <div className="block">
                <span className="font-semibold text-gray-500">Campaigns</span>
                <Dropdown
                  placeholder="Select"
                  classBox="bg-white"
                  options={campaignList}
                  onChange={value =>
                    formikselector.setFieldValue("campaign", value)
                  }
                  onBlur={formikselector.handleBlur}
                  value={formikselector.values.campaign}
                  error={formikselector.errors.campaign}
                  touched={formikselector.touched.campaign}
                />
              </div>
              <div className="block">
                <span className="font-semibold text-gray-500">Start Date</span>
                <Input
                  placeholder="Start Date"
                  type="date"
                  className="bg-white"
                  name="start_date"
                  onChange={(e) => formikselector.setFieldValue("start_date", e.target.value)}
                  onBlur={formikselector.handleBlur}
                  value={formikselector.values.start_date}
                  error={formikselector.errors.start_date}
                  touched={formikselector.touched.start_date}
                />
              </div>
              <div className="block">
                <span className="font-semibold text-gray-500">End Date</span>
                <Input
                  placeholder="End Date"
                  type="date"
                  className="bg-white"
                  name="end_date"
                  onChange={(e) => formikselector.setFieldValue("end_date", e.target.value)}
                  onBlur={formikselector.handleBlur}
                  value={formikselector.values.end_date}
                  error={formikselector.errors.end_date}
                  touched={formikselector.touched.end_date}
                />
              </div>
              <button className="px-2 py-2 my-4 button-gradient rounded-xl font-bold text-white" button="submit">
                Apply
              </button>
              <button className="px-2 py-2 my-4 bg-[#B6CC43] rounded-xl font-bold text-white" onClick={() => setShowManageColums(true)}>
                Manage Columns
              </button>
            </div>
          </form>:null}

          {creativeType != 'Media'? <div className="relative py-3">
            <DataTable
              columns={creativeColumns}
              data={presetOptions}
              responsive={true}
              progressPending={loading}
              customStyles={customStyles}
              progressComponent={<Spinner />}
              selectableRows
              onSelectedRowsChange={handleRowSelection}
              className='react-dataTable'
            />
          </div>:null}
          <div className="flex items-center justify-between px-4 py-3 sm:px-6 mt-5 ">
            <Button
              className="px-7 py-2 bg-[#B6CC43] rounded-xl ml-3 w-fit"
              onClick={handleAddSelected}
            >
              Add Selected
            </Button>
            <Pagination
              totalPages={Math.ceil(totalItems / 10)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              offset={10}
            />
          </div>
        </div>
      </BaseModal>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid lg:grid-cols-3 gap-4">
          <div className="lg:col-span-2">

            <div className="border border-gray-300 rounded-md bg-white py-5">
              <div className="px-5">
                <p className="mb-2 font-bold">Ad Name</p>
              </div>
              <div className="w-full grid grid-cols-1 gap-4 px-5">
                <Input
                  placeholder="Enter Ad Name"
                  name={`ad_naming`}
                  id={`ad_naming`}
                  onChange={formik.handleChange}
                  onFocus={(e) => setCurrentElementId("ad_naming") }
                  onBlur={formik.handleBlur}
                  value={formik.values.ad_naming}
                  error={formik.errors.ad_naming}
                  touched={formik.touched.ad_naming}
                />
              </div>
              <div className="flex flex-wrap w-100 mt-3 px-5">
                <p className="font-semibold text-sm text-[#B6CC43] cursor-pointer underline" onClick={() => setOpenInsertToken(true)}>Add Tokens</p>
              </div>
            </div>
          
            <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
              <div className="px-5 ">
                <p className="mb-2 font-bold">Identify</p>
              </div>
              <div className="mx-5 my-5">
                <p className="text-sm mb-1">Facebook Page</p>
                <Dropdown
                  placeholder="Select"
                  options={facebookPages}
                  name="meta_facebook_page_config.page"
                  onChange={value => {
                    formik.setFieldValue("meta_facebook_page_config.page", value)
                    formik.setFieldValue("meta_facebook_page_config.is_website", false)
                    formik.setFieldValue("meta_facebook_page_config.call_to_action", "")
                    formik.setFieldValue("meta_facebook_page_config.instant", "")
                    handleFacebookPage(value.value)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.meta_facebook_page_config?.page}
                  error={formik.errors.meta_facebook_page_config?.page}
                  touched={formik.touched.meta_facebook_page_config?.page}
                  disabled={isTestPhase}
                />
              </div>
              
              <div className="mx-5 my-5">
                <div className="flex gap-2">
                  <span className="text-sm mb-1">Website</span>
                  <Toggle
                    isChecked={
                      formik.values.meta_facebook_page_config.is_website
                    }
                    onChange={() => {
                      formik.setFieldValue(
                        `meta_facebook_page_config.is_website`,
                        !formik.values.meta_facebook_page_config.is_website
                      )

                      formik.setFieldValue("meta_facebook_page_config.call_to_action", "")
                      formik.setFieldValue("meta_facebook_page_config.instant", { label: '', value: '' })
                      formik.setFieldValue("meta_facebook_page_config.pixel", { label: '', value: '' })
                      formik.setFieldValue("meta_facebook_page_config.custom_event_type", { label: '', value: '' })
                    }}
                    disabled={isTestPhase}
                  />
                  <span className="text-sm font-light">Instant form</span>
                </div>
              </div>

              {formik.values.meta_facebook_page_config?.page && <>
                <div className="mx-5 my-5">
                  <p className="font-medium my-2">Call to Action
                    {(campaignData) && <>
                      {(isTestPhase && (campaignData && campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Call to Action')) &&
                        <span className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</span>
                      }
                    </>}
                  </p>
                  <Dropdown
                    placeholder="Select"
                    options={(formik.values.meta_facebook_page_config?.is_website) ? CALL_TO_ACTION_INSTANT : CALL_TO_ACTIONS}
                    name={`meta_facebook_page_config.call_to_action`}
                    onChange={value =>
                      formik.setFieldValue(
                        `meta_facebook_page_config.call_to_action`,
                        value
                      )
                    }
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.meta_facebook_page_config &&
                      formik.values.meta_facebook_page_config?.call_to_action
                    }
                    error={
                      formik.errors?.meta_facebook_page_config &&
                      formik.errors?.meta_facebook_page_config?.call_to_action
                    }
                    touched={
                      formik.touched?.meta_facebook_page_config &&
                      formik.touched?.meta_facebook_page_config?.call_to_action
                    }
                    disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Call to Action')}
                  />
                </div>
                {((campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_LEADS') || (campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_SALES')) && <>
                  {(!formik.values.meta_facebook_page_config?.is_website) && <>
                    <div className="mx-5 my-5">
                      <p className="font-medium my-2">Custom Event Type</p>
                      <Dropdown
                        placeholder="Select"
                        options={(campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_LEADS') ? CUSTOM_EVENT_TYPE_LEAD : (campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_SALES') ? CUSTOM_EVENT_TYPE_SALES : CUSTOM_EVENT_TYPE_LEAD}
                        name={`meta_facebook_page_config.custom_event_type`}
                        onChange={value =>
                          formik.setFieldValue(
                            `meta_facebook_page_config.custom_event_type`,
                            value
                          )
                        }
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.meta_facebook_page_config &&
                          formik.values.meta_facebook_page_config?.custom_event_type
                        }
                        error={
                          formik.errors?.meta_facebook_page_config &&
                          formik.errors?.meta_facebook_page_config?.custom_event_type
                        }
                        touched={
                          formik.touched?.meta_facebook_page_config &&
                          formik.touched?.meta_facebook_page_config?.custom_event_type
                        }
                        disabled={isTestPhase}
                      />
                    </div>
                    <div className="mx-5 my-5">
                      <p className="font-medium my-2">Pixel Selection</p>
                      <Dropdown
                        placeholder="Select"
                        options={pixelOtions}
                        name={`meta_facebook_page_config.pixel`}
                        onChange={value =>
                          formik.setFieldValue(
                            `meta_facebook_page_config.pixel`,
                            value
                          )
                        }
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.meta_facebook_page_config &&
                          formik.values.meta_facebook_page_config?.pixel
                        }
                        error={
                          formik.errors?.meta_facebook_page_config &&
                          formik.errors?.meta_facebook_page_config?.pixel
                        }
                        touched={
                          formik.touched?.meta_facebook_page_config &&
                          formik.touched?.meta_facebook_page_config?.pixel
                        }
                        disabled={isTestPhase}
                      />
                    </div>
                  </>}
                </>}
                
                {(campaignData && campaignData.objective && campaignData.objective === 'OUTCOME_LEADS' && formik.values.meta_facebook_page_config?.is_website) && <>
                  <div className="mx-5 my-5">
                    <p className="font-medium my-2">Instant Form</p>
                    <Dropdown
                      placeholder="Select"
                      options={insantFormOtions}
                      name={`meta_facebook_page_config.instant`}
                      onChange={value =>
                        formik.setFieldValue(
                          `meta_facebook_page_config.instant`,
                          value
                        )
                      }
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.meta_facebook_page_config &&
                        formik.values.meta_facebook_page_config?.instant
                      }
                      error={
                        formik.errors?.meta_facebook_page_config &&
                        formik.errors?.meta_facebook_page_config?.instant
                      }
                      touched={
                        formik.touched?.meta_facebook_page_config &&
                        formik.touched?.meta_facebook_page_config?.instant
                      }
                      disabled={isTestPhase}
                    />
                  </div>
                </>}
              </>}
            </div>
            <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
              <div className="px-5 ">
                <p className="mb-2 font-bold">Media Configuration</p>
              </div>
              {formik.values.meta_media_configs.map((_, index) => (
                <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
                  <div className="relative flex items-center justify-between">
                    <div className="flex items-center">
                      <p className="text-sm mb-1"> Media {index + 1}: </p>
                      {(campaignData) && <>
                        {(isTestPhase && (campaignData.test_phase && campaignData.test_phase && ['Image', 'Video'].includes(campaignData.test_phase.variable)) && (campaignData.meta_headline_configs && campaignData.meta_headline_configs.length >= formik.values.meta_headline_configs.length)) &&
                          <p className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</p>
                        }
                      </>}
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white border border-1 border-gray-600 p-1 absolute right-0 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        const temp = [...formik.values.meta_media_configs]
                        temp.splice(index, 1)
                        formik.setFieldValue("meta_media_configs", temp)
                      }}
                      disabled={(campaignData && campaignData.meta_media_configs && campaignData.meta_media_configs.length >= formik.values.meta_media_configs.length) && isTestPhase}
                    >
                      <span className="sr-only">Close</span>
                      <FaTimes className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  <div id={`dropzone-${index + 1}`}>
                    <Dropzone
                      onDrop={acceptedFiles =>
                        handleFileDrop(index, acceptedFiles)
                      }
                      ref={(element) => {dropzoneRef.current[index] = element}}
                      accept={{
                        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
                        "video/*": [".mp4", ".mov"]
                      }}
                      disabled={(campaignData && campaignData.meta_media_configs && campaignData.meta_media_configs.length >= formik.values.meta_media_configs.length) && isTestPhase}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="min-h-[80px] flex items-center gap-2"
                        >
                          <input {...getInputProps()} />
                          {formik.values.meta_media_configs?.[index]
                            .meta_media_images.length === 0 ? (
                            <p>Drag and drop files here, or click to select files</p>
                          ) : (
                            formik.values.meta_media_configs?.[
                              index
                            ].meta_media_images.map(file => (
                            <>
                              {(file && file.constructor === File) ? 
                                (file.type.startsWith('video/')) ? 
                                  <video width="160" controls>
                                    <source src={URL.createObjectURL(file)} type={file.type} />
                                  </video>
                                : 
                                <img
                                  key={index}
                                  src={URL.createObjectURL(file)}
                                  alt={`Preview ${index + 1}`}
                                  className="w-40 h-auto object-cover"
                                />
                              : (file.file && file.is_video) ?
                                <video width="160" controls>
                                  <source src={file.file} type={"video/mp4"} />
                                </video>
                              :
                                <img
                                  key={index}
                                  src={(file.file) ? file.file : URL.createObjectURL(file)}
                                  alt={`Preview ${index + 1}`}
                                  className="w-40 h-auto object-cover"
                                />
                              }
                            </>))
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex gap-2">
                      <span className="text-sm mb-1">Single</span>
                      <Toggle
                        isChecked={
                          formik.values.meta_media_configs[index].is_carousel
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            `meta_media_configs[${index}].is_carousel`,
                            !formik.values.meta_media_configs[index].is_carousel
                          )
                        }
                      />
                      <span className="text-sm font-light">Carousel</span>
                    </div>
                    <div>
                      <Button
                        type="button"
                        onClick={() => handleOpenBrowseWindow(index)}
                        className={`px-7 py-2 bg-[#00B2B4] rounded-xl`}
                        disabled={(campaignData && campaignData.meta_media_configs && campaignData.meta_media_configs.length >= formik.values.meta_media_configs.length) && isTestPhase}
                      >
                        Browse
                      </Button>
                      <Button
                          type="button"
                          className={`px-7 py-2 bg-[#00B2B4] rounded-xl ml-2`}
                          onClick={() =>
                            handleSelectExistingValues(index, "getMetaMediaImagesConfigs")
                          }
                          disabled={(campaignData && campaignData.meta_text_configs && campaignData.meta_text_configs.length >= formik.values.meta_text_configs.length) && isTestPhase}
                        >
                          Select Existing
                        </Button>
                    </div>
                    
                  </div>
                </div>
              ))}
              <div className="px-4 flex items-center justify-between">
                <div>
                  <p className="text-gray-400">You can select multiple media assets together. Give it a try!</p>
                </div>
                <Button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue("meta_media_configs", [
                      ...formik.values.meta_media_configs,
                      { meta_media_images: [], is_carousel: false }
                    ])
                  }
                  className={`px-7 py-2 bg-[#B6CC43] rounded-xl`}
                  disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Image')}
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
              <div className="px-5 ">
                <p className="mb-2 font-bold">Primary Text Configurations</p>
              </div>
              {formik.values.meta_text_configs.map((_, index) => (
                <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">
                  <div className="relative flex items-center justify-between">
                    <div className="flex items-center">
                      <p className="text-sm mb-1">Primary Text {index + 1}: </p>
                      {(campaignData) && <>
                        {(isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Primary Text') && (campaignData.meta_text_configs && campaignData.meta_text_configs.length >= formik.values.meta_text_configs.length)) &&
                          <p className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</p>
                        }
                      </>}
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white border border-1 border-gray-600 p-1 absolute right-0 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        const temp = [...formik.values.meta_text_configs]
                        temp.splice(index, 1)
                        formik.setFieldValue("meta_text_configs", temp)
                      }}
                      disabled={(campaignData && campaignData.meta_text_configs && campaignData.meta_text_configs.length >= formik.values.meta_text_configs.length) && isTestPhase}
                    >
                      <span className="sr-only">Close</span>
                      <FaTimes className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="col-span-3">
                      <Textarea
                        placeholder=""
                        rows={3}
                        name={`meta_text_configs[${index}].text`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.meta_text_configs?.[index].text || ""}
                        error={formik.errors.meta_text_configs?.[index]?.text}
                        touched={formik.touched.meta_text_configs?.[index]?.text}
                        disabled={(campaignData && campaignData.meta_text_configs && campaignData.meta_text_configs.length >= formik.values.meta_text_configs.length) && isTestPhase}
                      />
                    </div>
                    <div className="flex justify-end items-center">
                      <Button
                        type="button"
                        className={`px-7 py-2 bg-[#00B2B4] rounded-xl`}
                        onClick={() =>
                          handleSelectExistingValues(index, "meta_text_configs")
                        }
                        disabled={(campaignData && campaignData.meta_text_configs && campaignData.meta_text_configs.length >= formik.values.meta_text_configs.length) && isTestPhase}
                      >
                        Select Existing
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-end">
                <Button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue("meta_text_configs", [
                      ...formik.values.meta_text_configs,
                      { text: "" }
                    ])
                  }
                  className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
                  disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Primary Text')}
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
              <div className="px-5 ">
                <p className="mb-2 font-bold">Headline Configuraions</p>
              </div>
              {formik.values.meta_headline_configs.map((_, index) => (
                <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">

                  <div className="relative flex items-center justify-between">
                    <div className="flex items-center">
                      <p className="text-sm mb-1">Headline Text {index + 1}: </p>
                      {(campaignData) && <>
                        {(isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Headline') && (campaignData.meta_headline_configs && campaignData.meta_headline_configs.length >= formik.values.meta_headline_configs.length)) &&
                          <p className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</p>
                        }
                      </>}
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white border border-1 border-gray-600 p-1 absolute right-0 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        const temp = [...formik.values.meta_headline_configs]
                        temp.splice(index, 1)
                        formik.setFieldValue("meta_headline_configs", temp)
                      }}
                      disabled={(campaignData && campaignData.meta_headline_configs && campaignData.meta_headline_configs.length >= formik.values.meta_headline_configs.length) && isTestPhase}
                    >
                      <span className="sr-only">Close</span>
                      <FaTimes className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="col-span-3">
                      <Textarea
                        placeholder=""
                        name={`meta_headline_configs[${index}].text`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.meta_headline_configs?.[index].text || ""
                        }
                        error={formik.errors.meta_headline_configs?.[index]?.text}
                        touched={formik.touched.meta_headline_configs?.[index]?.text}
                        disabled={(campaignData && campaignData.meta_headline_configs && campaignData.meta_headline_configs.length >= formik.values.meta_headline_configs.length) && isTestPhase}
                      />
                    </div>
                    <div className="flex justify-end items-center">
                      <Button
                        type="button"
                        className={`px-7 py-2 bg-[#00B2B4] rounded-xl`}
                        onClick={() =>
                          handleSelectExistingValues(index, "meta_headline_configs")
                        }
                        disabled={(campaignData && campaignData.meta_headline_configs && campaignData.meta_headline_configs.length >= formik.values.meta_headline_configs.length) && isTestPhase}
                      >
                        Select Existing
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-end">
                <Button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue("meta_headline_configs", [
                      ...formik.values.meta_headline_configs,
                      { text: "" }
                    ])
                  }
                  className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
                  disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Headline')}
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="mt-10 border border-gray-300 rounded-md bg-white py-5">
              <div className="px-5 ">
                <p className="mb-2 font-bold">Description Test Configurations</p>
              </div>
              {formik.values.meta_description_configs.map((_, index) => (
                <div className="mx-5 my-5 px-4 py-6 border border-gray-300 rounded-md">

                  <div className="relative flex items-center justify-between">
                    <div className="flex items-center">
                      <p className="text-sm mb-1">Description Text {index + 1}: </p>
                      {(campaignData) && <>
                        {(isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable === 'Headline') && (campaignData.meta_headline_configs && campaignData.meta_headline_configs.length >= formik.values.meta_headline_configs.length)) &&
                          <p className="ml-3 mt-0 text-sm font-medium text-white px-3 border-green-400 bg-green-400 text-center rounded-lg">Control</p>
                        }
                      </>}
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white border border-1 border-gray-600 p-1 absolute right-0 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        const temp = [...formik.values.meta_description_configs]
                        temp.splice(index, 1)
                        formik.setFieldValue("meta_description_configs", temp)
                      }}
                      disabled={(campaignData && campaignData.meta_description_configs && campaignData.meta_description_configs.length >= formik.values.meta_description_configs.length) && isTestPhase}
                    >
                      <span className="sr-only">Close</span>
                      <FaTimes className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="col-span-3">
                      <Textarea
                        placeholder=""
                        name={`meta_description_configs[${index}].text`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.meta_description_configs?.[index].text || ""
                        }
                        error={formik.errors.meta_description_configs?.[index]?.text}
                        touched={
                          formik.touched.meta_description_configs?.[index]?.text
                        }
                        disabled={(campaignData && campaignData.meta_description_configs && campaignData.meta_description_configs.length >= formik.values.meta_description_configs.length) && isTestPhase}
                      />
                    </div>
                    <div className="flex justify-end items-center">
                      <Button
                        type="button"
                        className={`px-7 py-2 bg-[#00B2B4] rounded-xl`}
                        onClick={() =>
                          handleSelectExistingValues(
                            index,
                            "meta_description_configs"
                          )
                        }
                        disabled={(campaignData && campaignData.meta_description_configs && campaignData.meta_description_configs.length >= formik.values.meta_description_configs.length) && isTestPhase}
                      >
                        Select Existing
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-end">
                <Button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue("meta_description_configs", [
                      ...formik.values.meta_description_configs,
                      { text: "" }
                    ])
                  }
                  className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
                  disabled={isTestPhase && (campaignData.test_phase && campaignData.test_phase && campaignData.test_phase.variable !== 'Description')}
                >
                  Add New
                </Button>
              </div>
            </div>

            <div className="my-5 px-4 py-6 border border-gray-300 bg-white rounded-md">
              <p className="text-sm mb-1 font-bold">Extra Parameters </p>
              <Input
                placeholder="key1=value1&key2=value2"
                name="extra_parameters"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.extra_parameters}
                error={formik.errors.extra_parameters}
                touched={formik.touched.extra_parameters}
                disabled={isTestPhase}
              />
              <div className="flex justify-end items-center">
                <Button
                  type="button"
                  className={`px-7 py-2 bg-[#B6CC43] rounded-xl`}
                >
                  Add Token
                </Button>
              </div>
            </div>
          </div>
          <div className="sticky top-10 h-max">
            <AdPreview
              handleNext={() =>
                setSliderIndex(
                  currentSliderIndex > 0
                    ? currentSliderIndex - 1
                    : currentSliderIndex
                )
              }
              handleBack={() =>
                setSliderIndex(
                  currentSliderIndex < maxLength - 1
                    ? currentSliderIndex + 1
                    : currentSliderIndex
                )
              }
              title={formik.values.meta_text_configs?.[currentSliderIndex]?.text}
              headline={
                formik.values.meta_headline_configs?.[currentSliderIndex]?.text
              }
              description={
                formik.values.meta_description_configs?.[currentSliderIndex]?.text
              }
              image={
                formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0] && formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0]?.file ?
                  formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0]?.file :
                  formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0] ?
                    URL.createObjectURL(
                      formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0]
                    ) : null
              }
              isVideo={
                (formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0] && formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0]?.file) ?
                  formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0]?.is_video :
                    (formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0] && formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0].constructor === File &&
                      formik.values.meta_media_configs?.[currentSliderIndex]?.meta_media_images?.[0].type.startsWith('video/')) ? true : false
              }
              calltoaction={(formik.values?.meta_facebook_page_config?.call_to_action) ? formik.values?.meta_facebook_page_config?.call_to_action?.label : (campaignData && campaignData.meta_facebook_page_config && campaignData.meta_facebook_page_config.call_to_action) ? campaignData.meta_facebook_page_config.call_to_action : 'LEARN MORE'}
            />
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <Button
            className="my-2 md:my-0 px-7 py-2 bg-[#00B2B4] rounded-xl mr-3"
            onClick={() => navigate(`/dashboard/audience-targeting/${campaignId}`)}
          >
            Previous
          </Button>
          <Button
            type="submit"
            className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
          >
            {(submitting) ? <Spinner className="w-5 h-5" /> : 'Next'}
          </Button>
        </div>
      </form>
    </>
  )
}
