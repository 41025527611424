import axios from "axios"
import queryString from 'query-string';

//const BASE_URL = "https://alpha.mediascale.ai"
// const BASE_URL = "https://concussion-media-37828.botics.co"
// const BASE_URL = "https://sweet-scene-37828.botics.co"
const BASE_URL = "https://alpha.mediascale.ai"

axios.interceptors.request.use(config => {
  const token = localStorage.getItem("token")

  if (token) config.headers.Authorization = "Token " + token

  return config
})

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response?.status === 401) {
      // localStorage.removeItem("token")
      // if (window.location.pathname !== '/login') window.location.href = '/login';
    }
    // return new Error(error);
    return Promise.reject(error)
  }
)

const login = body => {
  return axios.post(BASE_URL + `/api/v1/auth/login/`, body)
}
const loginWithGoogle = body => {
  return axios.post(BASE_URL + `/api/v1/auth/google/`, body)
}
const loginWithFacebook = body => {
  return axios.post(BASE_URL + `/api/v1/auth/facebook/`, body)
}
const signup = body => {
  return axios.post(BASE_URL + `/api/v1/auth/sign-up/`, body)
}

const forgetPawwordAPI = body => {
  return axios.post(BASE_URL + `/api/v1/reset-password/reset/`, body)
}

const changePasswordAPI = body => {
  return axios.post(BASE_URL + `/api/v1/change-password/`, body)
}
const getVerticalsAPI = params => {
  return axios.get(BASE_URL + `/api/v1/offers/`, { params })
}
const createVerticalsAPI = body => {
  return axios.post(BASE_URL + `/api/v1/offers/`, body)
}
const updateVerticalsAPI = (id, body) => {
  return axios.put(BASE_URL + `/api/v1/offers/${id}/`, body)
}

const assignVerticalToCampagins = (id, body) => {
  return axios.put(BASE_URL + `/api/v1/campaign/${id}/`, body)
}

// const usersAPI = axios.create({
//   baseURL: SERVICE_URL,
//   headers: { Accept: "application/json", "Content-Type": "application/json" }
// })
// function getUserById(id) {
//   return usersAPI.get(`/api/v1/user/${id}/`)
// }
// function updateUserById(data, token) {
//   return usersAPI.patch(`/api/v1/user/${data.id}/`, data, {
//     headers: {
//       Authorization: `Token ${token}`
//     }
//   })
// }

const deleteVerticalsAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/verticals/${id}/`, {})
}
const createVerticalParamsAPI = body => {
  return axios.post(BASE_URL + `/api/v1/verticals_params/`, body)
}
const getVerticalParamsAPI = id => {
  return axios.get(BASE_URL + `/api/v1/verticals_params/?id=${id}`)
}

const getCampaginsAPI = params => {
  return axios.get(BASE_URL + `/api/v1/campaigns/`, { params })
}

const deleteCampaignAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/campaigns/${id}/`)
}

const getProfileAPI = () => {
  return axios.get(BASE_URL + `/api/v1/users/self-profile/`)
}

const getCampaignDetailAPI = id => {
  return axios.get(BASE_URL + `/api/v1/campaigns/${id}/`)
}
const createCampaignAPI = body => {
  return axios.post(BASE_URL + `/api/v1/campaigns/`, body)
}
const updateCampaignAPI = (id, body) => {
  return axios.put(BASE_URL + `/api/v1/campaigns/${id}/`, body)
}
const updateCampaignDataAPI = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/campaigns/${id}/`, body)
}
const fetchCampaignDataAPI = (id) => {
  return axios.get(BASE_URL + `/api/v1/campaigns/${id}/update-data/`)
}
const fetchAdsDataAPI = (id) => {
  return axios.get(BASE_URL + `/api/v1/remote-ads/${id}/update-data/`)
}
const fetchAdsetDataAPI = (id) => {
  return axios.get(BASE_URL + `/api/v1/remote-ad-sets/${id}/update-data/`)
}
const getAdsetAPI = params => {
  return axios.get(BASE_URL +`/api/v1/remote-ad-sets/?${queryString.stringify(params)}`)
}
const deleteAdsetAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/remote-ad-sets/${id}/`)
}

const updateAdsetAPI = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/remote-ad-sets/${id}/`, body)
}

const getAdCreativeAPI = params => {
  return axios.get(BASE_URL +`/api/v1/remote-ads/?${queryString.stringify(params)}`)
}
const deleteAdCreativeAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/adcreative/${id}/`)
}

const updateAdCreativeAPI = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/remote-ads/${id}/`, body)
}

const createRuleAPI = body => {
  return axios.post(BASE_URL + `/api/v1/rule/`, body)
}

const getRulesAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/active-rules/`, { params })
}
const updateRulesAPI = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/active-rules/${id}/`, body)
}
const deleteRulesAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/active-rules/${id}/`)
}
const deleteBulkRulesAPI = body => {
  return axios.post(BASE_URL + `/api/v1/active-rules/bulk-delete/`, body)
}
const getRuleTemplatesAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/rule-templates/`, { params })
}
const getRuleLogsAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/rule-logs/`, { params })
}
const createRuleTemplatesAPI = body => {
  return axios.post(BASE_URL + `/api/v1/rule-templates/`, body)
}
const updateRuleTemplateDataAPI = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/rule-templates/${id}/`, body)
}
const deleteRuleTemplatesAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/rule-templates/${id}/`)
}
const getUsersAPI = params => {
  // console.log('params', params);
  // const endpoint = params.name ? `/api/v1/users/?search=${params.name}&organization_role=${params.role}` : `/api/v1/users/`
  // const endpoint = params.role ? `/api/v1/users/?organization_role=${params.role}` : `/api/v1/users/`
  // return axios.get(BASE_URL + endpoint);
  // return axios.get(BASE_URL +`/api/v1/users/?search=${params.name}&organization_role=${params.role}`)
  return axios.get(BASE_URL +`/api/v1/users/`, { params })
}
const postUsersAPI = body => {
  return axios.post(BASE_URL + `/api/v1/users/`, body)
}
const updateUsersAPI = (id, body) => {
  return axios.put(BASE_URL + `/api/v1/users/${id}/`, body)
}
const deleteUsersAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/users/${id}/`)
}
const addFacebookAccount = body => {
  return axios.post(BASE_URL + `/api/v1/facebook-accounts/connect/`, body)
}
const getFacebookAccounts = () => {
  return axios.get(BASE_URL + `/api/v1/facebook-accounts/`)
}
const getFacebookAdAccounts = () => {
  return axios.get(BASE_URL + `/api/v1/facebook-ad-accounts/`)
}
const joinTeamResetPassword = body => {
  return axios.post(BASE_URL + `/api/v1/auth/password-reset/`, body)
}
const removeFacebookAccount = id => {
  return axios.delete(BASE_URL + `/api/v1/facebook-accounts/${id}/`)
}
const addFacebookAdAccount = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/facebook-ad-accounts/${id}/`, body)
}
const removeFacebookAdAccount = id => {
  return axios.delete(BASE_URL + `/api/v1/facebook-ad-accounts/${id}/`)
}
const facebookSearchLocation = (id, query) => {
  return axios.get(
    `${BASE_URL}/api/v1/campaigns/${id}/search/audience-geolocations/?search=${query}`
  )
}
const facebookSearchDemographics = (id, query) => {
  return axios.get(
    `${BASE_URL}/api/v1/campaigns/${id}/search/audience-detailed-targetings/?search=${query}`
  )
}
const facebookSearchLocale = (id, query) => {
  return axios.get(
    `${BASE_URL}/api/v1/campaigns/${id}/search/language/?search=${query}`
  )
}
const getFacebookPixels = id => {
  return axios.get(`${BASE_URL}/api/v1/campaigns/${id}/ads-pixels/`)
}
const getCustomAudience = id => {
  return axios.get(
    `${BASE_URL}/api/v1/campaigns/${id}/search/custom-audiences/`
  )
}
const getTrackingScript = () => {
  return axios.get(BASE_URL + `/api/v1/tracking/scripts/`)
}

const getFacebookPages = id => {
  return axios.get(BASE_URL + `/api/v1/campaigns/${id}/search/facebook-pages/`)
}

const getSavedAudienceAPI = id => {
  return axios.get(BASE_URL + `/api/v1/campaigns/${id}/search/saved-audiences/`)
}

const getInstantForm = (id, fbId) => {
  return axios.get(BASE_URL + `/api/v1/campaigns/${id}/search/${fbId}/instant-forms/`)
}

const updateImagesAPI = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/campaigns/${id}/`, body, {
    headers: "multipart/form-data"
  })
}

const getCreativeSelector = params => {
  return axios.get(BASE_URL + `/api/v1/creative-selector/`, { params })
}

const getMetaTextConfigs = search => {
  return axios.get(BASE_URL + `/api/v1/meta-text-configs/`, {
    params: { search }
  })
}

const getMetaDescriptionConfigs = search => {
  return axios.get(BASE_URL + `/api/v1/meta-description-configs/`, {
    params: { search }
  })
}

const getMetaHeadlineConfigs = search => {
  return axios.get(BASE_URL + `/api/v1/meta-headline-configs/`, {
    params: { search }
  })
}
const getMetaMediaImagesConfigs = search => {
  return axios.get(BASE_URL + `/api/v1/meta-media-images/`, {
    params: search
  })
}

const pushAdstoFacebook = (id, body) => {
  return axios.post(
    BASE_URL + `/api/v1/campaigns/${id}/push-to-facebook/`,
    body
  )
}

const getLandingPagesAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/landing-pages/`, { params })
}

const createLandingPagesAPI = body => {
  return axios.post(BASE_URL + `/api/v1/landing-pages/`, body)
}
const updateLandingPagesAPI = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/landing-pages/${id}/`, body)
}
const deleteLandingPagesAPI = id => {
  return axios.delete(BASE_URL + `/api/v1/landing-pages/${id}/`, {})
}
const getStatsAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/stats/summary/?${queryString.stringify(params)}`)
}
const updateSelfConfigAPI = (body) => {
  return axios.patch(BASE_URL + `/api/v1/fe-config/self-config/`, body)
}
const getSelfConfigAPI = () => {
  return axios.get(BASE_URL + `/api/v1/fe-config/self-config/`)
}
const getLeadStatsAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/stats/leads-stats/?${queryString.stringify(params)}`)
}
const getTestCampaginsAPI = params => {
  return axios.get(BASE_URL + `/api/v1/test-phases/`, { params })
}
const addTestModule = body => {
  return axios.post(BASE_URL + `/api/v1/test-modules/`, body)
}
const addTestPhase = body => {
  return axios.post(BASE_URL + `/api/v1/test-phases/`, body)
}
const updateTestPhase = (id, body) => {
  return axios.patch(BASE_URL + `/api/v1/test-phases/${id}/`, body)
}
const assignTemplatesToCampagins = (id, body) => {
  return axios.post(BASE_URL + `/api/v1/rule-templates/${id}/assign-entities/`, body)
}
const getAllClickTracksAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/click-tracks/`, { params })
}
const getAllConversionsAPI = (params) => {
  return axios.get(BASE_URL + `/api/v1/postback-leads/`, { params })
}
const assignBulkTemplatesToCampagins = (body) => {
  return axios.post(BASE_URL + `/api/v1/rule-templates/bulk-assign-entities/`, body)
}
const createDuplicateCampaign = (id) => {
  return axios.get(BASE_URL + `/api/v1/campaigns/${id}/create-duplicate/`)
}

export {
  login,
  signup,
  forgetPawwordAPI,
  changePasswordAPI,
  getVerticalsAPI,
  createVerticalsAPI,
  updateVerticalsAPI,
  deleteVerticalsAPI,
  createVerticalParamsAPI,
  getVerticalParamsAPI,
  getCampaginsAPI,
  assignVerticalToCampagins,
  getProfileAPI,
  getCampaignDetailAPI,
  createCampaignAPI,
  getAdsetAPI,
  getAdCreativeAPI,
  createRuleAPI,
  getRulesAPI,
  updateRulesAPI,
  getRuleTemplatesAPI,
  getRuleLogsAPI,
  createRuleTemplatesAPI,
  updateRuleTemplateDataAPI,
  deleteCampaignAPI,
  deleteAdsetAPI,
  deleteAdCreativeAPI,
  updateAdsetAPI,
  updateAdCreativeAPI,
  loginWithGoogle,
  loginWithFacebook,
  getUsersAPI,
  postUsersAPI,
  updateUsersAPI,
  addFacebookAccount,
  getFacebookAccounts,
  getFacebookAdAccounts,
  deleteUsersAPI,
  joinTeamResetPassword,
  removeFacebookAccount,
  addFacebookAdAccount,
  removeFacebookAdAccount,
  facebookSearchLocation,
  facebookSearchDemographics,
  facebookSearchLocale,
  getFacebookPixels,
  updateCampaignAPI,
  updateCampaignDataAPI,
  fetchCampaignDataAPI,
  fetchAdsetDataAPI,
  fetchAdsDataAPI,
  getCustomAudience,
  getTrackingScript,
  getFacebookPages,
  getSavedAudienceAPI,
  updateImagesAPI,
  getCreativeSelector,
  getMetaTextConfigs,
  getMetaDescriptionConfigs,
  getMetaHeadlineConfigs,
  getMetaMediaImagesConfigs,
  pushAdstoFacebook,
  getLandingPagesAPI,
  createLandingPagesAPI,
  updateLandingPagesAPI,
  deleteLandingPagesAPI,
  getStatsAPI,
  getInstantForm,
  getSelfConfigAPI,
  updateSelfConfigAPI,
  getLeadStatsAPI,
  getTestCampaginsAPI,
  addTestModule,
  addTestPhase,
  updateTestPhase,
  assignTemplatesToCampagins,
  assignBulkTemplatesToCampagins,
  deleteRulesAPI,
  deleteBulkRulesAPI,
  deleteRuleTemplatesAPI,
  getAllClickTracksAPI,
  getAllConversionsAPI,
  createDuplicateCampaign
}
