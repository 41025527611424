export const TESING_VARIABLES = [
  { value: "Age", label: "Age" },
  { value: "Audience", label: "Audience" },
  { value: "Attribution", label: "Attribution" },
  { value: "Bid Amount", label: "Bid Amount" },
  { value: "Bid Option", label: "Bid Option" },
  { value: "Budget Type", label: "Budget Type" },
  { value: "Call to Action", label: "Call to Action" },
  { value: "Description", label: "Description" },
  { value: "Gender", label: "Gender" },
  { value: "Headline", label: "Headline" },
  { value: "Image", label: "Image" },
  { value: "Landing Page", label: "Landing Page" },
  { value: "Location", label: "Location" },
  { value: "Media", label: "Media" },
  { value: "Placements", label: "Placements" },
  { value: "Primary Text", label: "Primary Text" },
  { value: "Video", label: "Video" }
]

export const DEFINED_KPIS = [
  { label: "CPC - (lower is better)", value: "CPC" },
  { label: "CTR ALL - (Higher is better)", value: "CTR ALL" },
  { label: "CTR - (Higher is better)", value: "CTR" },
  { label: "Lowest CPM'S - (lower is better)", value: "Lowest CPM" },
  { label: "Profit  - (Higher is better)", value: "Profit" },
  { label: "ROI - (Higher is better)", value: "RoI" }
]

export const ORGANIZATION_ROLE_ENUM = [
  { value: "Admin", label: "Admin" },
  { value: "Normal", label: "Normal" }
]

export const BID_STRATEGY_ENUM = [
  {
    value: "COST_CAP",
    label: "Cost cap"
  },
  {
    value: "LOWEST_COST_WITH_BID_CAP",
    label: "Bid cap"
  },
  {
    value: "LOWEST_COST_WITHOUT_CAP",
    label: "Lowest cost highest value"
  }
]

export const BUDGET_TYPE_ENUM = [
  { value: "LIFETIME", label: "Lifetime" },
  { value: "DAILY", label: "Daily" }
]

export const CUSTOM_EVENT_TYPE_LEAD = [
  { value: "COMPLETE_REGISTRATION", label: "COMPLETE REGISTRATION" },
  { value: "CONTACT", label: "CONTACT" },
  { value: "FIND_LOCATION", label: "FIND LOCATION" },
  { value: "LEAD", label: "LEAD" },
  { value: "SCHEDULE", label: "SCHEDULE" },
  { value: "SUBMIT_APPLICATION", label: "SUBMIT APPLICATION" },
  { value: "SUBSCRIBE", label: "SUBSCRIBE" },
  { value: "START_TRIAL", label: "START TRIAL" }
]

export const CUSTOM_EVENT_TYPE_SALES = [
  { value: "ADD_PAYMENT_INFO", label: "ADD PAYMENT INFO" },
  { value: "ADD_TO_CART", label: "ADD TO CART" },
  { value: "COMPLETE_REGISTRATION", label: "COMPLETE REGISTRATION" },
  { value: "CONTENT_VIEW", label: "CONTENT VIEW" },
  { value: "DONATE", label: "DONATE" },
  { value: "INITIATED_CHECKOUT", label: "INITIATED CHECKOUT" },
  { value: "PURCHASE", label: "PURCHASE" },
  { value: "SUBSCRIBE", label: "SUBSCRIBE" },
  { value: "START_TRIAL", label: "START TRIAL" }
]

export const OBJECTIVE_ENUM = [
  // {
  //   value: "OUTCOME_APP_PROMOTION",
  //   label: "Outcome app Promotion"
  // },
  // {
  //   value: "OUTCOME_AWARENESS",
  //   label: "Outcome awareness"
  // },
  // {
  //   value: "OUTCOME_ENGAGEMENT",
  //   label: "Outcome engagement"
  // },
  {
    value: "OUTCOME_LEADS",
    label: "Lead"
  },
  {
    value: "OUTCOME_SALES",
    label: "Purchase"
  },
  {
    value: "OUTCOME_TRAFFIC",
    label: "Traffic"
  }
]
export const OBJECTIVE_ENUM_OLD = [
  {
    value: "APP_INSTALLS",
    label: "App Installs"
  },
  {
    value: "BRAND_AWARENESS",
    label: "Brand Awareness"
  },
  {
    value: "CONVERSIONS",
    label: "Conversions"
  },
  {
    value: "EVENT_RESPONSES",
    label: "Event Responses"
  },
  {
    value: "LEAD_GENERATION",
    label: "Lead Generation"
  },
  {
    value: "LINK_CLICKS",
    label: "Link Clicks"
  },
  {
    value: "LOCAL_AWARENESS",
    label: "Local Awareness"
  },
  {
    value: "MESSAGES",
    label: "Messages"
  },
  {
    value: "OFFER_CLAIMS",
    label: "Offer Claims"
  },
  {
    value: "OUTCOME_APP_PROMOTION",
    label: "Outcome App_promotion"
  },
  {
    value: "OUTCOME_AWARENESS",
    label: "Outcome Awareness"
  },
  {
    value: "OUTCOME_ENGAGEMENT",
    label: "Outcome Engagement"
  },
  {
    value: "OUTCOME_LEADS",
    label: "Outcome Leads"
  },
  {
    value: "OUTCOME_SALES",
    label: "Outcome Sales"
  },
  {
    value: "OUTCOME_TRAFFIC",
    label: "Outcome Traffic"
  },
  {
    value: "PAGE_LIKES",
    label: "Page Likes"
  },
  {
    value: "POST_ENGAGEMENT",
    label: "Post Engagement"
  },
  {
    value: "PRODUCT_CATALOG_SALES",
    label: "Product Catalog Sales"
  },
  {
    value: "REACH",
    label: "Reach"
  },
  {
    value: "STORE_VISITS",
    label: "Store Visits"
  },
  {
    value: "VIDEO_VIEWS",
    label: "Video Views"
  }
]

export const SPECIAL_AD_CATEGORIES_ENUM = [
  { value: "CREDIT", label: "Credit" },
  { value: "EMPLOYMENT", label: "Employment" },
  { value: "HOUSING", label: "Housing" },
  { value: "ISSUES_ELECTIONS_POLITICS", label: "Issues Election Politics" },
  { value: "ONLINE_GAMBLING_AND_GAMING", label: "Online Gambling and Gaming" },
  { value: "NONE", label: "None" }
]

export const AD_SET_NAMING = [
  { label: "Account", value: "{{account}}" },
  { label: "Landing Page", value: "{{landing_page}}" },
  { label: "Campaign Objective", value: "{{campaign_objective}}" },
  { label: "Budget Type", value: "{{budget_type}}" },
  { label: "Budget Price", value: "{{budget_price}}" },
  { label: "Bid Strategies", value: "{{bid_strategies}}" },
  { label: "Schedule Start Date", value: "{{schedule_start_date}}" },
  { label: "Schedule End Date", value: "{{schedule_end_date}}" },
  { label: "Campaign ID", value: "{{campaign_id}}" },
  { label: "Ad Set ID", value: "{{ad_set_id}}" }
]

export const AD_NAMING = [
  { label: "Account", value: "{{account}}" },
  { label: "Landing Page", value: "{{landing_page}}" },
  { label: "Campaign Objective", value: "{{campaign_objective}}" },
  { label: "Budget Type", value: "{{budget_type}}" },
  { label: "Budget Price", value: "{{budget_price}}" },
  { label: "Bid Strategies", value: "{{bid_strategies}}" },
  { label: "Schedule Start Date", value: "{{schedule_start_date}}" },
  { label: "Schedule End Date", value: "{{schedule_end_date}}" },
  { label: "Media ID", value: "{{media_id}}" },
  { label: "Primary ID", value: "{{primary_id}}" },
  { label: "Headline ID", value: "{{headline_id}}" },
  { label: "Description ID", value: "{{description_id}}" },
  { label: "Campaign ID", value: "{{campaign_id}}" },
  { label: "Ad Set ID", value: "{{ad_set_id}}" },
  { label: "Ad ID", value: "{{ad_id}}" },
  { label: "Media Name", value: "{{media_name}}" },
]

export const SPECIAL_AD_CATEGORY_COUNTRY_ENUM = [
  { value: "AD", label: "AD" },
  { value: "AE", label: "AE" },
  { value: "AF", label: "AF" },
  { value: "AG", label: "AG" },
  { value: "AI", label: "AI" },
  { value: "AL", label: "AL" },
  { value: "AM", label: "AM" },
  { value: "AN", label: "AN" },
  { value: "AO", label: "AO" },
  { value: "AQ", label: "AQ" },
  { value: "AR", label: "AR" },
  { value: "AT", label: "AT" },
  { value: "AU", label: "AU" },
  { value: "AW", label: "AW" },
  { value: "AX", label: "AX" },
  { value: "AZ", label: "AZ" },
  { value: "BA", label: "BA" },
  { value: "BB", label: "BB" },
  { value: "BD", label: "BD" },
  { value: "BE", label: "BE" },
  { value: "BF", label: "BF" },
  { value: "BG", label: "BG" },
  { value: "BH", label: "BH" },
  { value: "BI", label: "BI" },
  { value: "BJ", label: "BJ" },
  { value: "BL", label: "BL" },
  { value: "BM", label: "BM" },
  { value: "BN", label: "BN" },
  { value: "BO", label: "BO" },
  { value: "BQ", label: "BQ" },
  { value: "BR", label: "BR" },
  { value: "BS", label: "BS" },
  { value: "BT", label: "BT" },
  { value: "BV", label: "BV" },
  { value: "BW", label: "BW" },
  { value: "BY", label: "BY" },
  { value: "BZ", label: "BZ" },
  { value: "CA", label: "CA" },
  { value: "CC", label: "CC" },
  { value: "CD", label: "CD" },
  { value: "CF", label: "CF" },
  { value: "CG", label: "CG" },
  { value: "CH", label: "CH" },
  { value: "CI", label: "CI" },
  { value: "CK", label: "CK" },
  { value: "CL", label: "CL" },
  { value: "CM", label: "CM" },
  { value: "CN", label: "CN" },
  { value: "CO", label: "CO" },
  { value: "CR", label: "CR" },
  { value: "CU", label: "CU" },
  { value: "CV", label: "CV" },
  { value: "CW", label: "CW" },
  { value: "CX", label: "CX" },
  { value: "CY", label: "CY" },
  { value: "CZ", label: "CZ" },
  { value: "DE", label: "DE" },
  { value: "DJ", label: "DJ" },
  { value: "DK", label: "DK" },
  { value: "DM", label: "DM" },
  { value: "DO", label: "DO" },
  { value: "DZ", label: "DZ" },
  { value: "EC", label: "EC" },
  { value: "EE", label: "EE" },
  { value: "EG", label: "EG" },
  { value: "EH", label: "EH" },
  { value: "ER", label: "ER" },
  { value: "ES", label: "ES" },
  { value: "ET", label: "ET" },
  { value: "FI", label: "FI" },
  { value: "FJ", label: "FJ" },
  { value: "FK", label: "FK" },
  { value: "FM", label: "FM" },
  { value: "FO", label: "FO" },
  { value: "FR", label: "FR" },
  { value: "GA", label: "GA" },
  { value: "GB", label: "GB" },
  { value: "GD", label: "GD" },
  { value: "GE", label: "GE" },
  { value: "GF", label: "GF" },
  { value: "GG", label: "GG" },
  { value: "GH", label: "GH" },
  { value: "GI", label: "GI" },
  { value: "GL", label: "GL" },
  { value: "GM", label: "GM" },
  { value: "GN", label: "GN" },
  { value: "GP", label: "GP" },
  { value: "GQ", label: "GQ" },
  { value: "GR", label: "GR" },
  { value: "GS", label: "GS" },
  { value: "GT", label: "GT" },
  { value: "GU", label: "GU" },
  { value: "GW", label: "GW" },
  { value: "GY", label: "GY" },
  { value: "HK", label: "HK" },
  { value: "HM", label: "HM" },
  { value: "HN", label: "HN" },
  { value: "HR", label: "HR" },
  { value: "HT", label: "HT" },
  { value: "HU", label: "HU" },
  { value: "ID", label: "ID" },
  { value: "IE", label: "IE" },
  { value: "IL", label: "IL" },
  { value: "IM", label: "IM" },
  { value: "IO", label: "IO" },
  { value: "IQ", label: "IQ" },
  { value: "IR", label: "IR" },
  { value: "IT", label: "IT" },
  { value: "JE", label: "JE" },
  { value: "JM", label: "JM" },
  { value: "JO", label: "JO" },
  { value: "JP", label: "JP" },
  { value: "KE", label: "KE" },
  { value: "KG", label: "KG" },
  { value: "KH", label: "KH" },
  { value: "KI", label: "KI" },
  { value: "KM", label: "KM" },
  { value: "KN", label: "KN" },
  { value: "KP", label: "KP" },
  { value: "KR", label: "KR" },
  { value: "KW", label: "KW" },
  { value: "KY", label: "KY" },
  { value: "KZ", label: "KZ" },
  { value: "LA", label: "LA" },
  { value: "LB", label: "LB" },
  { value: "LC", label: "LC" },
  { value: "LI", label: "LI" },
  { value: "LK", label: "LK" },
  { value: "LR", label: "LR" },
  { value: "LS", label: "LS" },
  { value: "LT", label: "LT" },
  { value: "LU", label: "LU" },
  { value: "LV", label: "LV" },
  { value: "LY", label: "LY" },
  { value: "MA", label: "MA" },
  { value: "MC", label: "MC" },
  { value: "MD", label: "MD" },
  { value: "ME", label: "ME" },
  { value: "MF", label: "MF" },
  { value: "MG", label: "MG" },
  { value: "MH", label: "MH" },
  { value: "MK", label: "MK" },
  { value: "ML", label: "ML" },
  { value: "MM", label: "MM" },
  { value: "MN", label: "MN" },
  { value: "MO", label: "MO" },
  { value: "MP", label: "MP" },
  { value: "MQ", label: "MQ" },
  { value: "MR", label: "MR" },
  { value: "MS", label: "MS" },
  { value: "MT", label: "MT" },
  { value: "MU", label: "MU" },
  { value: "MV", label: "MV" },
  { value: "MW", label: "MW" },
  { value: "MX", label: "MX" },
  { value: "MY", label: "MY" },
  { value: "MZ", label: "MZ" },
  { value: "NA", label: "NA" },
  { value: "NC", label: "NC" },
  { value: "NE", label: "NE" },
  { value: "NF", label: "NF" },
  { value: "NG", label: "NG" },
  { value: "NI", label: "NI" },
  { value: "NL", label: "NL" },
  { value: "NO", label: "NO" },
  { value: "NP", label: "NP" },
  { value: "NR", label: "NR" },
  { value: "NU", label: "NU" },
  { value: "NZ", label: "NZ" },
  { value: "OM", label: "OM" },
  { value: "PA", label: "PA" },
  { value: "PE", label: "PE" },
  { value: "PF", label: "PF" },
  { value: "PG", label: "PG" },
  { value: "PH", label: "PH" },
  { value: "PK", label: "PK" },
  { value: "PL", label: "PL" },
  { value: "PM", label: "PM" },
  { value: "PN", label: "PN" },
  { value: "PR", label: "PR" },
  { value: "PS", label: "PS" },
  { value: "PT", label: "PT" },
  { value: "PW", label: "PW" },
  { value: "PY", label: "PY" },
  { value: "QA", label: "QA" },
  { value: "RE", label: "RE" },
  { value: "RO", label: "RO" },
  { value: "RS", label: "RS" },
  { value: "RU", label: "RU" },
  { value: "RW", label: "RW" },
  { value: "SA", label: "SA" },
  { value: "SB", label: "SB" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "SE", label: "SE" },
  { value: "SG", label: "SG" },
  { value: "SH", label: "SH" },
  { value: "SI", label: "SI" },
  { value: "SJ", label: "SJ" },
  { value: "SK", label: "SK" },
  { value: "SL", label: "SL" },
  { value: "SM", label: "SM" },
  { value: "SN", label: "SN" },
  { value: "SO", label: "SO" },
  { value: "SR", label: "SR" },
  { value: "SS", label: "SS" },
  { value: "ST", label: "ST" },
  { value: "SV", label: "SV" },
  { value: "SX", label: "SX" },
  { value: "SY", label: "SY" },
  { value: "SZ", label: "SZ" },
  { value: "TC", label: "TC" },
  { value: "TD", label: "TD" },
  { value: "TF", label: "TF" },
  { value: "TG", label: "TG" },
  { value: "TH", label: "TH" },
  { value: "TJ", label: "TJ" },
  { value: "TK", label: "TK" },
  { value: "TL", label: "TL" },
  { value: "TM", label: "TM" },
  { value: "TN", label: "TN" },
  { value: "TO", label: "TO" },
  { value: "TR", label: "TR" },
  { value: "TT", label: "TT" },
  { value: "TV", label: "TV" },
  { value: "TW", label: "TW" },
  { value: "TZ", label: "TZ" },
  { value: "UA", label: "UA" },
  { value: "UG", label: "UG" },
  { value: "UM", label: "UM" },
  { value: "US", label: "US" },
  { value: "UY", label: "UY" },
  { value: "UZ", label: "UZ" },
  { value: "VA", label: "VA" },
  { value: "AS", label: "AS" },
  { value: "IN", label: "IN" },
  { value: "IS", label: "IS" },
  { value: "VC", label: "VC" },
  { value: "VE", label: "VE" },
  { value: "VG", label: "VG" },
  { value: "VI", label: "VI" },
  { value: "VN", label: "VN" },
  { value: "VU", label: "VU" },
  { value: "WF", label: "WF" },
  { value: "WS", label: "WS" },
  { value: "XK", label: "XK" },
  { value: "YE", label: "YE" },
  { value: "YT", label: "YT" },
  { value: "ZA", label: "ZA" },
  { value: "ZM", label: "ZM" },
  { value: "ZW", label: "ZW" }
]

export const STATUS_ENUM = [
  {
    value: "ACTIVE",
    label: "Active"
  },
  {
    value: "ARCHIVED",
    label: "Archived"
  },
  {
    value: "DELETED",
    label: "Deleted"
  },
  {
    value: "PAUSED",
    label: "Paused"
  }
]

export const EXECUTION_OPTIONS_ENUM = [
  {
    value: "include_recommendations",
    label: "Include Recommendations"
  },
  {
    value: "validate_only",
    label: "Validate Only"
  }
]

export const OPTIMIZATION_GOALS_LEADS = [
  {
    value: "OFFSITE_CONVERSIONS",
    label: "Offsite Conversions"
  },
  {
    value: "LANDING_PAGE_VIEWS",
    label: "Landing Page Views"
  },
  {
    value: "LINK_CLICKS",
    label: "Link Clicks"
  },
  {
    value: "IMPRESSIONS",
    label: "Impressions"
  },
  {
    value: "REACH",
    label: "Reach"
  },
  {
    value: "LEAD_GENERATION",
    label: "Lead Generation"
  },
  {
    value: "QUALITY_LEAD",
    label: "Quality Lead"
  }
]
export const OPTIMIZATION_GOALS_SALES = [
  {
    value: "OFFSITE_CONVERSIONS",
    label: "Offsite Conversions"
  },
  {
    value: "LANDING_PAGE_VIEWS",
    label: "Landing Page Views"
  },
  {
    value: "LINK_CLICKS",
    label: "Link Clicks"
  },
  {
    value: "IMPRESSIONS",
    label: "Impressions"
  },
  {
    value: "REACH",
    label: "Reach"
  },
  {
    value: "VALUE",
    label: "Value"
  },
]
export const OPTIMIZATION_GOALS_TRAFFIC = [
  {
    value: "LANDING_PAGE_VIEWS",
    label: "Landing Page Views"
  },
  {
    value: "LINK_CLICKS",
    label: "Link Clicks"
  },
  {
    value: "IMPRESSIONS",
    label: "Impressions"
  },
  {
    value: "REACH",
    label: "Reach"
  }
]
export const OPTIMIZATION_GOALS = [
  {
    value: "AD_RECALL_LIFT",
    label: "Ad Recall Lift"
  },
  {
    value: "APP_INSTALLS",
    label: "App Installs"
  },
  {
    value: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS",
    label: "App Installs And Offsite Conversions"
  },
  {
    value: "CONVERSATIONS",
    label: "Conversations"
  },
  {
    value: "DERIVED_EVENTS",
    label: "Derived Events"
  },
  {
    value: "ENGAGED_USERS",
    label: "Engaged Users"
  },
  {
    value: "EVENT_RESPONSES",
    label: "Event Responses"
  },
  {
    value: "IMPRESSIONS",
    label: "Impressions"
  },
  {
    value: "IN_APP_VALUE",
    label: "In App Value"
  },
  {
    value: "LANDING_PAGE_VIEWS",
    label: "Landing Page Views"
  },
  {
    value: "LEAD_GENERATION",
    label: "Lead Generation"
  },
  {
    value: "LINK_CLICKS",
    label: "Link Clicks"
  },
  {
    value: "MESSAGING_APPOINTMENT_CONVERSION",
    label: "Messaging Appointment Conversion"
  },
  {
    value: "MESSAGING_PURCHASE_CONVERSION",
    label: "Messaging Purchase Conversion"
  },
  {
    value: "NONE",
    label: "None"
  },
  {
    value: "OFFSITE_CONVERSIONS",
    label: "Offsite Conversions"
  },
  {
    value: "PAGE_LIKES",
    label: "Page Likes"
  },
  {
    value: "POST_ENGAGEMENT",
    label: "Post Engagement"
  },
  {
    value: "QUALITY_CALL",
    label: "Quality Call"
  },
  {
    value: "QUALITY_LEAD",
    label: "Quality Lead"
  },
  {
    value: "REACH",
    label: "Reach"
  },
  {
    value: "SUBSCRIBERS",
    label: "Subscribers"
  },
  {
    value: "THRUPLAY",
    label: "Thruplay"
  },
  {
    value: "VALUE",
    label: "Value"
  },
  {
    value: "VISIT_INSTAGRAM_PROFILE",
    label: "Visit Instagram Profile"
  }
]
export const ATTRIBUTATION_SETTINGS = [
  {
    value: "1d_click",
    label: "1d Click"
  },
  {
    value: "7d_click",
    label: "7d Click"
  },
  {
    value: "1d_click/1d_view",
    label: "1d Click/1d View"
  },
  {
    value: "7d_click/1d_view",
    label: "7d Click/1d View"
  }
]
export const DELIVERY_TYPE = [
  {
    value: "standard",
    label: "Standard"
  },
  {
    value: "day_parting",
    label: "Day Parting"
  },
  {
    value: "no_pacing",
    label: "No Pacing"
  },
  {
    value: "disabled",
    label: "Disabled"
  }
]
export const CALL_TO_ACTIONS = [
  {
    value: "APPLY_NOW",
    label: "APPLY NOW"
  },
  {
    value: "DOWNLOAD",
    label: "DOWNLOAD"
  },
  {
    value: "GET_OFFER",
    label: "GET OFFER"
  },
  {
    value: "GET_QUOTE",
    label: "GET QUOTE"
  },
  {
    value: "LEARN_MORE",
    label: "LEARN MORE"
  },
  {
    value: "SIGN_UP",
    label: "SIGN UP"
  },
  {
    value: "SUBSCRIBE",
    label: "SUBSCRIBE"
  },
  {
    value: "CONTACT_US",
    label: "CONTACT US"
  },
  {
    value: "DONATE_NOW",
    label: "DONATE NOW"
  },
  {
    value: "GET_SHOWTIMES",
    label: "GET SHOWTIMES"
  },
  {
    value: "LISTEN_NOW",
    label: "LISTEN NOW"
  },
  {
    value: "ORDER_NOW",
    label: "ORDER NOW"
  },
  {
    value: "PLAY_GAME",
    label: "PLAY GAME"
  },
  {
    value: "REQUEST_TIME",
    label: "REQUEST TIME"
  },
  {
    value: "SHOP_NOW",
    label: "SHOP NOW"
  },
  {
    value: "WATCH_MORE",
    label: "WATCH MORE"
  }
]
export const CALL_TO_ACTION_INSTANT = [
  {
    value: "APPLY_NOW",
    label: "APPLY NOW"
  },
  {
    value: "DOWNLOAD",
    label: "DOWNLOAD"
  },
  {
    value: "GET_OFFER",
    label: "GET OFFER"
  },
  {
    value: "GET_QUOTE",
    label: "GET QUOTE"
  },
  {
    value: "LEARN_MORE",
    label: "LEARN MORE"
  },
  {
    value: "SIGN_UP",
    label: "SIGN UP"
  },
  {
    value: "SUBSCRIBE",
    label: "SUBSCRIBE"
  }
]
export const LANGUAGES = [
  {
    value: "English",
    label: "English"
  },
  {
    value: "Afar",
    label: "Afar"
  },
  {
    value: "Abkhazian",
    label: "Abkhazian"
  },
  {
    value: "Avestan",
    label: "Avestan"
  },
  {
    value: "Afrikaans",
    label: "Afrikaans"
  },
  {
    value: "Akan",
    label: "Akan"
  },
  {
    value: "Amharic",
    label: "Amharic"
  },
  {
    value: "Aragonese",
    label: "Aragonese"
  },
  {
    value: "Arabic",
    label: "Arabic"
  },
  {
    value: "Assamese",
    label: "Assamese"
  },
  {
    value: "Avaric",
    label: "Avaric"
  },
  {
    value: "Aymara",
    label: "Aymara"
  },
  {
    value: "Azerbaijani",
    label: "Azerbaijani"
  },
  {
    value: "Bashkir",
    label: "Bashkir"
  },
  {
    value: "Belarusian",
    label: "Belarusian"
  },
  {
    value: "Bulgarian",
    label: "Bulgarian"
  },
  {
    value: "Bihari languages",
    label: "Bihari languages"
  },
  {
    value: "Bislama",
    label: "Bislama"
  },
  {
    value: "Bambara",
    label: "Bambara"
  },
  {
    value: "Bengali",
    label: "Bengali"
  },
  {
    value: "Tibetan",
    label: "Tibetan"
  },
  {
    value: "Breton",
    label: "Breton"
  },
  {
    value: "Bosnian",
    label: "Bosnian"
  },
  {
    value: "Catalan; Valencian",
    label: "Catalan; Valencian"
  },
  {
    value: "Chechen",
    label: "Chechen"
  },
  {
    value: "Chamorro",
    label: "Chamorro"
  },
  {
    value: "Corsican",
    label: "Corsican"
  },
  {
    value: "Cree",
    label: "Cree"
  },
  {
    value: "Czech",
    label: "Czech"
  },
  {
    value:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    label:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
  },
  {
    value: "Chuvash",
    label: "Chuvash"
  },
  {
    value: "Welsh",
    label: "Welsh"
  },
  {
    value: "Danish",
    label: "Danish"
  },
  {
    value: "German",
    label: "German"
  },
  {
    value: "Divehi; Dhivehi; Maldivian",
    label: "Divehi; Dhivehi; Maldivian"
  },
  {
    value: "Dzongkha",
    label: "Dzongkha"
  },
  {
    value: "Ewe",
    label: "Ewe"
  },
  {
    value: "Greek, Modern (1453-)",
    label: "Greek, Modern (1453-)"
  },
  {
    value: "English",
    label: "English"
  },
  {
    value: "Esperanto",
    label: "Esperanto"
  },
  {
    value: "Spanish; Castilian",
    label: "Spanish; Castilian"
  },
  {
    value: "Estonian",
    label: "Estonian"
  },
  {
    value: "Basque",
    label: "Basque"
  },
  {
    value: "Persian",
    label: "Persian"
  },
  {
    value: "Fulah",
    label: "Fulah"
  },
  {
    value: "Finnish",
    label: "Finnish"
  },
  {
    value: "Fijian",
    label: "Fijian"
  },
  {
    value: "Faroese",
    label: "Faroese"
  },
  {
    value: "French",
    label: "French"
  },
  {
    value: "Western Frisian",
    label: "Western Frisian"
  },
  {
    value: "Irish",
    label: "Irish"
  },
  {
    value: "Gaelic; Scomttish Gaelic",
    label: "Gaelic; Scomttish Gaelic"
  },
  {
    value: "Galician",
    label: "Galician"
  },
  {
    value: "Guarani",
    label: "Guarani"
  },
  {
    value: "Gujarati",
    label: "Gujarati"
  },
  {
    value: "Manx",
    label: "Manx"
  },
  {
    value: "Hausa",
    label: "Hausa"
  },
  {
    value: "Hebrew",
    label: "Hebrew"
  },
  {
    value: "Hindi",
    label: "Hindi"
  },
  {
    value: "Hiri Motu",
    label: "Hiri Motu"
  },
  {
    value: "Croatian",
    label: "Croatian"
  },
  {
    value: "Haitian; Haitian Creole",
    label: "Haitian; Haitian Creole"
  },
  {
    value: "Hungarian",
    label: "Hungarian"
  },
  {
    value: "Armenian",
    label: "Armenian"
  },
  {
    value: "Herero",
    label: "Herero"
  },
  {
    value: "Interlingua (International Auxiliary Language Association)",
    label: "Interlingua (International Auxiliary Language Association)"
  },
  {
    value: "Indonesian",
    label: "Indonesian"
  },
  {
    value: "Interlingue; Occidental",
    label: "Interlingue; Occidental"
  },
  {
    value: "Igbo",
    label: "Igbo"
  },
  {
    value: "Sichuan Yi; Nuosu",
    label: "Sichuan Yi; Nuosu"
  },
  {
    value: "Inupiaq",
    label: "Inupiaq"
  },
  {
    value: "Ido",
    label: "Ido"
  },
  {
    value: "Icelandic",
    label: "Icelandic"
  },
  {
    value: "Italian",
    label: "Italian"
  },
  {
    value: "Inuktitut",
    label: "Inuktitut"
  },
  {
    value: "Japanese",
    label: "Japanese"
  },
  {
    value: "Javanese",
    label: "Javanese"
  },
  {
    value: "Georgian",
    label: "Georgian"
  },
  {
    value: "Kongo",
    label: "Kongo"
  },
  {
    value: "Kikuyu; Gikuyu",
    label: "Kikuyu; Gikuyu"
  },
  {
    value: "Kuanyama; Kwanyama",
    label: "Kuanyama; Kwanyama"
  },
  {
    value: "Kazakh",
    label: "Kazakh"
  },
  {
    value: "Kalaallisut; Greenlandic",
    label: "Kalaallisut; Greenlandic"
  },
  {
    value: "Central Khmer",
    label: "Central Khmer"
  },
  {
    value: "Kannada",
    label: "Kannada"
  },
  {
    value: "Korean",
    label: "Korean"
  },
  {
    value: "Kanuri",
    label: "Kanuri"
  },
  {
    value: "Kashmiri",
    label: "Kashmiri"
  },
  {
    value: "Kurdish",
    label: "Kurdish"
  },
  {
    value: "Komi",
    label: "Komi"
  },
  {
    value: "Cornish",
    label: "Cornish"
  },
  {
    value: "Kirghiz; Kyrgyz",
    label: "Kirghiz; Kyrgyz"
  },
  {
    value: "Latin",
    label: "Latin"
  },
  {
    value: "Luxembourgish; Letzeburgesch",
    label: "Luxembourgish; Letzeburgesch"
  },
  {
    value: "Ganda",
    label: "Ganda"
  },
  {
    value: "Limburgan; Limburger; Limburgish",
    label: "Limburgan; Limburger; Limburgish"
  },
  {
    value: "Lingala",
    label: "Lingala"
  },
  {
    value: "Lao",
    label: "Lao"
  },
  {
    value: "Lithuanian",
    label: "Lithuanian"
  },
  {
    value: "Luba-Katanga",
    label: "Luba-Katanga"
  },
  {
    value: "Latvian",
    label: "Latvian"
  },
  {
    value: "Malagasy",
    label: "Malagasy"
  },
  {
    value: "Marshallese",
    label: "Marshallese"
  },
  {
    value: "Maori",
    label: "Maori"
  },
  {
    value: "Macedonian",
    label: "Macedonian"
  },
  {
    value: "Malayalam",
    label: "Malayalam"
  },
  {
    value: "Mongolian",
    label: "Mongolian"
  },
  {
    value: "Marathi",
    label: "Marathi"
  },
  {
    value: "Malay",
    label: "Malay"
  },
  {
    value: "Maltese",
    label: "Maltese"
  },
  {
    value: "Burmese",
    label: "Burmese"
  },
  {
    value: "Nauru",
    label: "Nauru"
  },
  {
    value: "Bokmål, Norwegian; Norwegian Bokmål",
    label: "Bokmål, Norwegian; Norwegian Bokmål"
  },
  {
    value: "Ndebele, North; North Ndebele",
    label: "Ndebele, North; North Ndebele"
  },
  {
    value: "Nepali",
    label: "Nepali"
  },
  {
    value: "Ndonga",
    label: "Ndonga"
  },
  {
    value: "Dutch; Flemish",
    label: "Dutch; Flemish"
  },
  {
    value: "Norwegian Nynorsk; Nynorsk, Norwegian",
    label: "Norwegian Nynorsk; Nynorsk, Norwegian"
  },
  {
    value: "Norwegian",
    label: "Norwegian"
  },
  {
    value: "Ndebele, South; South Ndebele",
    label: "Ndebele, South; South Ndebele"
  },
  {
    value: "Navajo; Navaho",
    label: "Navajo; Navaho"
  },
  {
    value: "Chichewa; Chewa; Nyanja",
    label: "Chichewa; Chewa; Nyanja"
  },
  {
    value: "Occitan (post 1500)",
    label: "Occitan (post 1500)"
  },
  {
    value: "Ojibwa",
    label: "Ojibwa"
  },
  {
    value: "Oromo",
    label: "Oromo"
  },
  {
    value: "Oriya",
    label: "Oriya"
  },
  {
    value: "Ossetian; Ossetic",
    label: "Ossetian; Ossetic"
  },
  {
    value: "Panjabi; Punjabi",
    label: "Panjabi; Punjabi"
  },
  {
    value: "Pali",
    label: "Pali"
  },
  {
    value: "Polish",
    label: "Polish"
  },
  {
    value: "Pushto; Pashto",
    label: "Pushto; Pashto"
  },
  {
    value: "Portuguese",
    label: "Portuguese"
  },
  {
    value: "Quechua",
    label: "Quechua"
  },
  {
    value: "Romansh",
    label: "Romansh"
  },
  {
    value: "Rundi",
    label: "Rundi"
  },
  {
    value: "Romanian; Moldavian; Moldovan",
    label: "Romanian; Moldavian; Moldovan"
  },
  {
    value: "Russian",
    label: "Russian"
  },
  {
    value: "Kinyarwanda",
    label: "Kinyarwanda"
  },
  {
    value: "Sanskrit",
    label: "Sanskrit"
  },
  {
    value: "Sardinian",
    label: "Sardinian"
  },
  {
    value: "Sindhi",
    label: "Sindhi"
  },
  {
    value: "Northern Sami",
    label: "Northern Sami"
  },
  {
    value: "Sango",
    label: "Sango"
  },
  {
    value: "Sinhala; Sinhalese",
    label: "Sinhala; Sinhalese"
  },
  {
    value: "Slovak",
    label: "Slovak"
  },
  {
    value: "Slovenian",
    label: "Slovenian"
  },
  {
    value: "Samoan",
    label: "Samoan"
  },
  {
    value: "Shona",
    label: "Shona"
  },
  {
    value: "Somali",
    label: "Somali"
  },
  {
    value: "Albanian",
    label: "Albanian"
  },
  {
    value: "Serbian",
    label: "Serbian"
  },
  {
    value: "Swati",
    label: "Swati"
  },
  {
    value: "Sotho, Southern",
    label: "Sotho, Southern"
  },
  {
    value: "Sundanese",
    label: "Sundanese"
  },
  {
    value: "Swedish",
    label: "Swedish"
  },
  {
    value: "Swahili",
    label: "Swahili"
  },
  {
    value: "Tamil",
    label: "Tamil"
  },
  {
    value: "Telugu",
    label: "Telugu"
  },
  {
    value: "Tajik",
    label: "Tajik"
  },
  {
    value: "Thai",
    label: "Thai"
  },
  {
    value: "Tigrinya",
    label: "Tigrinya"
  },
  {
    value: "Turkmen",
    label: "Turkmen"
  },
  {
    value: "Tagalog",
    label: "Tagalog"
  },
  {
    value: "Tswana",
    label: "Tswana"
  },
  {
    value: "Tonga (Tonga Islands)",
    label: "Tonga (Tonga Islands)"
  },
  {
    value: "Turkish",
    label: "Turkish"
  },
  {
    value: "Tsonga",
    label: "Tsonga"
  },
  {
    value: "Tatar",
    label: "Tatar"
  },
  {
    value: "Twi",
    label: "Twi"
  },
  {
    value: "Tahitian",
    label: "Tahitian"
  },
  {
    value: "Uighur; Uyghur",
    label: "Uighur; Uyghur"
  },
  {
    value: "Ukrainian",
    label: "Ukrainian"
  },
  {
    value: "Urdu",
    label: "Urdu"
  },
  {
    value: "Uzbek",
    label: "Uzbek"
  },
  {
    value: "Venda",
    label: "Venda"
  },
  {
    value: "Vietnamese",
    label: "Vietnamese"
  },
  {
    value: "Volapük",
    label: "Volapük"
  },
  {
    value: "Walloon",
    label: "Walloon"
  },
  {
    value: "Wolof",
    label: "Wolof"
  },
  {
    value: "Xhosa",
    label: "Xhosa"
  },
  {
    value: "Yiddish",
    label: "Yiddish"
  },
  {
    value: "Yoruba",
    label: "Yoruba"
  },
  {
    value: "Zhuang; Chuang",
    label: "Zhuang; Chuang"
  },
  {
    value: "Chinese",
    label: "Chinese"
  },
  {
    value: "Zulu",
    label: "Zulu"
  }
]

// This script I use to convert the data from swagger
// const newObj = ["standard", "day_parting", "no_pacing", "disabled"].map(item => ({
//   value: item,
//   label: capatalizeString(item)
// }))

// const capatalizeString=(stringVal)=>{
//   let strArr=stringVal.toLowerCase().split('_')
//   const capatilizeStr=strArr.map(item=>item.charAt(0).toUpperCase()+item.slice(1));
//   return capatilizeStr.join(' ')
// }
